//###########################################################################
// Resources
//###########################################################################

// Resources menu
//------------------

.resources-menu {
  height: 100%;
  position: relative;
  padding-top: 80px;
  background-color: #2D2D2D;
  padding-right: 32px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 100%;
    width: 100vw;
    background-color: #2D2D2D;
  }

  @include breakpoint-max(small) {
    padding-right: 6px;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 100%;
      width: 100vw;
      background-color: #2D2D2D;
    }
  }

  ul {
    list-style: none;
    margin-left: 0;
  }
}

// Resources menu group
//------------------

.resources-menu__group {
  .resources-menu__children {
    display: none;
  }

  &.open {
    .resources-menu__group__title {
      .icon {
        transform: rotate(-180deg);
      }
    }
    .resources-menu__children {
      display: block;
    }
  }
}

// Resources menu group title
//------------------

.resources-menu__group__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 12px;
  cursor: pointer;

  .icon {
    margin-left: 12px;
    transform: rotate(0deg);
    transition: transform 0.2s;
  }
}

// Resources menu children
//------------------

.resources-menu__children {
  list-style: none;
  margin-left: 0;
  padding-bottom: 12px;
}

// Resources menu option
//------------------

.resources-menu__option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 24px;
  cursor: pointer;

  > label {
    margin-bottom: 0;
  }

  .checkbox {
    margin-left: 12px;

    label {
      cursor: pointer;
      padding-left: 22px;
    }
  }
}

// Resources menu option title
//------------------

.resources-menu__option__title {
  flex: 1 1 auto;
}

// Resources document
//------------------

.resources-document {
  @include breakpoint-max(small) {
    text-align: center;
  }

  &.hidden {
    display: none;
  }
}

.resources-all {
  position: relative;
  padding-bottom: 60px;

  &.loading {
    opacity: 0;
  }
}

.resources-all__load-more {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 1.6rem;
  cursor: pointer;

  .text {
    pointer-events: none;
    margin-right: 10px;
  }

  .icon {
    pointer-events: none;
    position: relative;

    .plus {
      transition: opacity 0.1s;
      opacity: 1;
    }

    .resource-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: opacity 0.1s;
      opacity: 0;
    }
  }

  &.is-loading {
    pointer-events: none;

    .plus {
      opacity: 0;
    }
    .resource-loader {
      opacity: 1;
    }
  }

  &.all-loaded {
    cursor: default;
  }
}

.resource-loader {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;

  div {
    position: absolute;
    top: 8px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: palette(black);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 2px;
      animation: resource-loader1 0.6s infinite;
    }

    &:nth-child(2) {
      left: 2px;
      animation: resource-loader2 0.6s infinite;
    }

    &:nth-child(3) {
      left: 8px;
      animation: resource-loader2 0.6s infinite;
    }

    &:nth-child(4) {
      left: 16px;
      animation: resource-loader3 0.6s infinite;
    }
  }
}

@keyframes resource-loader1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes resource-loader2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(6px, 0);
  }
}

@keyframes resource-loader3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.resources-filtered {
  &.loading {
    opacity: 0;
  }
}

.resources-all__no-results, .resources-filtered__no-results {
  display: block;
  text-align: center;
}