//###########################################################################
// Forms
//###########################################################################

// Form
//------------------

select.form-pusher {
  transition: opacity .3s;

  &.changing {
    opacity: .2;
  }
}

.form {
  button[type=submit] {
    opacity: 1;
    transition: opacity 0.3s;
  }

  &--light {
    color: palette(white);

    input, textarea, select {
      border: 2px solid palette(white);
    }

    .checkbox {
      label {
        &:before {
          border: 1px solid palette(white);
        }

        &:after {
          color: palette(black);
        }
      }

      input[type="checkbox"] {
        &:checked + label {
          &:before {
            background-color: palette(white);
            border-color: palette(white);
          }
        }
      }
    }
  }
}

// Inputs
//------------------

input {
  font-family: $body-font;
  width: 100%;
  border: 2px solid palette(black);
  border-radius: 0;
  font-size: 1.6rem;
  padding: 15px;
  outline: none;
  -webkit-appearance: none; /* for box shadows to show on iOS */
  transition: background .3s, border .5s;

  &:focus {
    border-color: #a1a1a1;
    background-color: #fbfbfb;
  }

  &:not(:placeholder-shown) {
    background-color: #fbfbfb;
  }

  &::placeholder {
    color: lighten(palette(black), 55%);
  }

  &[disabled] {
    background-color: #f7f7f8;
    cursor: not-allowed;
  }
}

input[type=checkbox] {
  -webkit-appearance: checkbox;
  margin: 0;
}

input[type=file] {
  position: relative;
  cursor: pointer;
  padding: 14px 15px;
  color: palette(black);

  &::-webkit-file-upload-button {
    visibility: hidden;
  }

  &::before {
    content: 'Select a file';
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    color: lighten(palette(black), 55%);

    white-space: nowrap;
    font-size: 1.6rem;
    pointer-events: none;
  }

  &:after {
    content: 'Choose file';
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 1.4rem;
    background: palette(black);
    color: palette(white);
    padding: 10px 16px;
  }
}

// Textarea
//------------------

textarea {
  font-family: $body-font;
  width: 100%;
  border: 2px solid palette(black);
  border-radius: 0;
  font-size: 1.6rem;
  line-height: 1.5;
  padding: 15px;
  outline: none;
  transition: background .3s, border .5s;

  &:focus {
    border-color: #a1a1a1;
    background-color: #fbfbfb;
  }

  &:not(:empty) {
    background-color: #fbfbfb;
  }
}

// Select
//------------------

select {
  font-family: $body-font;
  width: 100%;
  font-size: 1.6rem;
  line-height: 1.2;
  padding: 17px 40px 17px 15px;
  outline: none;
  -webkit-appearance: none; /* for box shadows to show on iOS */
  min-height: 50px;
  border: 2px solid palette(black);
  border-radius: 0;
  background-image: url('../img/icons/arrow-down.svg');
  background-repeat: no-repeat;
  background-position: right 16px top 50%;
  color: palette(black);
}

// Labels
//------------------

label {
  display: block;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 300;
  margin-bottom: 12px;
}

// Form types
//------------------

.input__hold {
  margin-bottom: 20px;
}

.input__hold--fancy {
  margin-top: 24px;
  margin-bottom: 20px;
  position: relative;

  label {
    margin-top: 12px;
    margin-bottom: 0;
    -webkit-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    position: absolute;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    color: #333;
    pointer-events: none;
    font-weight: 400;
    font-size: 18px;
    left: 0;
    top: 50px;

    span {
      padding-left: 10px;
      font-size: 1rem;
      display: inline-block;
      color: #c7192e;
    }
  }

  input, textarea, select {
    width: 100%;
    border: none;
    border-bottom: 2px solid black;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    color: #333;
  }

  input {

    &:focus ~ label, &.active--input ~ label {
      -webkit-transform: translateY(-75px);
      transform: translateY(-75px);
      letter-spacing: 1px;
      font-size: 14px;
    }

    &[type=file] {
      border: 0;
      padding-top: 0;
      width: 100%;
      display: flex;
    }
  }

  textarea {

    &:focus ~ label, &.active--input ~ label {
      -webkit-transform: translateY(-75px);
      transform: translateY(-75px);
      font-size: 14px;
    }
  }

  select {
    width: 100%;
    font-size: 18px;
    transition: border .5s;
  }

  &.active--input select {
    border-bottom: 2px solid white;

    ~ label {
      color: #333;
      -webkit-transform: translateY(-75px);
      transform: translateY(-75px);
      letter-spacing: 1px;
      font-size: 14px;
    }
  }
}

// Checkboxes
//------------------

.checkbox {
  display: flex;

  &--white {
    label {
      &:before {
        border: 1px solid palette(white) !important;
      }

      &:after {
        color: palette(black) !important;
      }
    }

    input[type="checkbox"] {
      &:checked + label {
        &:before {
          background-color: palette(white) !important;
          border-color: palette(white) !important;
        }
      }
    }
  }

  label {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    line-height: 1.6;

    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: 30px;

    &::before, &::after {
      position: absolute;
      content: "";
      /*Needed for the line-height to take effect*/
      display: inline-block;
    }

    &::before {
      height: 16px;
      width: 16px;
      border: 1px solid palette(black);
      left: 1px;
      background-color: transparent;
      /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
       *to vertically center it.
       */
      top: 3px;
    }

    &::after {
      color: palette(white);
      height: 5px;
      width: 9px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      left: 4px;
      top: 7px;
    }
  }

  input[type="checkbox"] {
    display: none;

    + label::after {
      content: none;
    }

    &:checked + label {
      &:before {
        background-color: palette(black);
        border-color: palette(black);
      }

      &::after {
        content: "";
      }
    }

    &:focus + label::before {
      outline: rgb(59, 153, 252) auto 5px;
    }

    &:disabled ~ label {
      &:before, &:after {
        opacity: .5
      }
    }
  }

  &.error {
    label::before, label::after {
      animation: shake .5s ease-in-out forwards;
    }
  }
}

.checkbox__group {
  .checkbox {
    display: inline-flex;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      width: 49.5%;
      padding-right: 25px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  input {
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
    clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
    position: absolute !important;
    white-space: nowrap;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
}

// Opt in error
//------------------

.opt-in-error {
  border: 1px solid palette(red, error);
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 20px;

  > p {
    margin-bottom: 0;
  }
}

// Honeypot
//------------------

#firstName {
  display: none;
}

// Honey pot

//###########################################################################
// Form modifiers
//###########################################################################

// Form careers
//------------------

.form-careers__textarea-col {
  display: flex;
  flex-direction: column;

  .input__hold--textarea {
    flex: 1 1 auto;

    textarea {
      height: calc(100% - 24px);
    }
  }
}

// Input colours
//------------------

.input--success {
  border-color: palette(green, success);
}

.input--error {
  border-color: palette(red, error);
}


// From hold
//------------------

.form-container {
  transition: all .3s;
}

.form-hold {
  position: relative;
}

.form-complete {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.form-hold.form--complete {
  .form-container {
    opacity: 0;
    visibility: hidden;
  }

  .form-complete {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

.form--submitting {
  button[type=submit] {
    animation: submitPulse 2s infinite;
    animation-delay: 0.3s;
    opacity: 0.25;
    pointer-events: none;
  }
}

@keyframes submitPulse {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.25;
  }
}