$teal: #38A4B5;

#seminar-request,
#seminar-booking {

    .seminars,
    .seminar-booking {
        .hero {
            text-align: center;
        }
    }
}

// Seminar request page
//------------------
#seminar-request {
    .seminars {
        .summary {
            align-items: center;
            margin: 10px 0;
            text-align: center;

            p {
                margin-bottom: 0;
            }

            div:nth-child(2) {
                .selected {
                    display: inline-block;
                    border: 2px solid $teal;
                    padding: 15px 30px;
                    font-size: 1.6rem;
                    margin: 20px 0 10px 0;
                }
            }
        }

        .tile-wrapper {
            .tile-seminar {
                position: relative;
                margin: 20px 0;
                @include box-shadow();
                padding-bottom: 20px; // add additional space between body and footer

                .image {
                    img {
                        width: 100%;
                        height: auto;
                        margin: 0;
                        border-bottom: 5px solid $teal;
                    }
                }

                .body,
                .footer {
                    padding: 30px;
                }

                .body {
                    h2 {
                        font-size: 1.6rem;
                        margin-bottom: 10px;
                    }
                }

                .footer {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;

                    .add-remove-buttons {
                        text-align: right;
                    }
                }

                &.empty {
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;
                }
            }
        }

        .button {
            cursor: hand;
            cursor: pointer;

            &.learn-more,
            &.add-seminar,
            &.remove-seminar {
                font-size: 1.6rem;
                font-weight: 500;
            }

            &.add-seminar,
            &.remove-seminar {
                display: none;
                text-decoration: none;

                &.show {
                    display: inline-block;
                }
            }

            &.learn-more {
                display: inline-block;
            }

            &.add-seminar {
                color: $teal;
            }

            &.remove-seminar {
                color: #b53838;
            }
        }
    }

    .seminar-booking-section {
        background-color: #216B77;
        padding: 20px;

        .g {
            align-items: center;

            p {
                margin-bottom: 0;
                color: #fff;
            }
        }

        #book-now {
            display: block;
            margin: 20px 0;
            font-size: 1.6rem;
            font-weight: 600;
            padding: 15px 40px;
            text-decoration: none;
            background-color: #b8e0e7;
            color: #1c5761;
            text-align: center;

            &.disabled {
                cursor: not-allowed;
                background-color: #29808e;
            }
        }
    }

    .seminar-modal {
        padding: 0;
        border-top: 5px solid $teal;

        .seminar-modal-body {
            .image {
                background-size: cover;
                background-position: center;
                height: 300px;
            }

            .content {
                padding: 40px;

                h2 {
                    font-size: 1.6rem;
                }

                .button {
                    border: 2px solid $teal;
                    padding: 15px 30px;
                    font-size: 1.6rem;
                    font-weight: 600;
                    color: #000;

                    &.remove-seminar {
                        border-color: #b53838;
                    }
                }
            }
        }
    }
}

@include breakpoint(medium) {
    #seminar-request {
        .seminars {
            .summary {
                text-align: initial;

                div:nth-child(2) {
                    text-align: right;

                    .selected {
                        margin: 0;
                    }
                }
            }

            .tile-wrapper {
                display: flex;
                flex-direction: row;
                align-items: stretch;
                gap: 40px;

                .tile-seminar {
                    width: 33%;
                }
            }
        }

        .seminar-booking-section {
            padding: 80px 0;

            .g {
                div:nth-child(2) {
                    text-align: right;
                }
            }

            #book-now {
                margin: 0;
                display: inline-block;
            }
        }

        .seminar-modal {
            .seminar-modal-body {
                .image {
                    height: unset;
                }
            }
        }
    }
}

// Seminar booking page
//------------------
#seminar-booking {
    .add-more {
        background-color: $teal;
        padding: 15px 0;

        a {
            text-decoration: none;

            p {
                margin: 0;
            }
        }
    }

    .seminars {
        margin-bottom: 80px;

        .selected-seminars {
            border-top: 2px solid $teal;

            .seminar {
                margin: 10px 0;
                align-items: center;
                padding: 20px 30px;
                border-bottom: 2px solid $teal;

                p {
                    margin: 0;
                    font-weight: 600;
                }

                .g__1-3 {
                    text-align: right;
                }

                .remove-seminar {
                    font-size: 1.6rem;
                    text-decoration: none;
                    color: black;

                    .icon {
                        width: 30px;
                        height: auto;
                        margin-left: 5px;
                        pointer-events: none;
                    }
                }
            }
        }
    }

    .request-form {
        background-color: #000;
        border-top: 4px solid $teal;

        .heading {
            text-align: center;
            margin: 0 auto 40px auto;

            p {
                margin: 0;
            }
        }

        .form-fields {
            margin: 0 auto;

            .input--hold {
                margin-bottom: 20px;
            }

            .btn {
                background-color: $teal;
                color: #000;
                padding: 16px 30px;
            }

            &.hide {
                display: none;
            }
        }

        .success-message {
            display: none;
            text-align: center;
            padding: 30px;

            p {
                margin-bottom: 0;
            }

            &.show {
                display: block;
            }
        }
    }
}