//###########################################################################
// Location filter
//###########################################################################

.location-filter {
  margin-bottom: 20px;

  @include breakpoint(small) {
    text-align: right;
  }

  .checkbox {
    @include breakpoint(small) {
      display: inline-flex;
    }

    &:not(:last-child) {
      margin-right: 30px;
    }
  }
}

.location-filter__item {
  &--hidden {
    display: none;
  }
}