//###########################################################################
// Careers
//###########################################################################

// Careers info
//------------------

.career__info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}

.career__info__item {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 22px;
  }

  svg {
    margin-right: 10px;
  }

  p {
    font-size: 1.6rem;
    line-height: 1;
    margin-bottom: 0;
  }
}