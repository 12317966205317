//###########################################################################
// Text utilities
//###########################################################################

// Text transforms
//------------------

.tt-u {
  text-transform: uppercase !important;
}

// Text decorations
//------------------

.under {
  text-decoration: underline !important;
}

.td-n {
  text-decoration: none !important;
}

// Text alignment
//------------------

.ta-c {
  text-align: center !important;
}

.ta-l {
  text-align: left !important;

  @include breakpoint(small) {
    &--small-up {
      text-align: left !important;
    }
  }
}

.ta-r {
  text-align: right !important;

  @include breakpoint(small) {
    &--small-up {
      text-align: right !important;
    }
  }

  @include breakpoint(medium) {
    &--medium-up {
      text-align: right !important;
    }
  }
}

// Font weights
//------------------

.bold,
.bold > * {
  font-weight: 800 !important;
}

.medium {
  font-weight: 500 !important;
}

.normal {
  font-weight: 400 !important;
}

// Font styles
//------------------

em, .italic {
  font-style: italic !important;
}

// Accessibility
//------------------

.a11y {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden;
}