//###########################################################################
// News
//###########################################################################

// News filter
//------------------

.news-filter {
  padding: 40px 0;

  @include breakpoint(medium) {
    display: flex;
    align-items: center;
  }

  p {
    @include breakpoint(medium) {
      margin-right: 40px;
      margin-bottom: 0;
      width: auto;
    }
  }

  select {
    @include breakpoint-max(medium) {
      margin-bottom: 20px;
    }
    @include breakpoint(medium) {
      max-width: 320px;
      margin-right: 32px;
    }
  }
}

.news-info {
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: palette(black);
  color: palette(white);
  margin-bottom: 4px;

  @include breakpoint(small) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .news-info__date {
    margin-bottom: 20px;

    @include breakpoint(small) {
      margin-bottom: 0;
    }
  }

  .news-info__share {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(small) {
      width: auto;
      justify-content: flex-start;
    }

    p {
      margin-right: 16px;
    }

    a {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  p {
    margin-bottom: 0;
    font-weight: 500;
  }
}