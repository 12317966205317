//###########################################################################
// Background utilities
//###########################################################################

.bg--black {
  background-color: black;
}

// Duo backgrounds
//------------------

// Black/white
//---------

.bg--black-large, .bg--black-medium, .bg--black-small {
  position: relative;
  background-color: white;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    background-color: black;
    top: 0;
    left: 0;
  }
}

.bg--black-medium {
  &:before {
    height: 220px;
  }
}

.bg--white-large, .bg--white-medium, .bg--white-small {
  position: relative;
  background-color: black;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    background-color: white;
    top: 0;
    left: 0;
  }
}

.bg--white-large {
  &:before {
    height: 300px;
  }
}

.bg--white-medium {
  &:before {
    height: 220px;
  }
}

// Timber
//---------

.bg--group-large, .bg--group-medium, .bg--group-small {
  position: relative;
  background-color: black;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    background-color: white;
    top: 0;
    left: 0;
  }
}

.bg--group-large {
  &:before {
    height: 300px;
  }
}

.bg--group-medium {
  &:before {
    height: 220px;
  }
}

.bg--group-small {
  &:before {
    height: 100px;
  }
}

// Timber
//---------

.bg--timber {
  background-color: palette(green);
}

.bg--timber-light {
  background-color: palette(green, light);
}

.bg--timber-lighter {
  background-color: palette(green, lighter);
}

.bg--timber-large, .bg--timber-medium-large, .bg--timber-medium, .bg--timber-small, .bg--timber-xsmall {
  position: relative;
  background: palette(green);

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    background-color: palette(white);
    top: 0;
    left: 0;
  }
}

.bg--timber-large {
  &:before {
    height: 300px;
  }
}

.bg--timber-medium-large {
  &:before {
    height: 260px;
  }
}

.bg--timber-medium {
  &:before {
    height: 220px;
  }
}

.bg--timber-small {
  &:before {
    height: 100px;
  }
}

.bg--timber-xsmall {
  &:before {
    height: 60px;
  }
}

// Facades
//---------

.bg--facades {
  background-color: palette(blue);
}

.bg--facades-light {
  background-color: palette(blue, light);
}

.bg--facades-large, .bg--facades-medium, .bg--facades-small {
  position: relative;
  background: palette(blue);

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    background-color: palette(white);
    top: 0;
    left: 0;
  }
}

.bg--facades-large {
  &:before {
    height: 300px;
  }
}

.bg--facades-medium {
  &:before {
    height: 220px;
  }
}

.bg--facades-small {
  &:before {
    height: 100px;
  }
}