//###########################################################################
// Hamburger
//###########################################################################

.navigation-burger {
  display: inline-block;
}

.hamburger {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  height: 20px;
  margin: 0;
  position: relative;
  width: 30px;
  outline: none;
  display: flex;

  span {
    height: 0;
    border-bottom: 3px solid white;
    width: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    transition: all 200ms ease-out;
    backface-visibility: hidden;
  }

  &:hover {
    span {width: 100%;}
  }

  &:after, &:before {
    position: absolute;
    border-bottom: 3px solid white;
    content: '';
    height: 0;
    width: 100%;
    left: 0;
    transition: all .3s ease-out;
  }

  &:before {
    top: 0;
  }

  &:after {
    top: 100%;
    width: 100%;
  }

  &.open {
    span{
      width: 100%;
      transform: translateY(-50%) rotate(45deg);
    }

    &:after, &:before {
      width: 100%;
      top: 50%;
      transform: translateY(-50%) rotate(-45deg);
    }
  }
}

@include breakpoint(medium) {
  .hamburger__hold { display: none !important; }
}

