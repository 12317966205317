//###########################################################################
// Projects
//###########################################################################

// Project image
//------------------

.project__image {
  position: relative;

  .details-panel {
    @include breakpoint(small) {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

// Project share
//------------------

.project__share {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Project filter
//------------------

.projects-filter {
  padding: 40px 0;

  @include breakpoint(medium) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    @include breakpoint(medium) {
      margin-right: 32px;
      margin-bottom: 0;
      width: auto;

      &:first-child {
        flex: 0 0 auto;
      }
    }

    @include breakpoint(large) {
      margin-right: 40px;
    }
  }

  select {
    @include breakpoint-max(medium) {
      margin-bottom: 20px;
    }

    @include breakpoint(medium) {
      max-width: 320px;

      &:not(:last-child) {
        margin-right: 32px;
      }
    }

    @include breakpoint(large) {
      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }
}
