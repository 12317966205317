//###########################################################################
// Typography
//###########################################################################

// Base
//------------------

body {
  font-family: $body-font;
}

// Headings
//------------------

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
  font-family: $heading-font;
  font-weight: bold;
  line-height: 1.35;
  margin-bottom: 25px;
}

h1, .h1 {
  font-size: $h1-size;
}

h2, .h2 {
  font-size: $h2-size;
}

h3, .h3 {
  font-size: $h3-size;
}

h4, .h4 {
  font-size: $h4-size;
}

// Text
//------------------

p, .p {
  font-family: $body-font;
  font-size: $p-size;
  line-height: 1.8;
  margin-bottom: 20px;
  font-weight: 300;
}

.small > p,
.small > .p,
.small {
  font-size: $p-size--small;
}

.big > p,
.big > .p,
.big {
  font-size: $p-size--big;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

// Body
//------------------

.body {
  strong {
    font-weight: 500;
  }

  ul {
    li {
      ul {
        padding-top: 16px;
        padding-bottom: 0;
      }

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  a:not(.btn) {
    transition: color 0.2s;

    &:hover {
      color: palette(black, slate);
    }
  }

  &--group {
    a:not(.btn) {
      &:hover {
        color: palette(blue, light);
      }
    }
  }

  &--facades {
    a:not(.btn) {
      &:hover {
        color: palette(blue, light);
      }
    }
  }

  &--timber {
    a:not(.btn) {
      &:hover {
        color: palette(green, light);
      }
    }
  }
}

// Lists
//------------------

ul, ol {
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: 25px;

  &.ul--unstyled, &.ul--row {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 0;
    }
  }

  &.ul--row {
    li {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li {
  font-size: $p-size;
  line-height: 1.5;
  font-weight: 300;
  padding-bottom: 15px;
}

.info-table {
  border-top: 2px solid white;

  .info-row {
    padding: 20px;
    border-bottom: 2px solid white;

    p {
      margin-bottom: 0;
    }
  }
}

.quote__hold {
  position: relative;
  padding-left: 40px;
  padding-right: 40px;

  .quote-mark {
    font-size: 8rem;
  }

  .quote-start {
    position: absolute;
    top: 0;
    left: 0;
  }

  .quote-end {
    position: absolute;
    bottom: -40px;
    right: 0;
  }

}