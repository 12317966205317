//###########################################################################
// Typography variables
//###########################################################################

/* poppins-300 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/poppins-v5-latin/poppins-v5-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Poppins Light'), local('Poppins-Light'),
  url('/fonts/poppins-v5-latin/poppins-v5-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/poppins-v5-latin/poppins-v5-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/poppins-v5-latin/poppins-v5-latin-300.woff') format('woff'), /* Modern Browsers */
  url('/fonts/poppins-v5-latin/poppins-v5-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/poppins-v5-latin/poppins-v5-latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/poppins-v5-latin/poppins-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Poppins Regular'), local('Poppins-Regular'),
  url('/fonts/poppins-v5-latin/poppins-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/poppins-v5-latin/poppins-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/poppins-v5-latin/poppins-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/fonts/poppins-v5-latin/poppins-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/poppins-v5-latin/poppins-v5-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/poppins-v5-latin/poppins-v5-latin/poppins-v5-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Poppins Medium'), local('Poppins-Medium'),
  url('/fonts/poppins-v5-latin/poppins-v5-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/poppins-v5-latin/poppins-v5-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/poppins-v5-latin/poppins-v5-latin-500.woff') format('woff'), /* Modern Browsers */
  url('/fonts/poppins-v5-latin/poppins-v5-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/poppins-v5-latin/poppins-v5-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/poppins-v5-latin/poppins-v5-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Poppins Bold'), local('Poppins-Bold'),
  url('/fonts/poppins-v5-latin/poppins-v5-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/poppins-v5-latin/poppins-v5-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/poppins-v5-latin/poppins-v5-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/fonts/poppins-v5-latin/poppins-v5-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/poppins-v5-latin/poppins-v5-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
}

// Fonts
//------------------

$body-font: 'Poppins', sans-serif;
$heading-font: 'Poppins', sans-serif;

// Base
//------------------

$base-font-size: 1.6rem;
$base-line-height: 28;
$min-font: 17px;
$max-font: 21px;

// Headings
//------------------

$h1-size: 5.8rem;
$h2-size: 3.8rem;
$h3-size: 2.8rem;
$h4-size: 2.4rem;

// Paragraphs
//------------------

$p-size: 1.6rem;
$p-size--small: 1.4rem;
$p-size--big: 2.2rem;
$small-font-size: 1.6rem;
$lead-font-size: 2.2rem;