//###########################################################################
// Newsletter
//###########################################################################

// Newsletter input
//------------------

.newsletter-input {
  position: relative;
  margin-bottom: 20px;

  input {
    margin: 0;
    padding-right: 110px;

    &:focus {
      border: 2px solid palette(black);
    }
  }

  .btn {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  }
}

// Newsletter bottom
//------------------

.newsletter-bottom {
  border-top: 4px solid palette(black);
  padding-top: 40px;
  padding-bottom: 40px;

  .newsletter-bottom__form {

    //padding: 0 52px 0 34px;
    flex: 0 0 auto;
    width: 100%;
  }

  @include breakpoint(small) {
    .newsletter-bottom__form {
      padding-left: 20px;
    }
  }

  @include breakpoint(medium) {
    .newsletter-bottom__form {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}