//###########################################################################
// Flooring selector filter
//###########################################################################

.flooring-selector-filter {

}

.flooring-selector-filter__menu-btn {
  position: relative;
  display: flex;
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;

  .menu-open & {
    margin-bottom: 40px;
  }

  &:focus {
    outline: none;
  }
}

.flooring-selector-filter__menu-btn__icon {
  position: relative;
  width: 48px;
  height: 48px;
  background: palette(green, lighter);
  margin-right: 12px;

  span {
    position: absolute;
    top: 50%;
    left: 0;
    width: 70%;
    height: 3px;
    background: palette(black);
    opacity: 1;
    transform-origin: center;
    transition: all 0.3s;

    &:nth-child(1) {
      top: 28%;
      transform: translateX(8px) translateY(-50%);

      .menu-open & {
        top: 50%;
        width: 75%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
      }
    }

    &:nth-child(2) {
      transform: translateX(8px) translateY(-50%);
      width: 50%;

      .menu-open & {
        width: 0%;
        opacity: 0;
      }
    }

    &:nth-child(3) {
      top: 70%;
      transform: translateX(8px) translateY(-50%);
      width: 35%;

      .menu-open & {
        top: 50%;
        left: 50%;
        width: 75%;
        transform: translateX(-50%) translateY(-50%) rotate(-45deg);
      }
    }
  }
}

.flooring-selector-filter__results {
  display: none;

  .menu-open & {
    display: block;
    padding-bottom: 40px;
  }
}