/* poppins-300 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/poppins-v5-latin/poppins-v5-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Poppins Light"), local("Poppins-Light"), url("/fonts/poppins-v5-latin/poppins-v5-latin-300.eot?#iefix") format("embedded-opentype"), url("/fonts/poppins-v5-latin/poppins-v5-latin-300.woff2") format("woff2"), url("/fonts/poppins-v5-latin/poppins-v5-latin-300.woff") format("woff"), url("/fonts/poppins-v5-latin/poppins-v5-latin-300.ttf") format("truetype"), url("/fonts/poppins-v5-latin/poppins-v5-latin-300.svg#Poppins") format("svg");
  /* Legacy iOS */ }

/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/poppins-v5-latin/poppins-v5-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Poppins Regular"), local("Poppins-Regular"), url("/fonts/poppins-v5-latin/poppins-v5-latin-regular.eot?#iefix") format("embedded-opentype"), url("/fonts/poppins-v5-latin/poppins-v5-latin-regular.woff2") format("woff2"), url("/fonts/poppins-v5-latin/poppins-v5-latin-regular.woff") format("woff"), url("/fonts/poppins-v5-latin/poppins-v5-latin-regular.ttf") format("truetype"), url("/fonts/poppins-v5-latin/poppins-v5-latin-regular.svg#Poppins") format("svg");
  /* Legacy iOS */ }

/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/poppins-v5-latin/poppins-v5-latin/poppins-v5-latin-500.eot");
  /* IE9 Compat Modes */
  src: local("Poppins Medium"), local("Poppins-Medium"), url("/fonts/poppins-v5-latin/poppins-v5-latin-500.eot?#iefix") format("embedded-opentype"), url("/fonts/poppins-v5-latin/poppins-v5-latin-500.woff2") format("woff2"), url("/fonts/poppins-v5-latin/poppins-v5-latin-500.woff") format("woff"), url("/fonts/poppins-v5-latin/poppins-v5-latin-500.ttf") format("truetype"), url("/fonts/poppins-v5-latin/poppins-v5-latin-500.svg#Poppins") format("svg");
  /* Legacy iOS */ }

/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/poppins-v5-latin/poppins-v5-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Poppins Bold"), local("Poppins-Bold"), url("/fonts/poppins-v5-latin/poppins-v5-latin-700.eot?#iefix") format("embedded-opentype"), url("/fonts/poppins-v5-latin/poppins-v5-latin-700.woff2") format("woff2"), url("/fonts/poppins-v5-latin/poppins-v5-latin-700.woff") format("woff"), url("/fonts/poppins-v5-latin/poppins-v5-latin-700.ttf") format("truetype"), url("/fonts/poppins-v5-latin/poppins-v5-latin-700.svg#Poppins") format("svg");
  /* Legacy iOS */ }

.bg--black {
  background-color: black; }

.bg--black-large, .bg--black-medium, .bg--black-small {
  position: relative;
  background-color: white; }
  .bg--black-large:before, .bg--black-medium:before, .bg--black-small:before {
    content: '';
    position: absolute;
    width: 100%;
    background-color: black;
    top: 0;
    left: 0; }

.bg--black-medium:before {
  height: 220px; }

.bg--white-large, .bg--white-medium, .bg--white-small {
  position: relative;
  background-color: black; }
  .bg--white-large:before, .bg--white-medium:before, .bg--white-small:before {
    content: '';
    position: absolute;
    width: 100%;
    background-color: white;
    top: 0;
    left: 0; }

.bg--white-large:before {
  height: 300px; }

.bg--white-medium:before {
  height: 220px; }

.bg--group-large, .bg--group-medium, .bg--group-small {
  position: relative;
  background-color: black; }
  .bg--group-large:before, .bg--group-medium:before, .bg--group-small:before {
    content: '';
    position: absolute;
    width: 100%;
    background-color: white;
    top: 0;
    left: 0; }

.bg--group-large:before {
  height: 300px; }

.bg--group-medium:before {
  height: 220px; }

.bg--group-small:before {
  height: 100px; }

.bg--timber {
  background-color: #6B775D; }

.bg--timber-light {
  background-color: #7B866E; }

.bg--timber-lighter {
  background-color: #9BAE88; }

.bg--timber-large, .bg--timber-medium-large, .bg--timber-medium, .bg--timber-small, .bg--timber-xsmall {
  position: relative;
  background: #6B775D; }
  .bg--timber-large:before, .bg--timber-medium-large:before, .bg--timber-medium:before, .bg--timber-small:before, .bg--timber-xsmall:before {
    content: '';
    position: absolute;
    width: 100%;
    background-color: #ffffff;
    top: 0;
    left: 0; }

.bg--timber-large:before {
  height: 300px; }

.bg--timber-medium-large:before {
  height: 260px; }

.bg--timber-medium:before {
  height: 220px; }

.bg--timber-small:before {
  height: 100px; }

.bg--timber-xsmall:before {
  height: 60px; }

.bg--facades {
  background-color: #216B77; }

.bg--facades-light {
  background-color: #38A4B5; }

.bg--facades-large, .bg--facades-medium, .bg--facades-small {
  position: relative;
  background: #216B77; }
  .bg--facades-large:before, .bg--facades-medium:before, .bg--facades-small:before {
    content: '';
    position: absolute;
    width: 100%;
    background-color: #ffffff;
    top: 0;
    left: 0; }

.bg--facades-large:before {
  height: 300px; }

.bg--facades-medium:before {
  height: 220px; }

.bg--facades-small:before {
  height: 100px; }

.bg--cover {
  background-size: 100%;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center; }

.bt--white {
  border-top: 2px solid #ffffff; }

.bb--white {
  border-bottom: 2px solid #ffffff; }

.br {
  display: block; }

main, .wrapper {
  *zoom: 1; }
  main:before, .wrapper:before, main:after, .wrapper:after {
    content: " ";
    display: table; }
  main:after, .wrapper:after {
    clear: both; }

.clear {
  clear: both; }
  .clear:after {
    content: "";
    display: table;
    clear: both; }

.color--white {
  color: #ffffff; }

.color--black {
  color: #000000; }

.color--grey {
  color: #7F7F7F; }

a.color--grey {
  transition: color .3s; }
  a.color--grey:hover {
    color: white; }

.color--green, .color--timber {
  color: #6B775D; }

.color--green-light {
  color: #7B866E; }

.color--green-lighter {
  color: #9BAE88; }

.color--blue, .color--facades {
  color: #216B77; }

.color--blue-light {
  color: #38A4B5; }

.color--red-error {
  color: #EE3D52; }

.cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.flex {
  display: flex; }

.justify-content-center {
  justify-content: center; }

.justify-content-between {
  justify-content: space-between; }

.align-items-center {
  align-items: center; }

.flex-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px; }
  .flex-grid .flex-col {
    width: 100%;
    padding: 0 16px; }
  @media (min-width: 768px) {
    .flex-grid .flex-col__1-2-small-up {
      width: 50%; } }
  @media (min-width: 992px) {
    .flex-grid .flex-col__1-4-medium-up {
      width: 25%; } }

.is-hidden {
  display: none; }

@media (min-width: 768px) {
  .is-hidden--sm {
    display: none !important; } }

@media (min-width: 992px) {
  .is-hidden--md {
    display: none !important; } }

@media (min-width: 1260px) {
  .is-hidden--lg {
    display: none !important; } }

.is-visible {
  display: block; }

.is-visible--md {
  display: none; }
  @media (min-width: 992px) {
    .is-visible--md {
      display: block !important; } }

.iframe-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0; }
  .iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 100%; }

.iframe-container--3-2 {
  padding-bottom: 66.66% !important; }

@media (max-width: 767px) {
  .iframe-container--3-2-small-down {
    padding-bottom: 66.66% !important; } }

.img--responsive {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }

.img--background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-family: 'object-fit: cover;'; }

.overflow-hidden {
  overflow: hidden; }

.overflow-x-hidden {
  overflow-x: hidden; }

.overflow-y-visible {
  overflow-y: visible; }

.bump--xs {
  margin-bottom: 10px !important; }

.bump {
  margin-bottom: 20px !important; }

.bump--sm {
  margin-bottom: 40px !important; }

.bump--md {
  margin-bottom: 60px !important; }

.bump--lg {
  margin-bottom: 80px !important; }

.bump--none {
  margin-bottom: 0 !important; }

.p-bump--xs {
  padding-bottom: 10px !important; }

.p-bump {
  padding-bottom: 20px !important; }

.p-bump-top--xs {
  padding-top: 10px !important; }

.p-bump-top {
  padding-top: 20px !important; }

@media (max-width: 767px) {
  .bump--small-down {
    margin-bottom: 20px !important; }
  .bump--sm-small-down {
    margin-bottom: 40px !important; } }

@media (max-width: 991px) {
  .bump--medium-down {
    margin-bottom: 20px !important; }
  .bump--sm-medium-down {
    margin-bottom: 40px !important; } }

@media (min-width: 992px) {
  .bump--none-medium-up {
    margin-bottom: 0 !important; } }

.bump-top {
  margin-top: 20px !important; }

.bump-top--sm {
  margin-top: 40px !important; }

.bump-top--md {
  margin-top: 60px !important; }

.bump-top--lg {
  margin-top: 80px !important; }

@media (max-width: 767px) {
  .bump-top--sm-small-down {
    margin-top: 40px !important; }
  .bump-top--xl-small-down {
    margin-top: 120px !important; } }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.nudge {
  margin-right: 15px !important; }

.p-nudge {
  padding-right: 15px !important; }

@media (min-width: 1260px) {
  .p-nudge-large-up {
    padding-right: 15px !important; }
  .p-nudge-lg-large-up {
    padding-right: 60px !important; } }

.padded {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.spacer {
  margin-bottom: 8vh; }
  .spacer--large {
    margin-bottom: 8vh; }
    @media (min-width: 768px) {
      .spacer--large {
        margin-bottom: 15vh; } }
  .spacer-top {
    margin-top: 8vh; }
    .spacer-top--large {
      margin-top: 8vh; }
      @media (min-width: 768px) {
        .spacer-top--large {
          margin-top: 15vh; } }

.p-spacer {
  padding-bottom: 8vh; }
  .p-spacer--large {
    padding-bottom: 8vh; }
    @media (min-width: 768px) {
      .p-spacer--large {
        padding-bottom: 15vh; } }
  .p-spacer--xlarge {
    padding-bottom: 8vh; }
    @media (min-width: 768px) {
      .p-spacer--xlarge {
        padding-bottom: 17.5vh; } }
  .p-spacer-top {
    padding-top: 8vh; }
    .p-spacer-top--large {
      padding-top: 8vh; }
      @media (min-width: 768px) {
        .p-spacer-top--large {
          padding-top: 15vh; } }

.tt-u {
  text-transform: uppercase !important; }

.under {
  text-decoration: underline !important; }

.td-n {
  text-decoration: none !important; }

.ta-c {
  text-align: center !important; }

.ta-l {
  text-align: left !important; }
  @media (min-width: 768px) {
    .ta-l--small-up {
      text-align: left !important; } }

.ta-r {
  text-align: right !important; }
  @media (min-width: 768px) {
    .ta-r--small-up {
      text-align: right !important; } }
  @media (min-width: 992px) {
    .ta-r--medium-up {
      text-align: right !important; } }

.bold,
.bold > * {
  font-weight: 800 !important; }

.medium {
  font-weight: 500 !important; }

.normal {
  font-weight: 400 !important; }

em, .italic {
  font-style: italic !important; }

.a11y {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden; }

.mw-165 {
  max-width: 165px !important; }

.mw-200 {
  max-width: 200px !important; }

.mw-250 {
  max-width: 250px !important; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

textarea {
  resize: none; }

a {
  color: inherit; }

a, button, input, select, p, li, label {
  font-family: inherit; }

html {
  height: 100%;
  overflow-x: hidden; }

html, body {
  max-width: 100%;
  font-size: 55% !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  min-height: 100% !important;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #000000;
  background: white; }
  @media (min-width: 1260px) {
    html, body {
      font-size: 62.5% !important;
      /* Normalise font size so that 1rem = 10px, 2rem = 20px etc. */ } }

body {
  position: relative;
  overflow-x: hidden;
  scroll-behavior: smooth; }
  body.fix {
    overflow: hidden; }

main {
  position: relative;
  z-index: 1;
  padding-top: 56px; }
  @media (min-width: 992px) {
    main {
      padding-top: 117px; } }
  @media (min-width: 1260px) {
    main {
      padding-top: 120px; } }

body {
  font-family: "Poppins", sans-serif; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  line-height: 1.35;
  margin-bottom: 25px; }

h1, .h1 {
  font-size: 5.8rem; }

h2, .h2 {
  font-size: 3.8rem; }

h3, .h3 {
  font-size: 2.8rem; }

h4, .h4 {
  font-size: 2.4rem; }

p, .p {
  font-family: "Poppins", sans-serif;
  font-size: 1.6rem;
  line-height: 1.8;
  margin-bottom: 20px;
  font-weight: 300; }

.small > p,
.small > .p,
.small {
  font-size: 1.4rem; }

.big > p,
.big > .p,
.big {
  font-size: 2.2rem; }

strong {
  font-weight: bold; }

em {
  font-style: italic; }

.body strong {
  font-weight: 500; }

.body ul li ul {
  padding-top: 16px;
  padding-bottom: 0; }

.body ul li p:last-child {
  margin-bottom: 0; }

.body a:not(.btn) {
  transition: color 0.2s; }
  .body a:not(.btn):hover {
    color: #2D2D2D; }

.body--group a:not(.btn):hover {
  color: #38A4B5; }

.body--facades a:not(.btn):hover {
  color: #38A4B5; }

.body--timber a:not(.btn):hover {
  color: #7B866E; }

ul, ol {
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: 25px; }
  ul.ul--unstyled, ul.ul--row, ol.ul--unstyled, ol.ul--row {
    list-style: none;
    margin: 0;
    padding: 0; }
    ul.ul--unstyled li, ul.ul--row li, ol.ul--unstyled li, ol.ul--row li {
      padding: 0; }
  ul.ul--row li, ol.ul--row li {
    display: inline-block;
    vertical-align: middle; }

ul {
  list-style: disc; }

ol {
  list-style: decimal; }

li {
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 300;
  padding-bottom: 15px; }

.info-table {
  border-top: 2px solid white; }
  .info-table .info-row {
    padding: 20px;
    border-bottom: 2px solid white; }
    .info-table .info-row p {
      margin-bottom: 0; }

.quote__hold {
  position: relative;
  padding-left: 40px;
  padding-right: 40px; }
  .quote__hold .quote-mark {
    font-size: 8rem; }
  .quote__hold .quote-start {
    position: absolute;
    top: 0;
    left: 0; }
  .quote__hold .quote-end {
    position: absolute;
    bottom: -40px;
    right: 0; }

/*
  NOTE

	This file should only contain css related to the grid
	just widths, margins and vertical spacing,
	Any other styling, such as background colours, should be moved to a different
	selector. Nount wrong with 4/5 classes on a single element.
	<div class="g__col gm__1-2 gt__1-3 gd__1-4 cta-box"></div>
	Ideally, if you can, move the cta styling to a child element, leaving the class
	with only grid properties. Oh so reusable, oh so portable
*/
.g {
  width: 100%;
  lost-flex-container: row; }
  .g > * {
    width: 100%; }

.g--ng > * {
  width: 50%;
  flex-basis: 50%; }

.g__ac {
  align-items: center; }

.g__jce {
  justify-content: end; }

/*
  Footer grid modification
*/
.g--gutter .g__1-2 {
  lost-column: 1/2 2 1.33334rem; }

.g--gutter .g__1-3 {
  lost-column: 1/3 3 1.33334rem; }

.g--gutter .g__2-3 {
  lost-column: 2/3 3 1.33334rem; }

.g__1-2 {
  lost-column: 1/2; }

.g__1-3 {
  lost-column: 1/3; }

.g__2-3 {
  lost-column: 2/3; }

@media (min-width: 768px) {
  .gs__ac {
    align-items: center; }
  .gs__jcc {
    justify-content: center; }
  .g--gutter .gs__1-2 {
    lost-column: 1/2 2 9.33334rem; }
  .g--gutter .gs__m1-2 {
    lost-move: 1/2 2 9.33334rem; }
  .g--gutter .gs__m-1-2 {
    lost-move: -1/2 2 9.33334rem; }
  .gs__1-1 {
    lost-column: 1/1; }
  .gs__1-2 {
    lost-column: 1/2; }
  .gs__1-3 {
    lost-column: 1/3; }
  .gs__2-3 {
    lost-column: 2/3; }
  .gs__2-8 {
    lost-column: 2/8; }
  .gs__6-8 {
    lost-column: 6/8; }
  .gs__6-10 {
    lost-column: 6/10; }
  .gs__4-10 {
    lost-column: 4/10; } }

@media (min-width: 992px) {
  .gm__ac {
    align-items: center; }
  .g--gutter .gm__1-2 {
    lost-column: 1/2 2 2rem; }
  .gm__1-2 {
    lost-column: 1/2; }
  .gm__1-3 {
    lost-column: 1/3; }
  .gm__2-3 {
    lost-column: 2/3; }
  .gm__1-4 {
    lost-column: 1/4; }
  .gm__3-4 {
    lost-column: 3/4; }
  .gm__2-8 {
    lost-column: 2/8; }
  .gm__6-8 {
    lost-column: 6/8; }
  .gm__4-10 {
    lost-column: 4/10; }
  .gm__6-10 {
    lost-column: 6/10; }
  .gm__5-12 {
    lost-column: 5/12; }
  .gm__7-12 {
    lost-column: 7/12; }
  .g--sidebar .gm__1-3 {
    lost-column: 1/3 5.33334rem; }
  .g--sidebar .gm__2-3 {
    lost-column: 2/3 5.33334rem; }
  .g--sidebar .gs__m1-3 {
    lost-move: 1/3 3 5.33334rem; }
  .g--sidebar .gs__m-2-3 {
    lost-move: -2/3 3 5.33334rem; } }

@media (min-width: 1260px) {
  .g--gutter .gl__1-3 {
    lost-column: 1/3 3 2rem; }
  .gl__1-2 {
    lost-column: 1/2; }
  .gl__1-3 {
    lost-column: 1/3; }
  .gl__1-4 {
    lost-column: 1/4; }
  .gl__3-4 {
    lost-column: 3/4; } }

.wrapper {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding: 0 1.33334rem; }
  @media (min-width: 768px) {
    .wrapper {
      width: 720px; } }
  @media (min-width: 992px) {
    .wrapper {
      width: 940px; } }
  @media (min-width: 1260px) {
    .wrapper {
      width: 1260px; } }

@media (min-width: 1260px) {
  .wrapper--tight {
    width: 980px; } }

@media (min-width: 992px) {
  .wrapper--x-tight {
    width: 780px; } }

@media (min-width: 768px) {
  .wrapper--xx-tight {
    width: 580px; } }

@media (min-width: 768px) {
  .wrapper--full {
    width: 100%; } }

#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  transition: transform .3s; }
  #header .show-desktop, #header .promo {
    display: none; }
  #header a {
    text-decoration: none; }
  #header .site-header {
    position: relative;
    z-index: 3;
    background-color: black;
    border-bottom: 4px solid white;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  #header .logo {
    display: inline-block;
    border-right: 4px solid white; }
    #header .logo a {
      display: block;
      font-size: 0; }
    #header .logo svg {
      width: 172px;
      height: 52px; }
  #header .nav__icon-group {
    padding-right: 15px; }
  #header .nav a {
    color: white; }
  #header .nav > li > a {
    font-weight: bold; }
  #header .top-menu {
    height: 38px;
    transition: all 0.3s;
    display: none;
    background-color: #2D2D2D; }
    #header .top-menu li {
      margin-right: 35px; }
      #header .top-menu li:last-of-type {
        margin-right: 0; }
    #header .top-menu p {
      margin-top: 10px;
      margin-bottom: 10px; }
    #header .top-menu a {
      color: white; }
      #header .top-menu a:hover {
        text-decoration: underline; }
  #header .site-nav__search {
    display: block;
    padding: 13px 12px 14px;
    transition: background .3s; }
    #header .site-nav__search.search--active {
      background: #2D2D2D; }
  #header .search__hold {
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 11;
    top: 56px;
    height: calc(100vh - 56px);
    transition: opacity .3s, visibility .3s;
    opacity: 0;
    visibility: hidden;
    padding: 100px 0; }
  #header .search__close {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: none;
    position: absolute;
    top: 0;
    right: 8px;
    transform: translateY(-200%);
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center; }
    #header .search__close:focus, #header .search__close:active {
      outline: none; }
    @media (min-width: 768px) {
      #header .search__close {
        right: 15px; } }
    @media (min-width: 992px) {
      #header .search__close {
        transform: translateY(-150%); } }
  #header .search__close__wrapper {
    position: relative; }
  #header .search {
    position: relative; }
    #header .search input {
      border: none;
      width: calc(100% - 60px); }
    #header .search .submit {
      border: none;
      width: 80px;
      background: #2D2D2D;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      cursor: pointer; }
  #header .cpd-bar, #header .fs-bar {
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    right: 0; }
    #header .cpd-bar .btn--icon-left, #header .fs-bar .btn--icon-left {
      padding-left: 0 !important; }
    #header .cpd-bar .counter, #header .fs-bar .counter {
      width: 20px;
      font-size: 1.6rem; }
  #header .fs-bar.fs-bar--padded {
    padding: 12px 0; }

#header.header--search .search__hold {
  opacity: 1;
  visibility: visible; }

#header.header--scrolled {
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2); }
  #header.header--scrolled .top-menu {
    height: 0;
    overflow: hidden; }

#header.header--hide {
  transform: translateY(-100%) !important; }
  #header.header--hide.header--open {
    transform: translateY(0) !important; }

#header.header--no-shadow {
  box-shadow: none; }

@media (max-width: 991px) {
  #header .show-inline-desktop {
    display: none; }
  #header .site-nav {
    background-color: black;
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 11;
    top: 56px;
    height: 100vh;
    transition: transform .3s;
    transform: translateX(-100%);
    overflow-y: scroll;
    overflow-x: hidden; }
  #header .nav {
    margin: 0;
    padding: 30px 30px 100px 30px; }
    #header .nav > li {
      padding: 0;
      border-bottom: 2px solid #323232; }
      #header .nav > li:first-of-type {
        border-top: 2px solid #323232; }
      #header .nav > li.menu-information {
        border-bottom: 0;
        padding-top: 20px; }
        #header .nav > li.menu-information p:first-of-type {
          margin-bottom: 10px; }
        #header .nav > li.menu-information .social li {
          margin-bottom: 15px; }
      #header .nav > li > a {
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 1.8rem;
        background-image: url("/dist/img/icons/arrow-right-white.svg");
        background-size: 20px 10px;
        background-repeat: no-repeat;
        background-position: center right; }
  #header .sub-nav {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    transform: translateX(100%);
    transition: transform .3s; }
    #header .sub-nav--facades {
      border-top: 4px solid #38A4B5; }
      #header .sub-nav--facades .sub-nav-dropdown-heading, #header .sub-nav--facades li > a, #header .sub-nav--facades .sub-nav-category-heading::before {
        background-image: url("/dist/img/icons/arrow-right-blue.svg") !important; }
    #header .sub-nav--timber {
      border-top: 4px solid #7B866E; }
      #header .sub-nav--timber .sub-nav-dropdown-heading, #header .sub-nav--timber li > a, #header .sub-nav--timber .sub-nav-category-heading::before {
        background-image: url("/dist/img/icons/arrow-right-green.svg") !important; }
    #header .sub-nav .sub-nav__inner {
      padding: 30px; }
    #header .sub-nav ul {
      padding: 0;
      margin: 0;
      list-style: none; }
    #header .sub-nav .sub-nav-dropdown-heading {
      color: white;
      margin: 0;
      font-size: 1.8rem;
      padding-top: 15px;
      padding-bottom: 15px;
      border-bottom: 2px solid #323232;
      position: relative;
      background-size: 20px 10px;
      background-repeat: no-repeat;
      background-position: center right; }
    #header .sub-nav .sub-nav-dropdown {
      position: absolute;
      padding: 30px 30px 100px;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: black;
      transform: translateX(100%);
      transition: transform .3s; }
      #header .sub-nav .sub-nav-dropdown > ul > li.open .sub-nav-category-heading::before {
        transform: translateY(-50%) rotate(-90deg); }
      #header .sub-nav .sub-nav-dropdown > ul > li.open .sub-nav-category {
        display: block; }
    #header .sub-nav .sub-nav-category-heading {
      background: none !important;
      position: relative; }
      #header .sub-nav .sub-nav-category-heading::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 20px;
        height: 10px;
        transform: translateY(-50%) rotate(90deg);
        background-size: 20px 10px;
        background-repeat: no-repeat;
        background-position: center right;
        transition: transform .3s; }
    #header .sub-nav .sub-nav-category {
      background-color: #151515;
      margin-left: -30px;
      margin-right: -30px;
      padding-left: 30px;
      padding-right: 30px;
      display: none; }
      #header .sub-nav .sub-nav-category li {
        border: none !important; }
      #header .sub-nav .sub-nav-category a {
        background: none !important; }
    #header .sub-nav .list.open .sub-nav-dropdown {
      transform: translateX(0%); }
    #header .sub-nav .list.open .sub-nav-dropdown-heading::before {
      transform: translateY(-50%) rotate(-90deg); }
    #header .sub-nav li {
      padding: 0;
      border-bottom: 2px solid #323232; }
      #header .sub-nav li > a {
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 25px;
        font-size: 1.8rem;
        background-image: url("/dist/img/icons/arrow-right-white.svg");
        background-size: 20px 10px;
        background-repeat: no-repeat;
        background-position: center right; }
  #header .sub-nav__back {
    color: white;
    font-size: 1.8rem;
    padding-left: 30px;
    font-weight: bold;
    background-image: url("/dist/img/icons/arrow-left-white.svg");
    background-size: 20px 10px;
    background-repeat: no-repeat;
    background-position: center left;
    border-bottom: 2px solid #323232;
    padding-bottom: 15px;
    padding-top: 15px; }
  #header .open .sub-nav {
    transform: translateX(0); }
  #header.header--open .site-nav {
    transform: translateX(0); } }

@media (max-width: 991px) {
  #header .wrapper {
    width: 100%; } }

@media (min-width: 992px) {
  #header.header--scrolled .search__hold {
    top: 79px;
    height: calc(100vh - 79px); }
  #header .search__hold {
    top: 117px;
    height: calc(100vh - 117px); }
  #header .search input {
    padding: 30px 20px;
    font-size: 2rem; }
  #header .site-nav__search {
    padding: 25px 25px 25px; }
  #header .top-menu, #header .show-desktop {
    display: block; }
  #header .show-inline-desktop {
    display: inline-block;
    padding: 0 !important; }
  #header .nav__icon-group, #header .hide-desktop {
    display: none; }
  #header .logo {
    display: inline-block;
    border-right: 4px solid white; }
    #header .logo a {
      display: block;
      font-size: 0; }
    #header .logo svg {
      width: 248px;
      height: 75px; }
  #header .nav > li {
    padding: 25px 17px; }
    #header .nav > li.link--active {
      background-color: #2d2d2d; }
    #header .nav > li:hover {
      background-color: #2D2D2D; }
  #header .sub-nav__hold {
    position: relative; }
    #header .sub-nav__hold.link--active {
      background-color: #2D2D2D; }
    #header .sub-nav__hold:hover {
      background-color: #2D2D2D; }
      #header .sub-nav__hold:hover .sub-nav {
        opacity: 1;
        visibility: visible; }
    #header .sub-nav__hold--facades:hover {
      background-color: #216B77 !important; }
    #header .sub-nav__hold--facades.link--active {
      background-color: #216B77 !important; }
    #header .sub-nav__hold--timber:hover {
      background-color: #6B775D !important; }
    #header .sub-nav__hold--timber.link--active {
      background-color: #6B775D !important; }
  #header .sub-nav {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100%;
    margin-top: 5px;
    width: 280px;
    border-left: 4px solid white;
    border-right: 4px solid white;
    border-bottom: 4px solid white;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s, visibility .3s; }
    #header .sub-nav a {
      font-weight: 500; }
      #header .sub-nav a:hover {
        text-decoration: underline; }
  #header .sub-nav__inner {
    background-color: black;
    position: relative;
    box-shadow: 0 0 13px 8px rgba(0, 0, 0, 0.15); }
  #header .sub-nav--big {
    width: 940px; }
    #header .sub-nav--big.sub-nav--facades {
      margin-left: 58px;
      border-bottom: 4px solid #38A4B5; }
      #header .sub-nav--big.sub-nav--facades li a:hover {
        color: #38A4B5;
        text-decoration: underline; }
    #header .sub-nav--big.sub-nav--timber {
      margin-left: -34px;
      border-bottom: 4px solid #7B866E; }
      #header .sub-nav--big.sub-nav--timber li a:hover {
        color: #7B866E;
        text-decoration: underline; }
    #header .sub-nav--big .sub-nav__inner {
      display: flex;
      min-height: 460px; }
    #header .sub-nav--big .list {
      flex: 1;
      padding: 45px 0; }
      #header .sub-nav--big .list > p {
        font-weight: bold;
        color: white;
        font-size: 1.8rem;
        padding-left: 30px;
        padding-right: 15px; }
    #header .sub-nav--big a {
      padding-left: 30px;
      padding-right: 15px;
      display: block; }
    #header .sub-nav--big .sub-nav-category {
      display: none; }
    #header .sub-nav--big .promo {
      width: 30%;
      background: white;
      position: relative;
      transition: opacity .3s; }
      #header .sub-nav--big .promo:hover {
        opacity: .6; }
      #header .sub-nav--big .promo .overlay {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 30px 50px 30px 20px;
        background-color: rgba(0, 0, 0, 0.7); }
        #header .sub-nav--big .promo .overlay .icon {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%); }
        #header .sub-nav--big .promo .overlay p {
          margin-bottom: 0; }
  #header .sub-nav__back {
    display: none; }
  #header .sub-nav-dropdown li:hover .sub-nav-category {
    display: block; }
  #header .sub-nav-category {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #191919;
    left: 0;
    width: 47%;
    margin-left: 23%;
    padding-top: 47px;
    z-index: 3; }
    #header .sub-nav-category li {
      width: 48%;
      display: inline-block !important;
      vertical-align: top !important; }
  #header .list {
    text-align: left;
    padding: 45px 30px; }
    #header .list .breaker {
      color: white;
      margin: 0;
      padding-left: 30px;
      padding-right: 15px;
      padding-top: 18px; }
    #header .list li {
      display: block;
      padding-bottom: 25px; }
      #header .list li:last-of-type {
        padding-bottom: 0; }
  #header nav ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  #header nav li {
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 0; } }

@media (min-width: 1260px) {
  #header .top-menu {
    height: 41px; }
  #header .search__hold {
    text-align: left !important; }
  #header .site-header {
    position: relative;
    height: 79px;
    text-align: right;
    justify-content: flex-end; }
  #header .list {
    text-align: left; }
  #header .nav__group {
    width: 1260px;
    margin-right: auto;
    margin-left: auto; }
  #header .logo {
    position: absolute;
    top: 0;
    left: 0; }
  #header .sub-nav {
    margin-top: 4px; }
  #header .sub-nav--big {
    width: 1260px; }
    #header .sub-nav--big .list > p {
      padding-left: 50px; }
    #header .sub-nav--big .breaker {
      padding-left: 50px; }
    #header .sub-nav--big a {
      padding-left: 50px; }
    #header .sub-nav--big.sub-nav--facades {
      margin-left: -30px; }
    #header .sub-nav--big.sub-nav--timber {
      margin-left: -130px; } }

#footer {
  padding-top: 45px;
  background-color: black; }
  #footer a {
    text-decoration: none; }
  #footer .social {
    margin-bottom: 30px; }
    #footer .social li {
      margin-bottom: 15px; }
  #footer a:hover {
    color: #38A4B5;
    transition: color .3s; }
    #footer a:hover .icon svg path {
      fill: #38A4B5; }
  #footer .address p {
    line-height: 2; }
  #footer .footer-logos img {
    max-height: 190px; }
  #footer .legals {
    margin-bottom: 20px; }
    #footer .legals li {
      position: relative;
      margin-bottom: 10px;
      padding-right: 20px; }
      #footer .legals li::before {
        content: "|";
        position: absolute;
        right: 0;
        color: white; }
      #footer .legals li:last-of-type {
        padding-right: 0; }
        #footer .legals li:last-of-type::before {
          content: none; }
    #footer .legals a {
      text-decoration: none;
      font-size: 1.3rem; }
  #footer .copy {
    background-color: #353535;
    border-top: 4px solid white; }
    #footer .copy p {
      color: white;
      padding-top: 20px; }

body.debug:after {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  text-transform: uppercase;
  padding: 17px 25px;
  position: fixed;
  font-size: 15px;
  font-weight: 700;
  bottom: 45px;
  right: 0;
  z-index: 999; }
  @media (max-width: 767px) {
    body.debug:after {
      content: "x-small"; } }
  @media (min-width: x-small) {
    body.debug:after {
      content: "x-small"; } }
  @media (min-width: 768px) {
    body.debug:after {
      content: "small"; } }
  @media (min-width: 992px) {
    body.debug:after {
      content: "medium"; } }
  @media (min-width: 1260px) {
    body.debug:after {
      content: "large"; } }

.arrow-block {
  position: relative; }

.arrow-block__inner {
  position: relative;
  max-width: 785px;
  margin: 0 auto;
  text-align: center;
  z-index: 1;
  padding: 0 1.33334rem; }

.arrow-block__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }
  .arrow-block__background img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .arrow-block__background--tinted:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1; }

.accordion__container {
  position: relative;
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .accordion__container {
      margin-bottom: 40px; } }

.accordion__item {
  position: relative;
  background: transparent;
  border-top: 2px solid #ffffff;
  transition: background 0.2s; }
  .accordion__item:last-child {
    border-bottom: 2px solid #ffffff; }
  .accordion__item .accordion__header {
    padding: 8px 0px 8px 5px; }
    @media (min-width: 992px) {
      .accordion__item .accordion__header {
        padding: 26px 6px 26px 24px; } }
  .accordion__item .accordion__heading {
    font-weight: bold;
    margin-bottom: 0; }
  .accordion__item .accordion__content {
    overflow: hidden;
    display: none;
    padding: 0 5px 20px 5px; }
    @media (min-width: 768px) {
      .accordion__item .accordion__content {
        padding: 0 40px 30px 40px; } }
    @media (min-width: 992px) {
      .accordion__item .accordion__content {
        padding: 0 60px 40px 60px; } }
    @media (min-width: 1260px) {
      .accordion__item .accordion__content {
        padding: 0 80px 50px 80px; } }
    .accordion__item .accordion__content .inner {
      border-top: dotted 2px transparent;
      transition: border .3s; }
      .accordion__item .accordion__content .inner p:last-of-type {
        margin-bottom: 0; }
  .accordion__item .accordion__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer; }
    .accordion__item .accordion__header h3 {
      margin-bottom: 0; }
    .accordion__item .accordion__header .accordion__trigger {
      position: relative;
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: none;
      width: 44px;
      height: 44px; }
      .accordion__item .accordion__header .accordion__trigger:focus, .accordion__item .accordion__header .accordion__trigger:active {
        outline: none; }
      .accordion__item .accordion__header .accordion__trigger .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
        .accordion__item .accordion__header .accordion__trigger .icon span {
          width: 15px;
          height: 3px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: #ffffff; }
          .accordion__item .accordion__header .accordion__trigger .icon span:nth-child(2) {
            transform: translate(-50%, -50%) rotate(90deg);
            transition: transform 0.2s; }
      .accordion__item .accordion__header .accordion__trigger:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
  .accordion__item--dark {
    border-top: 2px solid black; }
    .accordion__item--dark .accordion__header .accordion__trigger .icon span {
      background: black; }
    .accordion__item--dark:last-child {
      border-bottom: 2px solid black; }
  .accordion__item.open {
    background: rgba(255, 255, 255, 0.1); }
    .accordion__item.open .accordion__trigger .icon span:nth-child(2) {
      transform: translate(-50%, -50%) rotate(0deg); }
    .accordion__item.open .accordion__content {
      display: block; }

@media (min-width: 768px) {
  .accordion__item .accordion__data .inner {
    padding-left: 10px; } }

/* purgecss end ignore */
.block {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch; }
  .block--flip {
    flex-direction: row-reverse; }
  .block .block__image {
    position: relative;
    flex: 0 0 auto;
    width: 100%;
    height: 200px; }
    @media (min-width: 768px) {
      .block .block__image {
        width: 50%;
        height: auto; } }
    .block .block__image img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .block .block__content {
    padding: 42px 32px 48px 32px;
    flex: 0 0 auto;
    width: 100%; }
    @media (min-width: 768px) {
      .block .block__content {
        width: 50%;
        padding: 52px 40px 60px 40px; } }
    @media (min-width: 1260px) {
      .block .block__content {
        padding: 52px 70px 60px 70px; } }
  .block--timber .block__content {
    background: #9BAE88; }
  .block--facades .block__content {
    background: #38A4B5; }
  .block--group .block__content {
    background: #38A4B5; }

.block-full .inner {
  padding: 50px 20px;
  max-width: 540px;
  margin: 0 auto; }

.block-full .block__logo {
  max-width: 180px;
  margin: 0 0 30px; }

.block-full .block__half {
  position: relative; }
  .block-full .block__half--img {
    min-height: 400px; }
  .block-full .block__half--tint::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
    opacity: .4;
    z-index: 2; }
  .block-full .block__half--tint .inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 3; }

@media (min-width: 992px) {
  .block-full {
    display: flex; }
    .block-full--flip {
      flex-direction: row-reverse; }
    .block-full > * {
      flex: 1; }
    .block-full .inner {
      padding: 100px 40px;
      max-width: 580px;
      margin: 0 auto; } }

.block-help {
  border: 3px solid black;
  padding: 40px 30px 30px;
  position: relative; }
  .block-help h2 {
    text-align: center;
    position: absolute;
    top: 0;
    transform: translateY(-50%) translateX(-50%);
    background-color: white;
    left: 50%;
    padding-left: 20px;
    padding-right: 20px; }
  .block-help select {
    margin-bottom: 20px; }
  @media (min-width: 992px) {
    .block-help select {
      margin-bottom: 0; }
    .block-help .btn {
      width: 100%; } }

.block-resources {
  position: relative;
  overflow: hidden; }

.map__container {
  position: relative;
  z-index: 2; }

.map__hold {
  position: relative; }
  .map__hold #map {
    width: 100%;
    height: 380px; }
  .map__hold .btn {
    position: absolute;
    bottom: 0;
    left: 30px;
    transform: translateY(50%); }
  @media (min-width: 768px) {
    .map__hold {
      margin-top: -60px; }
      .map__hold #map {
        height: 600px; } }

.btn {
  text-decoration: none;
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.375;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  background-color: transparent;
  border-color: #000000;
  border-width: 2px;
  border-style: solid;
  color: #000000;
  outline: none;
  padding: 12px 20px;
  display: inline-block;
  cursor: pointer;
  transition: all .3s;
  user-select: none; }
  .btn:hover, .btn:focus {
    background-color: #1a1a1a;
    color: #ffffff; }

.btn--small {
  font-size: 1.7rem;
  line-height: 2.3rem;
  padding: 7px 20px; }

.btn--large {
  font-size: 2.8rem;
  line-height: 3.3rem;
  padding: 14px 40px; }

.btn--icon-left .icon {
  position: relative;
  top: -1px;
  margin-right: 20px; }

.btn--icon-right .icon {
  position: relative;
  top: -1px;
  margin-left: 20px; }

.btn--white {
  color: #ffffff;
  border-color: #ffffff;
  background-color: transparent; }
  .btn--white:hover, .btn--white:focus {
    color: #ffffff;
    border-color: #e6e6e6;
    background-color: #e6e6e6; }

.btn--black {
  color: #000000;
  border-color: #000000;
  background-color: transparent; }
  .btn--black:hover, .btn--black:focus {
    background: #2D2D2D;
    color: #ffffff; }
    .btn--black:hover .icon path, .btn--black:focus .icon path {
      fill: #ffffff !important; }

.btn--timber {
  color: #000000;
  border-color: #9BAE88;
  background-color: transparent; }
  .btn--timber.color--white {
    color: white; }
  .btn--timber:hover, .btn--timber:focus {
    border-color: #6B775D;
    background-color: #9BAE88; }

.btn--facades {
  color: #000000;
  border-color: #38A4B5;
  background-color: transparent; }
  .btn--facades.color--white {
    color: white; }
  .btn--facades:hover, .btn--facades:focus {
    color: #000000;
    border-color: #216B77;
    background-color: #3CB6C9; }

.btn--white-fill {
  color: #000000;
  border-color: #ffffff;
  background-color: #ffffff; }
  .btn--white-fill:hover, .btn--white-fill:focus {
    color: #000000;
    border-color: #e6e6e6;
    background-color: #e6e6e6; }

.btn--black-fill {
  color: #ffffff;
  border-color: #000000;
  background-color: #000000; }
  .btn--black-fill:hover, .btn--black-fill:focus {
    color: #ffffff;
    border-color: #2D2D2D;
    background: #2D2D2D; }

.btn--timber-fill {
  color: #000000;
  border-color: #9BAE88;
  background-color: #9BAE88; }
  .btn--timber-fill:hover, .btn--timber-fill:focus {
    color: #000000;
    border-color: #B1C59D;
    background-color: #B1C59D; }

.btn--facades-fill {
  color: #000000;
  border-color: #38A4B5;
  background-color: #38A4B5; }
  .btn--facades-fill:hover, .btn--facades-fill:focus {
    color: #000000;
    border-color: #3CB6C9;
    background-color: #3CB6C9; }

.btn--hcwh {
  padding: 1.65rem 20px; }
  @media (min-width: 1260px) {
    .btn--hcwh {
      padding: 1.55rem 20px; } }

@media (max-width: 767px) {
  .btn--bottom-small-down {
    position: relative;
    padding-bottom: 50px; }
    .btn--bottom-small-down .wrapper {
      position: unset; }
    .btn--bottom-small-down .btn {
      position: absolute;
      bottom: 0;
      left: 1.33334rem; } }

@media (max-width: 767px) {
  .btn-resources--bottom-small-down {
    margin-bottom: 20vh; }
    .btn-resources--bottom-small-down .btn {
      position: absolute;
      bottom: -80px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 240px; } }

.career__info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px; }

.career__info__item {
  display: flex;
  align-items: center; }
  .career__info__item:not(:last-child) {
    margin-right: 22px; }
  .career__info__item svg {
    margin-right: 10px; }
  .career__info__item p {
    font-size: 1.6rem;
    line-height: 1;
    margin-bottom: 0; }

#cookie-message {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  background-color: black;
  max-width: 600px;
  border: 2px solid white;
  padding: 30px 30px 10px; }
  #cookie-message p {
    color: white;
    margin-bottom: 20px; }
  #cookie-message .btn {
    margin-right: 15px;
    margin-bottom: 20px; }
  #cookie-message .wrapper {
    padding: 2.66667rem 1.33334rem; }

@media (min-width: 992px) {
  #cookie-message .wrapper {
    padding: 40px; } }

.cta {
  padding: 32px;
  background: #000000;
  color: #ffffff; }
  @media (min-width: 768px) {
    .cta {
      padding: 44px 50px; } }
  @media (min-width: 992px) {
    .cta {
      padding: 48px 60px; } }

.cta-contact {
  border-bottom: 4px solid white; }
  .cta-contact .wrapper {
    position: relative;
    z-index: 2; }
  .cta-contact .inner {
    padding: 30px;
    border: 3px solid black;
    position: relative;
    margin-bottom: -100px;
    background-color: white; }
    .cta-contact .inner .btn {
      position: absolute;
      bottom: 0;
      left: 30px;
      transform: translateY(50%); }
  .cta-contact p a {
    font-weight: 500; }
  .cta-contact .cta-image {
    position: relative;
    min-height: 180px;
    background-color: white;
    opacity: .6; }
  @media (min-width: 768px) {
    .cta-contact .inner {
      padding: 40px 70px; }
      .cta-contact .inner .btn {
        left: 70px; }
    .cta-contact .cta-image {
      min-height: 240px; } }

.cta-contact--timber p a {
  color: #7B866E; }

.cta-contact--facades p a {
  color: #3CB6C9; }

.cta-contact--404 {
  margin-top: -91px; }
  @media (min-width: 768px) {
    .cta-contact--404 {
      margin-top: -213px; } }
  @media (min-width: 992px) {
    .cta-contact--404 {
      margin-top: -137px; } }
  @media (min-width: 1260px) {
    .cta-contact--404 {
      margin-top: -152px; } }

.cta-custom {
  border: 3px solid black; }
  .cta-custom .img {
    position: relative;
    min-height: 300px;
    margin-bottom: -1px; }
    .cta-custom .img--tint::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: black;
      opacity: .4;
      z-index: 2; }
    .cta-custom .img .img__text {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      padding: 30px;
      z-index: 3;
      transform: translateX(-50%) translateY(-50%); }
  .cta-custom .text .inner {
    padding: 30px;
    position: relative; }
  .cta-custom--selector h2 {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    top: 0;
    left: 50%;
    white-space: nowrap;
    background-color: white;
    transform: translateX(-50%) translateY(-50%); }
  .cta-custom--selector .text .inner {
    padding: 60px 30px 30px; }
  @media (min-width: 992px) {
    .cta-custom {
      display: flex; }
      .cta-custom--selector {
        flex-direction: row-reverse; }
      .cta-custom > * {
        flex: 1; }
      .cta-custom .text .inner {
        padding: 50px 70px; } }

.decor__hold {
  position: relative; }

.decor__container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  pointer-events: none; }
  @media (max-width: 767px) {
    .decor__container {
      display: none; } }

.decor {
  color: #ffffff;
  opacity: 0.1;
  font-size: 14rem;
  font-weight: bold;
  line-height: 0.7;
  bottom: 0;
  left: 0; }

.decor--b-l {
  text-align: left; }

.decor--b-r {
  text-align: right; }

.details-panel {
  width: 100%;
  padding: 32px;
  background: #000000;
  color: #ffffff; }
  @media (min-width: 768px) {
    .details-panel {
      width: 90%; } }
  @media (min-width: 992px) {
    .details-panel {
      padding: 36px 60px;
      width: 85%; } }
  @media (min-width: 1260px) {
    .details-panel {
      padding: 40px 100px; } }
  .details-panel--right {
    margin-left: auto; }

.details-panel__inner {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: -16px;
  margin-right: -16px; }
  @media (min-width: 768px) {
    .details-panel__inner {
      display: flex;
      justify-content: center;
      flex-direction: row; } }
  .details-panel__inner p {
    margin-bottom: 0; }

@media (min-width: 768px) and (max-width: 960px) {
  .details-panel__inner p {
    font-size: 1.4rem; } }

.details-panel__item {
  margin: 0 16px;
  flex-grow: 1;
  flex-basis: 0; }
  @media (max-width: 767px) {
    .details-panel__item {
      margin-bottom: 20px; } }

.flooring-selector-filter__menu-btn {
  position: relative;
  display: flex;
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer; }
  .menu-open .flooring-selector-filter__menu-btn {
    margin-bottom: 40px; }
  .flooring-selector-filter__menu-btn:focus {
    outline: none; }

.flooring-selector-filter__menu-btn__icon {
  position: relative;
  width: 48px;
  height: 48px;
  background: #9BAE88;
  margin-right: 12px; }
  .flooring-selector-filter__menu-btn__icon span {
    position: absolute;
    top: 50%;
    left: 0;
    width: 70%;
    height: 3px;
    background: #000000;
    opacity: 1;
    transform-origin: center;
    transition: all 0.3s; }
    .flooring-selector-filter__menu-btn__icon span:nth-child(1) {
      top: 28%;
      transform: translateX(8px) translateY(-50%); }
      .menu-open .flooring-selector-filter__menu-btn__icon span:nth-child(1) {
        top: 50%;
        width: 75%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(45deg); }
    .flooring-selector-filter__menu-btn__icon span:nth-child(2) {
      transform: translateX(8px) translateY(-50%);
      width: 50%; }
      .menu-open .flooring-selector-filter__menu-btn__icon span:nth-child(2) {
        width: 0%;
        opacity: 0; }
    .flooring-selector-filter__menu-btn__icon span:nth-child(3) {
      top: 70%;
      transform: translateX(8px) translateY(-50%);
      width: 35%; }
      .menu-open .flooring-selector-filter__menu-btn__icon span:nth-child(3) {
        top: 50%;
        left: 50%;
        width: 75%;
        transform: translateX(-50%) translateY(-50%) rotate(-45deg); }

.flooring-selector-filter__results {
  display: none; }
  .menu-open .flooring-selector-filter__results {
    display: block;
    padding-bottom: 40px; }

select.form-pusher {
  transition: opacity .3s; }
  select.form-pusher.changing {
    opacity: .2; }

.form button[type=submit] {
  opacity: 1;
  transition: opacity 0.3s; }

.form--light {
  color: #ffffff; }
  .form--light input, .form--light textarea, .form--light select {
    border: 2px solid #ffffff; }
  .form--light .checkbox label:before {
    border: 1px solid #ffffff; }
  .form--light .checkbox label:after {
    color: #000000; }
  .form--light .checkbox input[type="checkbox"]:checked + label:before {
    background-color: #ffffff;
    border-color: #ffffff; }

input {
  font-family: "Poppins", sans-serif;
  width: 100%;
  border: 2px solid #000000;
  border-radius: 0;
  font-size: 1.6rem;
  padding: 15px;
  outline: none;
  -webkit-appearance: none;
  /* for box shadows to show on iOS */
  transition: background .3s, border .5s; }
  input:focus {
    border-color: #a1a1a1;
    background-color: #fbfbfb; }
  input:not(:placeholder-shown) {
    background-color: #fbfbfb; }
  input::placeholder {
    color: #8c8c8c; }
  input[disabled] {
    background-color: #f7f7f8;
    cursor: not-allowed; }

input[type=checkbox] {
  -webkit-appearance: checkbox;
  margin: 0; }

input[type=file] {
  position: relative;
  cursor: pointer;
  padding: 14px 15px;
  color: #000000; }
  input[type=file]::-webkit-file-upload-button {
    visibility: hidden; }
  input[type=file]::before {
    content: 'Select a file';
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    color: #8c8c8c;
    white-space: nowrap;
    font-size: 1.6rem;
    pointer-events: none; }
  input[type=file]:after {
    content: 'Choose file';
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 1.4rem;
    background: #000000;
    color: #ffffff;
    padding: 10px 16px; }

textarea {
  font-family: "Poppins", sans-serif;
  width: 100%;
  border: 2px solid #000000;
  border-radius: 0;
  font-size: 1.6rem;
  line-height: 1.5;
  padding: 15px;
  outline: none;
  transition: background .3s, border .5s; }
  textarea:focus {
    border-color: #a1a1a1;
    background-color: #fbfbfb; }
  textarea:not(:empty) {
    background-color: #fbfbfb; }

select {
  font-family: "Poppins", sans-serif;
  width: 100%;
  font-size: 1.6rem;
  line-height: 1.2;
  padding: 17px 40px 17px 15px;
  outline: none;
  -webkit-appearance: none;
  /* for box shadows to show on iOS */
  min-height: 50px;
  border: 2px solid #000000;
  border-radius: 0;
  background-image: url("../img/icons/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: right 16px top 50%;
  color: #000000; }

label {
  display: block;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 300;
  margin-bottom: 12px; }

.input__hold {
  margin-bottom: 20px; }

.input__hold--fancy {
  margin-top: 24px;
  margin-bottom: 20px;
  position: relative; }
  .input__hold--fancy label {
    margin-top: 12px;
    margin-bottom: 0;
    -webkit-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    position: absolute;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    color: #333;
    pointer-events: none;
    font-weight: 400;
    font-size: 18px;
    left: 0;
    top: 50px; }
    .input__hold--fancy label span {
      padding-left: 10px;
      font-size: 1rem;
      display: inline-block;
      color: #c7192e; }
  .input__hold--fancy input, .input__hold--fancy textarea, .input__hold--fancy select {
    width: 100%;
    border: none;
    border-bottom: 2px solid black;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    color: #333; }
  .input__hold--fancy input:focus ~ label, .input__hold--fancy input.active--input ~ label {
    -webkit-transform: translateY(-75px);
    transform: translateY(-75px);
    letter-spacing: 1px;
    font-size: 14px; }
  .input__hold--fancy input[type=file] {
    border: 0;
    padding-top: 0;
    width: 100%;
    display: flex; }
  .input__hold--fancy textarea:focus ~ label, .input__hold--fancy textarea.active--input ~ label {
    -webkit-transform: translateY(-75px);
    transform: translateY(-75px);
    font-size: 14px; }
  .input__hold--fancy select {
    width: 100%;
    font-size: 18px;
    transition: border .5s; }
  .input__hold--fancy.active--input select {
    border-bottom: 2px solid white; }
    .input__hold--fancy.active--input select ~ label {
      color: #333;
      -webkit-transform: translateY(-75px);
      transform: translateY(-75px);
      letter-spacing: 1px;
      font-size: 14px; }

.checkbox {
  display: flex; }
  .checkbox--white label:before {
    border: 1px solid #ffffff !important; }
  .checkbox--white label:after {
    color: #000000 !important; }
  .checkbox--white input[type="checkbox"]:checked + label:before {
    background-color: #ffffff !important;
    border-color: #ffffff !important; }
  .checkbox label {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    line-height: 1.6;
    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: 30px; }
    .checkbox label::before, .checkbox label::after {
      position: absolute;
      content: "";
      /*Needed for the line-height to take effect*/
      display: inline-block; }
    .checkbox label::before {
      height: 16px;
      width: 16px;
      border: 1px solid #000000;
      left: 1px;
      background-color: transparent;
      /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
       *to vertically center it.
       */
      top: 3px; }
    .checkbox label::after {
      color: #ffffff;
      height: 5px;
      width: 9px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      left: 4px;
      top: 7px; }
  .checkbox input[type="checkbox"] {
    display: none; }
    .checkbox input[type="checkbox"] + label::after {
      content: none; }
    .checkbox input[type="checkbox"]:checked + label:before {
      background-color: #000000;
      border-color: #000000; }
    .checkbox input[type="checkbox"]:checked + label::after {
      content: ""; }
    .checkbox input[type="checkbox"]:focus + label::before {
      outline: #3b99fc auto 5px; }
    .checkbox input[type="checkbox"]:disabled ~ label:before, .checkbox input[type="checkbox"]:disabled ~ label:after {
      opacity: .5; }
  .checkbox.error label::before, .checkbox.error label::after {
    animation: shake .5s ease-in-out forwards; }

.checkbox__group .checkbox {
  display: inline-flex; }

.checkbox__group ul {
  margin: 0;
  padding: 0; }
  .checkbox__group ul li {
    width: 49.5%;
    padding-right: 25px; }
    .checkbox__group ul li:last-of-type {
      margin-right: 0; }

.checkbox__group input {
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden; }

.opt-in-error {
  border: 1px solid #EE3D52;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 20px; }
  .opt-in-error > p {
    margin-bottom: 0; }

#firstName {
  display: none; }

.form-careers__textarea-col {
  display: flex;
  flex-direction: column; }
  .form-careers__textarea-col .input__hold--textarea {
    flex: 1 1 auto; }
    .form-careers__textarea-col .input__hold--textarea textarea {
      height: calc(100% - 24px); }

.input--error {
  border-color: #EE3D52; }

.form-container {
  transition: all .3s; }

.form-hold {
  position: relative; }

.form-complete {
  opacity: 0;
  visibility: hidden;
  pointer-events: none; }

.form-hold.form--complete .form-container {
  opacity: 0;
  visibility: hidden; }

.form-hold.form--complete .form-complete {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  opacity: 1;
  visibility: visible;
  pointer-events: auto; }

.form--submitting button[type=submit] {
  animation: submitPulse 2s infinite;
  animation-delay: 0.3s;
  opacity: 0.25;
  pointer-events: none; }

@keyframes submitPulse {
  0% {
    opacity: 0.25; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 0.25; } }

.navigation-burger {
  display: inline-block; }

.hamburger {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  height: 20px;
  margin: 0;
  position: relative;
  width: 30px;
  outline: none;
  display: flex; }
  .hamburger span {
    height: 0;
    border-bottom: 3px solid white;
    width: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    transition: all 200ms ease-out;
    backface-visibility: hidden; }
  .hamburger:hover span {
    width: 100%; }
  .hamburger:after, .hamburger:before {
    position: absolute;
    border-bottom: 3px solid white;
    content: '';
    height: 0;
    width: 100%;
    left: 0;
    transition: all .3s ease-out; }
  .hamburger:before {
    top: 0; }
  .hamburger:after {
    top: 100%;
    width: 100%; }
  .hamburger.open span {
    width: 100%;
    transform: translateY(-50%) rotate(45deg); }
  .hamburger.open:after, .hamburger.open:before {
    width: 100%;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg); }

@media (min-width: 992px) {
  .hamburger__hold {
    display: none !important; } }

.hero {
  position: relative; }
  @media (max-width: 767px) {
    .hero h1 {
      font-size: 4.0rem; } }

.hero-simple {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: black; }
  .hero-simple .hero__logo {
    max-width: 180px;
    margin: 0 0 30px; }
  @media (min-width: 768px) {
    .hero-simple {
      padding-top: 120px;
      padding-bottom: 100px; } }
  .hero-simple .search {
    position: relative;
    max-width: 400px;
    border: 2px solid #000000; }
    .hero-simple .search input {
      border: none;
      width: calc(100% - 60px); }
    .hero-simple .search .submit {
      border: none;
      width: 60px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
      background: #eaeaea; }
    .hero-simple .search--large {
      max-width: 100%; }
      .hero-simple .search--large input {
        padding: 20px 20px; }
      .hero-simple .search--large .submit {
        background: #2D2D2D; }
        .hero-simple .search--large .submit svg path {
          fill: #ffffff; }
  .hero-simple--light {
    background-color: #ffffff;
    color: #000000; }
  .hero-simple--dark {
    background-color: #000000;
    color: #ffffff; }

.hero--products-pull-up {
  margin-top: -30px; }
  @media (min-width: 768px) {
    .hero--products-pull-up {
      margin-top: -80px; } }

.hero-media {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: black; }
  .hero-media--image::before {
    content: "";
    background-color: black;
    z-index: 1;
    opacity: .6;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .hero-media .hero__logo {
    max-width: 360px;
    margin: 0 auto 30px; }
  .hero-media .btn__group {
    position: relative;
    padding: 50px 0 35px;
    border: 2px solid white;
    max-width: 100%;
    width: 490px;
    margin: 0 auto; }
    .hero-media .btn__group p {
      padding-left: 10px;
      padding-right: 10px;
      margin: 0;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      background-color: black; }
  .hero-media .wrapper {
    position: relative;
    z-index: 2; }
  .hero-media h1, .hero-media p {
    color: white; }
  @media (min-width: 768px) {
    .hero-media {
      padding-top: 80px;
      padding-bottom: 80px; }
      .hero-media .btn__group {
        padding: 40px 0 35px; }
        .hero-media .btn__group p {
          white-space: nowrap;
          padding-left: 25px;
          padding-right: 25px; } }
  @media (min-width: 992px) {
    .hero-media {
      min-height: 465px;
      display: flex;
      align-items: center; } }
  @media (min-width: 1260px) {
    .hero-media {
      min-height: 510px; } }

.hero-media-large {
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: black; }
  .hero-media-large .wrapper {
    position: relative;
    z-index: 2; }
  .hero-media-large .hero__logo {
    max-width: 180px;
    margin: 0 0 30px; }
  .hero-media-large h1, .hero-media-large p {
    color: white; }
  .hero-media-large .inner {
    position: relative;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 50px 30px 50px 0; }
    .hero-media-large .inner .tag {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-50%); }
    .hero-media-large .inner h1, .hero-media-large .inner .h1 {
      margin-bottom: 0; }
    .hero-media-large .inner::before {
      content: "";
      position: absolute;
      right: 100%;
      top: 0;
      bottom: 0;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      width: 100vw; }
  @media (min-width: 768px) {
    .hero-media-large {
      padding-top: 100px;
      padding-bottom: 100px; }
      .hero-media-large .inner {
        padding: 80px 30px 80px 0; } }
  @media (min-width: 992px) {
    .hero-media-large {
      min-height: 700px;
      display: flex;
      align-items: center; } }
  @media (min-width: 1260px) {
    .hero-media-large .inner {
      padding: 100px 90px 100px 0; } }

.hero-slider {
  position: relative; }
  .hero-slider .wrapper {
    z-index: 2; }
  .hero-slider .hero__logo {
    max-width: 180px;
    margin: 0 0 30px; }
  .hero-slider .inner {
    padding-top: 50px;
    padding-bottom: 50px; }
  .hero-slider .slider-hero {
    width: 100%;
    height: 390px; }
  @media (min-width: 768px) {
    .hero-slider .slider-hero {
      height: 520px; }
    .hero-slider .inner {
      padding-top: 100px;
      padding-bottom: 100px; } }
  @media (min-width: 992px) {
    .hero-slider {
      min-height: 650px; }
      .hero-slider .slider-hero {
        position: absolute;
        top: -54px;
        right: 0;
        width: 45%;
        height: 550px; } }
  @media (min-width: 1260px) {
    .hero-slider {
      min-height: 675px; }
      .hero-slider .inner {
        padding-top: 125px;
        padding-bottom: 125px; }
      .hero-slider .slider-hero {
        height: 650px; } }

.hero-career {
  padding-top: 50px;
  padding-bottom: 50px; }
  .hero-career .hero__text {
    text-align: center;
    max-width: 600px;
    margin: 0 auto 30px; }
  .hero-career .wrapper {
    position: relative;
    z-index: 2; }
  .hero-career .inner {
    padding-top: 50px;
    padding-bottom: 50px; }
  .hero-career .hero__logo {
    max-width: 180px;
    margin: 0 0 30px; }
  @media (min-width: 768px) {
    .hero-career {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media (min-width: 992px) {
    .hero-career {
      min-height: 465px;
      display: flex;
      align-items: center; } }
  @media (min-width: 1260px) {
    .hero-career {
      min-height: 510px; } }

.hero-split .half {
  padding: 30px; }

.hero-split .inner {
  max-width: 450px;
  margin: 0 auto; }

.hero-split .location {
  position: relative; }
  .hero-split .location .icon {
    position: absolute;
    top: 10px;
    right: 10px; }

.hero-split input {
  background: transparent;
  border-color: #ffffff;
  color: #ffffff; }

.hero-split .half--form {
  background: #353535; }

@media (min-width: 768px) {
  .hero-split {
    display: flex; }
    .hero-split .half {
      padding: 100px 30px; }
    .hero-split > * {
      flex: 1; } }

.hero__selectors {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  padding-top: 20px;
  padding-bottom: 20px; }

.icon {
  display: inline-block;
  vertical-align: middle; }
  .icon > svg {
    display: block;
    transition: fill .3s; }
    .icon > svg path {
      transition: fill .3s; }

.icon--white > svg {
  fill: #ffffff !important; }

.icon--black > svg, .icon--group > svg {
  fill: #000000 !important; }

.icon--white > svg {
  fill: #ffffff !important; }

.icon--black > svg {
  fill: #000000 !important; }

.icon--black-slate > svg {
  fill: #2D2D2D !important; }

.icon--light > svg {
  fill: #eaeaea !important; }

.icon--grey > svg {
  fill: #7F7F7F !important; }

.icon--dark > svg {
  fill: #5f5f5f !important; }

.icon--green-lighter > svg {
  fill: #9BAE88 !important; }

.icon--green-light > svg {
  fill: #7B866E !important; }

.icon--green > svg {
  fill: #6B775D !important; }

.icon--blue-lighter > svg {
  fill: #3CB6C9 !important; }

.icon--blue-light > svg {
  fill: #38A4B5 !important; }

.icon--blue > svg {
  fill: #216B77 !important; }

.icon--red-error > svg {
  fill: #EE3D52 !important; }

.icon--red > svg {
  fill: #D94547 !important; }

.icon--brand-timber > svg {
  fill: #6B775E !important; }

.icon--timber > svg {
  fill: #9BAE88 !important; }

.icon--facades > svg {
  fill: #38A4B5 !important; }

.icon--h-blue:hover svg path {
  fill: #38A4B5 !important; }

.icon--12,
.icon--12 > svg {
  width: 12px;
  height: 12px; }

.icon--15,
.icon--15 > svg {
  width: 15px;
  height: 15px; }

.icon--20,
.icon--20 > svg {
  width: 20px;
  height: 20px; }

.icon--22,
.icon--22 > svg {
  width: 22px;
  height: 22px; }

.icon--25,
.icon--25 > svg {
  width: 25px;
  height: 25px; }

.icon--30,
.icon--30 > svg {
  width: 30px;
  height: 30px; }

.icon--32,
.icon--32 > svg {
  width: 32px;
  height: 32px; }

.icon--40,
.icon--40 > svg {
  width: 40px;
  height: 40px; }

.icon--60,
.icon--60 > svg {
  width: 60px;
  height: 60px; }

@media (max-width: 767px) {
  .icon--40-small-down,
  .icon--40-small-down > svg {
    width: 40px;
    height: 40px; } }

.icon--inline {
  margin: 0 4px; }

.icon--logo,
.icon--logo > svg {
  width: 240px;
  height: auto; }
  .icon--logo path,
  .icon--logo > svg path {
    fill: #1D1D1B; }

.icon--card {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  height: 0; }
  .icon--card img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: 100%;
    max-height: 80%; }
  @media (min-width: 992px) {
    .icon--card {
      padding-bottom: 66.6%; }
      .icon--card img {
        max-height: 75%; } }

.link, .link--fake {
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  font-size: 1.6rem;
  transition: color .3s; }
  .link:hover, .link--fake:hover {
    color: #216B77; }

.link--icon span {
  margin-left: 10px; }

.link-arrow {
  display: inline-flex;
  align-items: center;
  text-decoration: none !important;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.6; }

.link-arrow--right .text {
  margin-right: 10px; }

.link-arrow--right .icon {
  transition: transform .3s; }

.link-arrow--right:hover .icon {
  transform: translateX(8px); }

.link-arrow--left .icon {
  transition: transform .3s;
  margin-right: 10px; }

.link-arrow--left:hover .icon {
  transform: translateX(-8px); }

.link-arrow--category {
  width: 100%;
  justify-content: space-between;
  padding: 24px 15px 24px 0;
  line-height: 1.65; }
  .link-arrow--category__container {
    border-bottom: 2px solid #ffffff; }
    .link-arrow--category__container:nth-child(1) {
      border-top: 2px solid #ffffff; }
  .link-arrow--category__container-grid {
    border-bottom: 2px solid #ffffff; }
    .link-arrow--category__container-grid:nth-child(1) {
      border-top: 2px solid #ffffff; }
    @media (min-width: 768px) {
      .link-arrow--category__container-grid:nth-child(2) {
        border-top: 2px solid #ffffff; } }

.link-block {
  display: block;
  position: relative;
  width: calc(100% - 30px);
  padding: 30px 50px 30px 40px;
  margin-bottom: 55px;
  background: #ffffff;
  box-shadow: 0 0 22px 5px rgba(0, 0, 0, 0.1);
  border-bottom: 4px solid #000000;
  text-decoration: none; }
  .link-block:last-of-type {
    margin-bottom: 0; }
  .link-block .link-block__tag {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-50%); }
  .link-block .link-block__title {
    margin-bottom: 0;
    font-size: 2.2rem;
    font-weight: 800; }
  .link-block .link-block__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 0;
    width: 60px;
    height: 60px;
    background: #000000;
    transform: translateY(-50%) translateX(50%);
    transition: transform 0.3s; }
  .link-block--timber {
    border-bottom: 4px solid #6B775D; }
    .link-block--timber .link-block__tag {
      background: #6B775D; }
  .link-block--facades {
    border-bottom: 4px solid #216B77; }
    .link-block--facades .link-block__tag {
      background: #216B77; }
  @media (min-width: 992px) {
    .link-block:hover .link-block__arrow {
      transform: translateY(-50%) translateX(60%); } }

.location-filter {
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .location-filter {
      text-align: right; } }
  @media (min-width: 768px) {
    .location-filter .checkbox {
      display: inline-flex; } }
  .location-filter .checkbox:not(:last-child) {
    margin-right: 30px; }

.location-filter__item--hidden {
  display: none; }

/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 10;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */ }

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  width: 80%;
  max-width: 1000px;
  position: relative; }

/* The Close Button */
.modal-close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 15px;
  right: 20px; }
  .modal-close .icon {
    width: 18px;
    height: auto; }
  .modal-close:hover, .modal-close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer; }

.news-filter {
  padding: 40px 0; }
  @media (min-width: 992px) {
    .news-filter {
      display: flex;
      align-items: center; } }
  @media (min-width: 992px) {
    .news-filter p {
      margin-right: 40px;
      margin-bottom: 0;
      width: auto; } }
  @media (max-width: 991px) {
    .news-filter select {
      margin-bottom: 20px; } }
  @media (min-width: 992px) {
    .news-filter select {
      max-width: 320px;
      margin-right: 32px; } }

.news-info {
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: #000000;
  color: #ffffff;
  margin-bottom: 4px; }
  @media (min-width: 768px) {
    .news-info {
      flex-wrap: nowrap;
      justify-content: space-between; } }
  .news-info .news-info__date {
    margin-bottom: 20px; }
    @media (min-width: 768px) {
      .news-info .news-info__date {
        margin-bottom: 0; } }
  .news-info .news-info__share {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (min-width: 768px) {
      .news-info .news-info__share {
        width: auto;
        justify-content: flex-start; } }
    .news-info .news-info__share p {
      margin-right: 16px; }
    .news-info .news-info__share a:not(:last-child) {
      margin-right: 16px; }
  .news-info p {
    margin-bottom: 0;
    font-weight: 500; }

.newsletter-input {
  position: relative;
  margin-bottom: 20px; }
  .newsletter-input input {
    margin: 0;
    padding-right: 110px; }
    .newsletter-input input:focus {
      border: 2px solid #000000; }
  .newsletter-input .btn {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%; }

.newsletter-bottom {
  border-top: 4px solid #000000;
  padding-top: 40px;
  padding-bottom: 40px; }
  .newsletter-bottom .newsletter-bottom__form {
    flex: 0 0 auto;
    width: 100%; }
  @media (min-width: 768px) {
    .newsletter-bottom .newsletter-bottom__form {
      padding-left: 20px; } }
  @media (min-width: 992px) {
    .newsletter-bottom .newsletter-bottom__form {
      padding-left: 40px;
      padding-right: 40px; } }

.pagination {
  text-align: center;
  font-size: 1.6rem; }
  .pagination a, .pagination span {
    position: relative;
    padding: 6px 14px;
    margin: 0 8px;
    color: #000000;
    font-weight: 500;
    text-decoration: none; }
    @media (min-width: 992px) {
      .pagination a, .pagination span {
        margin: 0 4px; } }
  .pagination .icon {
    padding: 0;
    opacity: .5;
    transition: opacity .5s; }
    .pagination .icon:hover {
      opacity: 1; }
  .pagination .previous {
    margin-right: 4px; }
  .pagination .next {
    margin-left: 4px; }
  @media (min-width: 992px) {
    .pagination .previous {
      margin-right: 4px; }
    .pagination .next {
      margin-left: 4px; } }
  .pagination span.current {
    background: #38A4B5; }
    .pagination span.current:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      width: 34px;
      height: 34px; }
  .pagination.pagination--green span.current {
    background: #9BAE88; }

.project__image {
  position: relative; }
  @media (min-width: 768px) {
    .project__image .details-panel {
      position: absolute;
      top: 0;
      right: 0; } }

.project__share {
  display: flex;
  align-items: center;
  justify-content: center; }

.projects-filter {
  padding: 40px 0; }
  @media (min-width: 992px) {
    .projects-filter {
      display: flex;
      align-items: center;
      justify-content: space-between; } }
  @media (min-width: 992px) {
    .projects-filter p {
      margin-right: 32px;
      margin-bottom: 0;
      width: auto; }
      .projects-filter p:first-child {
        flex: 0 0 auto; } }
  @media (min-width: 1260px) {
    .projects-filter p {
      margin-right: 40px; } }
  @media (max-width: 991px) {
    .projects-filter select {
      margin-bottom: 20px; } }
  @media (min-width: 992px) {
    .projects-filter select {
      max-width: 320px; }
      .projects-filter select:not(:last-child) {
        margin-right: 32px; } }
  @media (min-width: 1260px) {
    .projects-filter select:not(:last-child) {
      margin-right: 40px; } }

.resources-menu {
  height: 100%;
  position: relative;
  padding-top: 80px;
  background-color: #2D2D2D;
  padding-right: 32px; }
  .resources-menu:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 100%;
    width: 100vw;
    background-color: #2D2D2D; }
  @media (max-width: 767px) {
    .resources-menu {
      padding-right: 6px; }
      .resources-menu:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        width: 100vw;
        background-color: #2D2D2D; } }
  .resources-menu ul {
    list-style: none;
    margin-left: 0; }

.resources-menu__group .resources-menu__children {
  display: none; }

.resources-menu__group.open .resources-menu__group__title .icon {
  transform: rotate(-180deg); }

.resources-menu__group.open .resources-menu__children {
  display: block; }

.resources-menu__group__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 12px;
  cursor: pointer; }
  .resources-menu__group__title .icon {
    margin-left: 12px;
    transform: rotate(0deg);
    transition: transform 0.2s; }

.resources-menu__children {
  list-style: none;
  margin-left: 0;
  padding-bottom: 12px; }

.resources-menu__option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 24px;
  cursor: pointer; }
  .resources-menu__option > label {
    margin-bottom: 0; }
  .resources-menu__option .checkbox {
    margin-left: 12px; }
    .resources-menu__option .checkbox label {
      cursor: pointer;
      padding-left: 22px; }

.resources-menu__option__title {
  flex: 1 1 auto; }

@media (max-width: 767px) {
  .resources-document {
    text-align: center; } }

.resources-document.hidden {
  display: none; }

.resources-all {
  position: relative;
  padding-bottom: 60px; }
  .resources-all.loading {
    opacity: 0; }

.resources-all__load-more {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 1.6rem;
  cursor: pointer; }
  .resources-all__load-more .text {
    pointer-events: none;
    margin-right: 10px; }
  .resources-all__load-more .icon {
    pointer-events: none;
    position: relative; }
    .resources-all__load-more .icon .plus {
      transition: opacity 0.1s;
      opacity: 1; }
    .resources-all__load-more .icon .resource-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: opacity 0.1s;
      opacity: 0; }
  .resources-all__load-more.is-loading {
    pointer-events: none; }
    .resources-all__load-more.is-loading .plus {
      opacity: 0; }
    .resources-all__load-more.is-loading .resource-loader {
      opacity: 1; }
  .resources-all__load-more.all-loaded {
    cursor: default; }

.resource-loader {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%; }
  .resource-loader div {
    position: absolute;
    top: 8px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #000000;
    animation-timing-function: cubic-bezier(0, 1, 1, 0); }
    .resource-loader div:nth-child(1) {
      left: 2px;
      animation: resource-loader1 0.6s infinite; }
    .resource-loader div:nth-child(2) {
      left: 2px;
      animation: resource-loader2 0.6s infinite; }
    .resource-loader div:nth-child(3) {
      left: 8px;
      animation: resource-loader2 0.6s infinite; }
    .resource-loader div:nth-child(4) {
      left: 16px;
      animation: resource-loader3 0.6s infinite; }

@keyframes resource-loader1 {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes resource-loader2 {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(6px, 0); } }

@keyframes resource-loader3 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0); } }

.resources-filtered.loading {
  opacity: 0; }

.resources-all__no-results, .resources-filtered__no-results {
  display: block;
  text-align: center; }

#seminar-request .seminars .hero,
#seminar-request .seminar-booking .hero,
#seminar-booking .seminars .hero,
#seminar-booking .seminar-booking .hero {
  text-align: center; }

#seminar-request .seminars .summary {
  align-items: center;
  margin: 10px 0;
  text-align: center; }
  #seminar-request .seminars .summary p {
    margin-bottom: 0; }
  #seminar-request .seminars .summary div:nth-child(2) .selected {
    display: inline-block;
    border: 2px solid #38A4B5;
    padding: 15px 30px;
    font-size: 1.6rem;
    margin: 20px 0 10px 0; }

#seminar-request .seminars .tile-wrapper .tile-seminar {
  position: relative;
  margin: 20px 0;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding-bottom: 20px; }
  #seminar-request .seminars .tile-wrapper .tile-seminar .image img {
    width: 100%;
    height: auto;
    margin: 0;
    border-bottom: 5px solid #38A4B5; }
  #seminar-request .seminars .tile-wrapper .tile-seminar .body,
  #seminar-request .seminars .tile-wrapper .tile-seminar .footer {
    padding: 30px; }
  #seminar-request .seminars .tile-wrapper .tile-seminar .body h2 {
    font-size: 1.6rem;
    margin-bottom: 10px; }
  #seminar-request .seminars .tile-wrapper .tile-seminar .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }
    #seminar-request .seminars .tile-wrapper .tile-seminar .footer .add-remove-buttons {
      text-align: right; }
  #seminar-request .seminars .tile-wrapper .tile-seminar.empty {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

#seminar-request .seminars .button {
  cursor: hand;
  cursor: pointer; }
  #seminar-request .seminars .button.learn-more, #seminar-request .seminars .button.add-seminar, #seminar-request .seminars .button.remove-seminar {
    font-size: 1.6rem;
    font-weight: 500; }
  #seminar-request .seminars .button.add-seminar, #seminar-request .seminars .button.remove-seminar {
    display: none;
    text-decoration: none; }
    #seminar-request .seminars .button.add-seminar.show, #seminar-request .seminars .button.remove-seminar.show {
      display: inline-block; }
  #seminar-request .seminars .button.learn-more {
    display: inline-block; }
  #seminar-request .seminars .button.add-seminar {
    color: #38A4B5; }
  #seminar-request .seminars .button.remove-seminar {
    color: #b53838; }

#seminar-request .seminar-booking-section {
  background-color: #216B77;
  padding: 20px; }
  #seminar-request .seminar-booking-section .g {
    align-items: center; }
    #seminar-request .seminar-booking-section .g p {
      margin-bottom: 0;
      color: #fff; }
  #seminar-request .seminar-booking-section #book-now {
    display: block;
    margin: 20px 0;
    font-size: 1.6rem;
    font-weight: 600;
    padding: 15px 40px;
    text-decoration: none;
    background-color: #b8e0e7;
    color: #1c5761;
    text-align: center; }
    #seminar-request .seminar-booking-section #book-now.disabled {
      cursor: not-allowed;
      background-color: #29808e; }

#seminar-request .seminar-modal {
  padding: 0;
  border-top: 5px solid #38A4B5; }
  #seminar-request .seminar-modal .seminar-modal-body .image {
    background-size: cover;
    background-position: center;
    height: 300px; }
  #seminar-request .seminar-modal .seminar-modal-body .content {
    padding: 40px; }
    #seminar-request .seminar-modal .seminar-modal-body .content h2 {
      font-size: 1.6rem; }
    #seminar-request .seminar-modal .seminar-modal-body .content .button {
      border: 2px solid #38A4B5;
      padding: 15px 30px;
      font-size: 1.6rem;
      font-weight: 600;
      color: #000; }
      #seminar-request .seminar-modal .seminar-modal-body .content .button.remove-seminar {
        border-color: #b53838; }

@media (min-width: 992px) {
  #seminar-request .seminars .summary {
    text-align: initial; }
    #seminar-request .seminars .summary div:nth-child(2) {
      text-align: right; }
      #seminar-request .seminars .summary div:nth-child(2) .selected {
        margin: 0; }
  #seminar-request .seminars .tile-wrapper {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 40px; }
    #seminar-request .seminars .tile-wrapper .tile-seminar {
      width: 33%; }
  #seminar-request .seminar-booking-section {
    padding: 80px 0; }
    #seminar-request .seminar-booking-section .g div:nth-child(2) {
      text-align: right; }
    #seminar-request .seminar-booking-section #book-now {
      margin: 0;
      display: inline-block; }
  #seminar-request .seminar-modal .seminar-modal-body .image {
    height: unset; } }

#seminar-booking .add-more {
  background-color: #38A4B5;
  padding: 15px 0; }
  #seminar-booking .add-more a {
    text-decoration: none; }
    #seminar-booking .add-more a p {
      margin: 0; }

#seminar-booking .seminars {
  margin-bottom: 80px; }
  #seminar-booking .seminars .selected-seminars {
    border-top: 2px solid #38A4B5; }
    #seminar-booking .seminars .selected-seminars .seminar {
      margin: 10px 0;
      align-items: center;
      padding: 20px 30px;
      border-bottom: 2px solid #38A4B5; }
      #seminar-booking .seminars .selected-seminars .seminar p {
        margin: 0;
        font-weight: 600; }
      #seminar-booking .seminars .selected-seminars .seminar .g__1-3 {
        text-align: right; }
      #seminar-booking .seminars .selected-seminars .seminar .remove-seminar {
        font-size: 1.6rem;
        text-decoration: none;
        color: black; }
        #seminar-booking .seminars .selected-seminars .seminar .remove-seminar .icon {
          width: 30px;
          height: auto;
          margin-left: 5px;
          pointer-events: none; }

#seminar-booking .request-form {
  background-color: #000;
  border-top: 4px solid #38A4B5; }
  #seminar-booking .request-form .heading {
    text-align: center;
    margin: 0 auto 40px auto; }
    #seminar-booking .request-form .heading p {
      margin: 0; }
  #seminar-booking .request-form .form-fields {
    margin: 0 auto; }
    #seminar-booking .request-form .form-fields .input--hold {
      margin-bottom: 20px; }
    #seminar-booking .request-form .form-fields .btn {
      background-color: #38A4B5;
      color: #000;
      padding: 16px 30px; }
    #seminar-booking .request-form .form-fields.hide {
      display: none; }
  #seminar-booking .request-form .success-message {
    display: none;
    text-align: center;
    padding: 30px; }
    #seminar-booking .request-form .success-message p {
      margin-bottom: 0; }
    #seminar-booking .request-form .success-message.show {
      display: block; }

.shortlist__menu {
  display: inline-block;
  position: relative; }
  .shortlist__menu.open .shortlist__list {
    opacity: 1;
    pointer-events: auto; }
  .shortlist__menu .shortlist__list {
    padding-top: 12px;
    position: absolute;
    top: 100%;
    right: 0;
    width: calc(100vw - 40px);
    max-width: 400px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s; }
    .shortlist__menu .shortlist__list .shortlist__list__items {
      max-height: 400px;
      overflow: auto;
      padding-right: 12px;
      margin-right: -12px; }
    @media (min-width: 768px) {
      .shortlist__menu .shortlist__list {
        width: 320px; } }
    .shortlist__menu .shortlist__list .btn {
      width: 100%; }
    .shortlist__menu .shortlist__list .inner {
      padding: 25px;
      background-color: white;
      box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.2); }
      .shortlist__menu .shortlist__list .inner::before {
        content: "";
        position: absolute;
        right: 10px;
        top: 4px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid white; }
  .shortlist__menu .shortlist__list__items {
    margin-bottom: 32px; }
    .shortlist__menu .shortlist__list__items::-webkit-scrollbar {
      width: 12px; }
    .shortlist__menu .shortlist__list__items::-webkit-scrollbar-track {
      background: #ffffff; }
    .shortlist__menu .shortlist__list__items::-webkit-scrollbar-thumb {
      background-color: #eaeaea;
      border-radius: 10px;
      border: 3px solid #ffffff; }
    .shortlist__menu .shortlist__list__items .shortlist__list__item {
      position: relative;
      display: flex;
      align-items: flex-start;
      padding-bottom: 15px;
      margin-bottom: 15px; }
      .shortlist__menu .shortlist__list__items .shortlist__list__item:not(:last-child) {
        border-bottom: 1px solid #eaeaea; }
      .shortlist__menu .shortlist__list__items .shortlist__list__item img {
        width: 40%; }
      .shortlist__menu .shortlist__list__items .shortlist__list__item .shortlist__list__item__content {
        width: 60%;
        padding-left: 15px;
        text-align: left !important; }
        .shortlist__menu .shortlist__list__items .shortlist__list__item .shortlist__list__item__content a {
          text-decoration: none;
          font-weight: normal; }
          .shortlist__menu .shortlist__list__items .shortlist__list__item .shortlist__list__item__content a:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block; }
        .shortlist__menu .shortlist__list__items .shortlist__list__item .shortlist__list__item__content .shortlist__remove {
          background: none;
          color: inherit;
          border: none;
          padding: 0;
          font: inherit;
          cursor: pointer;
          outline: none;
          position: relative;
          font-size: 14px;
          padding-left: 22px;
          z-index: 2;
          min-height: 30px;
          opacity: 1;
          transition: opacity 0.2s; }
          .shortlist__menu .shortlist__list__items .shortlist__list__item .shortlist__list__item__content .shortlist__remove:focus, .shortlist__menu .shortlist__list__items .shortlist__list__item .shortlist__list__item__content .shortlist__remove:active {
            outline: none; }
          .shortlist__menu .shortlist__list__items .shortlist__list__item .shortlist__list__item__content .shortlist__remove span {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 12px;
            height: 12px;
            background: #D94547;
            border-radius: 50%; }
            .shortlist__menu .shortlist__list__items .shortlist__list__item .shortlist__list__item__content .shortlist__remove span:after, .shortlist__menu .shortlist__list__items .shortlist__list__item .shortlist__list__item__content .shortlist__remove span:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              width: 50%;
              height: 2px;
              background: #ffffff; }
            .shortlist__menu .shortlist__list__items .shortlist__list__item .shortlist__list__item__content .shortlist__remove span:after {
              transform: translate(-50%, -50%) rotate(45deg); }
            .shortlist__menu .shortlist__list__items .shortlist__list__item .shortlist__list__item__content .shortlist__remove span:before {
              transform: translate(-50%, -50%) rotate(-45deg); }
          .shortlist__menu .shortlist__list__items .shortlist__list__item .shortlist__list__item__content .shortlist__remove:hover {
            opacity: 0.65; }

.shortlist__head {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center; }
  .shortlist__head:focus, .shortlist__head:active {
    outline: none; }
  .shortlist__head .shortlist__head__label {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0;
    margin-right: 20px; }
    @media (max-width: 564px) {
      .shortlist__head .shortlist__head__label {
        display: none; } }
  .shortlist__head .shortlist__head__counter {
    line-height: 1; }
  .shortlist__head .icon {
    position: relative;
    top: -1px; }

.shortlist__clear {
  background: transparent;
  border: none; }

.shortlist__add {
  background: transparent;
  border: none;
  padding: 0;
  position: absolute !important;
  top: 0;
  right: 0; }
  .shortlist__add .star {
    transition: fill 0.1s; }
  .shortlist__add:hover .star, .shortlist__add.is-active .star {
    fill: #38A4B5; }
  .shortlist__add.is-active:before {
    content: 'Remove from shortlist'; }
  .shortlist__add.is-active .icon {
    position: relative; }
    .shortlist__add.is-active .icon:after {
      content: '';
      width: 50%;
      height: 3px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #ffffff;
      opacity: 0;
      transition: opacity 0.1s; }
  .shortlist__add.is-active:hover .star {
    fill: transparent !important; }
  .shortlist__add.is-active:hover .icon:after {
    opacity: 1; }
  .shortlist__add.shortlist__add--green:hover .star, .shortlist__add.shortlist__add--green.is-active .star {
    fill: #9BAE88; }

.shortlist__remove-shortlist {
  background: transparent;
  border: none;
  padding: 0;
  position: absolute !important;
  top: 0;
  right: 0; }
  .shortlist__remove-shortlist .star {
    transition: fill 0.1s;
    fill: #38A4B5; }
  .shortlist__remove-shortlist.shortlist__remove-shortlist--fs .star {
    fill: #9BAE88; }
  .shortlist__remove-shortlist .icon {
    position: relative; }
    .shortlist__remove-shortlist .icon:after {
      content: '';
      width: 50%;
      height: 3px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #ffffff;
      opacity: 0;
      transition: opacity 0.1s; }
  .shortlist__remove-shortlist:hover .star {
    fill: transparent; }
  .shortlist__remove-shortlist:hover .icon:after {
    opacity: 1; }

.shortlist__full {
  position: relative;
  margin-bottom: 32px; }
  .shortlist__full .shortlist__add {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer; }
    .shortlist__full .shortlist__add:before {
      content: '';
      display: none; }
    .shortlist__full .shortlist__add .shortlist__add__label {
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1;
      color: #000000;
      background: #ffffff;
      padding: 9px 32px 10px 18px;
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
      position: relative;
      left: 20px;
      z-index: 1;
      transition: background-color 0.2s; }
    .shortlist__full .shortlist__add .icon {
      position: relative;
      z-index: 1; }
    .shortlist__full .shortlist__add.is-active .star {
      fill: #38A4B5; }
    .shortlist__full .shortlist__add.shortlist__add--green:hover .star, .shortlist__full .shortlist__add.shortlist__add--green.is-active .star {
      fill: #9BAE88; }
    @media (min-width: 768px) {
      .shortlist__full .shortlist__add {
        top: 20px;
        right: 20px; }
        .shortlist__full .shortlist__add .shortlist__add__label {
          top: 1px; } }
    @media (min-width: 1260px) {
      .shortlist__full .shortlist__add .shortlist__add__label {
        padding: 8px 32px 8px 18px; }
      .shortlist__full .shortlist__add:hover .shortlist__add__label {
        background: #eaeaea; } }
  .shortlist__full.shortlist__full--in-stock:before {
    content: 'Sample in stock';
    position: absolute;
    bottom: 8px;
    left: 8px;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.7);
    padding: 8px 14px;
    z-index: 1;
    font-size: 14px;
    font-weight: 500; }
    @media (min-width: 413px) {
      .shortlist__full.shortlist__full--in-stock:before {
        bottom: auto;
        top: 8px; } }
    @media (min-width: 992px) {
      .shortlist__full.shortlist__full--in-stock:before {
        font-size: 16px;
        top: 20px;
        left: 20px; } }

.slider-item {
  will-change: transform; }

.slider {
  position: relative;
  padding-bottom: 80px;
  opacity: 0;
  transition: opacity 0.2s; }
  .slider.loaded {
    opacity: 1; }
  @media (min-width: 768px) {
    .slider {
      padding-bottom: 110px; } }
  @media (min-width: 768px) {
    .slider .slider-item {
      width: 80%;
      margin-right: 30px; } }
  .slider .slider-item__media {
    position: relative; }
    .slider .slider-item__media .heading {
      position: absolute;
      background: rgba(0, 0, 0, 0.4);
      z-index: 2;
      bottom: 30px;
      padding: 15px 30px;
      color: white;
      font-size: 2rem;
      font-weight: bold; }
  .slider .slider__controls {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; }
    .slider .slider__controls .slider__buttons .slider__button--prev {
      margin-right: 4px; }
  .slider.slider--dark .slider__count {
    color: #ffffff; }
  .slider.slider--dark .slider__controls .slider__progress {
    background: #ffffff; }
    .slider.slider--dark .slider__controls .slider__progress .bar {
      background: #333333; }
  .slider.slider--light .slider__controls .slider__progress {
    background: #e6e6e6; }
    .slider.slider--light .slider__controls .slider__progress .bar {
      background: #000000; }
  .slider.slider--timber .slider__controls .slider__progress .bar {
    background: #7B866E; }
  .slider.slider--facades .slider__controls .slider__progress .bar {
    background: #38A4B5; }

.slider__progress {
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  margin-bottom: 26px; }
  .slider__progress .bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 0px;
    height: 4px;
    transition: width 0.5s;
    border-radius: 2px; }

.slider__count {
  font-size: 1.8rem;
  font-weight: bold;
  color: #000000; }
  .slider__count .count {
    font-size: 3.0rem; }
  .slider__count .amount {
    font-size: 1.8rem; }

.slider__button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none; }
  .slider__button:focus, .slider__button:active {
    outline: none; }

.slider-hero {
  position: relative;
  padding-bottom: 0 !important;
  opacity: 0;
  transition: opacity 0.2s; }
  .slider-hero.loaded {
    opacity: 1; }
  .slider-hero .slider-item {
    position: relative;
    width: 100%;
    height: 100%;
    pointer-events: none; }
    .slider-hero .slider-item.is-selected {
      pointer-events: auto; }
    .slider-hero .slider-item .slide__media {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%; }
      .slider-hero .slider-item .slide__media img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        width: 100%;
        height: 100%; }
    .slider-hero .slider-item .slide__content {
      position: absolute;
      bottom: 30px;
      left: 0;
      width: 100%;
      min-height: 120px;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.7);
      color: #ffffff;
      padding: 27px 30px 32px 80px; }
      @media (min-width: 992px) {
        .slider-hero .slider-item .slide__content {
          padding: 30px 30px; } }
      @media (min-width: 1260px) {
        .slider-hero .slider-item .slide__content h2 {
          padding-right: 85px; } }
      .slider-hero .slider-item .slide__content h2, .slider-hero .slider-item .slide__content p {
        margin-bottom: 0; }
      .slider-hero .slider-item .slide__content p {
        font-weight: 500; }
      .slider-hero .slider-item .slide__content .slider__count {
        position: absolute;
        bottom: 32px;
        right: 40px;
        color: #ffffff; }
        @media (max-width: 1259px) {
          .slider-hero .slider-item .slide__content .slider__count {
            display: none; } }
  .slider-hero .slider__buttons {
    position: absolute;
    bottom: 30px;
    left: 0;
    z-index: 1; }
    @media (min-width: 992px) {
      .slider-hero .slider__buttons {
        transform: translateX(-100%); } }
    .slider-hero .slider__buttons .slider__button {
      display: block; }
  .slider-hero .slider__controls {
    bottom: -38px; }

.slider-tiles {
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
  opacity: 0;
  transition: opacity 0.2s; }
  .slider-tiles.loaded {
    opacity: 1; }
  .slider-tiles .slider__button {
    position: absolute;
    top: 45%;
    transform: translateY(-50%); }
    .slider-tiles .slider__button--prev {
      left: 0; }
    .slider-tiles .slider__button--next {
      right: 0; }
  @media (min-width: 768px) {
    .slider-tiles {
      padding-left: 80px;
      padding-right: 80px; }
      .slider-tiles .slider-item {
        width: 50%; } }
  @media (min-width: 992px) {
    .slider-tiles .slider-item {
      width: 33.333%; } }
  @media (min-width: 1260px) {
    .slider-tiles .slider-item {
      width: 25%; } }
  @media (min-width: 1260px) {
    .slider-tiles.slider--disabled-large {
      padding-left: 0;
      padding-right: 0;
      margin-left: -16px; } }

.slider-partners-content {
  padding-bottom: 80px;
  opacity: 0;
  transition: opacity 0.2s; }
  .slider-partners-content.loaded {
    opacity: 1; }
  .slider-partners-content .slider-item {
    width: 100%;
    pointer-events: none; }
    .slider-partners-content .slider-item.is-selected {
      pointer-events: auto; }
    .slider-partners-content .slider-item .logo {
      padding: 10px;
      margin-bottom: 30px;
      background: #ffffff;
      max-width: 236px; }
    .slider-partners-content .slider-item ul li p:last-child {
      margin-bottom: 0; }

.slider-products {
  position: relative;
  opacity: 0;
  transition: opacity 0.2s; }
  .slider-products.loaded {
    opacity: 1; }
  .slider-products .flickity-viewport {
    overflow: visible; }
  .slider-products .slider-item {
    margin-right: 30px;
    width: 270px; }
    .slider-products .slider-item.is-selected .img::after {
      content: "";
      position: absolute;
      top: -2px;
      right: -2px;
      bottom: -2px;
      left: -2px;
      border: 5px solid #000000; }
  .slider-products .slider__buttons {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-108px); }
    @media (max-width: 767px) {
      .slider-products .slider__buttons {
        display: none; } }

@media (max-width: 767px) {
  .slider-products-heading {
    padding-right: 120px; } }

.slider-product-categories {
  position: relative;
  opacity: 0;
  transition: opacity 0.2s; }
  .slider-product-categories.loaded {
    opacity: 1; }
  .slider-product-categories .flickity-viewport {
    overflow: visible; }
  .slider-product-categories .slider-item {
    margin-right: 30px;
    width: 240px; }
    @media (min-width: 400px) {
      .slider-product-categories .slider-item {
        width: 270px; } }
    .slider-product-categories .slider-item.is-selected .img::after {
      content: "";
      position: absolute;
      top: -2px;
      right: -2px;
      bottom: -2px;
      left: -2px;
      border: 5px solid #000000; }
  .slider-product-categories .slider__buttons {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-108px); }

.slider-product-categories-content {
  opacity: 0;
  transition: opacity 0.2s; }
  .slider-product-categories-content.loaded {
    opacity: 1; }
  .slider-product-categories-content .flickity-viewport {
    transition: height 0.3s; }
  .slider-product-categories-content .slider-item {
    pointer-events: none; }
    .slider-product-categories-content .slider-item.is-selected {
      pointer-events: auto; }

.slider-projects {
  position: relative;
  padding-bottom: 40px;
  opacity: 0;
  transition: opacity 0.2s; }
  .slider-projects.loaded {
    opacity: 1; }
  .slider-projects .flickity-viewport {
    overflow: visible; }
  .slider-projects .slider-item {
    width: 240px;
    margin-right: 30px; }
    @media (min-width: 385px) {
      .slider-projects .slider-item {
        width: 270px; } }
    @media (min-width: 768px) {
      .slider-projects .slider-item {
        width: 470px; } }
  .slider-projects .slider__buttons {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-88px); }
    .slider-projects .slider__buttons .slider__button--prev {
      margin-right: 4px; }
    @media (min-width: 768px) {
      .slider-projects .slider__buttons {
        transform: translateY(-95px); } }
    @media (max-width: 384px) {
      .slider-projects .slider__buttons {
        display: none; } }
  .slider-projects--riba .slider-item {
    width: 100%; }
    @media (min-width: 768px) {
      .slider-projects--riba .slider-item {
        width: 33.3%; } }

@media (min-width: 385px) and (max-width: 767px) {
  .slider-projects-heading {
    padding-right: 120px; } }

@media (min-width: 385px) {
  .slider-projects-riba-heading {
    padding-right: 120px; } }

@media (min-width: 768px) {
  .slider-projects-riba-heading {
    padding-right: 140px; } }

.slider-approach {
  margin-bottom: 80px;
  opacity: 0;
  transition: opacity 0.2s; }
  .slider-approach.loaded {
    opacity: 1; }
  @media (max-width: 991px) {
    .slider-approach {
      margin-top: 200px; } }
  @media (max-width: 991px) {
    .slider-approach .flickity-viewport {
      overflow: visible; } }
  @media (min-width: 992px) {
    @supports (clip-path: inset(-100vw -100vw -100vw 0)) {
      .slider-approach .flickity-viewport {
        overflow: visible;
        clip-path: inset(-100vw -100vw -100vw 0); } } }
  .slider-approach .slider-item {
    width: 80%;
    margin-right: 42px; }
    @media (min-width: 768px) {
      .slider-approach .slider-item {
        width: 100%; } }
  .slider-approach .slider__progress {
    position: absolute;
    bottom: -55px;
    left: 0;
    background: #16474f; }
    .slider-approach .slider__progress .bar {
      background: #38A4B5; }
  .slider-approach .slider__count {
    position: absolute;
    bottom: -80px;
    left: 0;
    color: #ffffff; }
  .slider-approach .slider__button {
    position: absolute;
    top: 50%;
    z-index: 1;
    filter: contrast(0.8); }
    @media (max-width: 767px) {
      .slider-approach .slider__button {
        display: none; } }
    .slider-approach .slider__button--prev {
      left: 0;
      transform: translateY(-50%) translateX(-50%); }
    .slider-approach .slider__button--next {
      right: 0;
      transform: translateY(-50%) translateX(50%); }

.slider-mobile-only {
  opacity: 1; }
  .slider-mobile-only .slider__buttons {
    display: none; }
  @media (max-width: 767px) {
    .slider-mobile-only {
      position: relative;
      opacity: 0;
      transition: opacity 0.2s; }
      .slider-mobile-only.loaded {
        opacity: 1; }
      .slider-mobile-only .flickity-viewport {
        overflow: visible; }
      .slider-mobile-only .slider-item {
        margin-right: 30px;
        width: 100%; }
      .slider-mobile-only .slider__buttons {
        display: inline-flex;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(-50%);
        z-index: 1; }
        .slider-mobile-only .slider__buttons .slider__button--prev {
          margin-right: 4px; }
      .slider-mobile-only--showrooms {
        display: block;
        margin-right: 0;
        margin-left: 0; }
        .slider-mobile-only--showrooms .flickity-viewport {
          margin-bottom: 40px; }
        .slider-mobile-only--showrooms .flex-col {
          padding: 0; }
        .slider-mobile-only--showrooms .slider-item {
          width: 240px; } }
      @media (max-width: 767px) and (min-width: 414px) {
        .slider-mobile-only--showrooms .slider-item {
          width: 270px; } }

.slider-fs {
  position: relative;
  opacity: 0;
  transition: opacity 0.2s; }
  .slider-fs.loaded {
    opacity: 1; }
  .slider-fs .slider-item {
    margin-right: 30px;
    width: 100%; }
  .slider-fs .shortlist__add {
    position: absolute;
    top: 12px;
    right: 10px;
    z-index: 1;
    cursor: pointer; }
    .slider-fs .shortlist__add:before {
      content: '';
      display: none; }
    .slider-fs .shortlist__add .shortlist__add__label {
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1;
      color: #000000;
      background: #ffffff;
      padding: 8px 32px 8px 18px;
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
      position: relative;
      top: 2px;
      left: 20px;
      z-index: 1;
      transition: background-color 0.2s;
      pointer-events: none; }
    .slider-fs .shortlist__add .icon {
      position: relative;
      z-index: 3; }

.slider-fs-nav__wrapper {
  display: flex;
  margin-top: 16px; }

.slider-fs-nav {
  opacity: 0;
  transition: opacity 0.2s;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px; }
  .slider-fs-nav.loaded {
    opacity: 1; }
  .slider-fs-nav .slider-item {
    position: relative;
    width: 33.3%;
    margin-right: 10px; }
    @media (min-width: 768px) {
      .slider-fs-nav .slider-item {
        width: 25%; } }
    @media (min-width: 992px) {
      .slider-fs-nav .slider-item {
        width: 16.66%; } }
    .slider-fs-nav .slider-item:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      border: 0px solid #9BAE88;
      opacity: 0;
      transition: all 0.2s; }
      @media (min-width: 768px) {
        .slider-fs-nav .slider-item:after {
          width: calc(100% - 10px);
          height: calc(100% - 10px); } }
    .slider-fs-nav .slider-item.is-nav-selected:after {
      opacity: 1;
      border: 2px solid #9BAE88; }
      @media (min-width: 768px) {
        .slider-fs-nav .slider-item.is-nav-selected:after {
          border: 5px solid #9BAE88; } }

.stockist-table {
  border-top: 5px solid #7B866E;
  padding: 20px;
  background-color: #EAEAEA; }
  .stockist-table .head {
    padding-bottom: 20px;
    border-bottom: 1px solid #979797; }
  .stockist-table .list {
    height: 370px;
    overflow: scroll;
    scroll-behavior: smooth; }
  .stockist-table .item {
    padding: 26px;
    background: #FFFFFF;
    box-shadow: 0 10px 20px -7px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px; }
    .stockist-table .item:last-child {
      border: none;
      margin: 0; }

.stockist-map #map {
  min-height: 450px;
  height: 100%; }
  .stockist-map #map .gm-style .gm-style-iw-c {
    padding: 34px 26px 26px 26px;
    border-radius: 0;
    border-top: 4px solid #7B866E; }
    .stockist-map #map .gm-style .gm-style-iw-c .gm-style-iw-d {
      padding-bottom: 14px;
      padding-right: 14px; }
    .stockist-map #map .gm-style .gm-style-iw-c button {
      top: 5px !important;
      right: 5px !important; }

.tag {
  min-width: 90px;
  padding: 10px 20px;
  text-align: center;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #000000;
  text-transform: capitalize; }
  .tag--timber {
    background-color: #6B775D; }
  .tag--facades {
    background-color: #216B77; }

.tag__container {
  position: relative; }
  .tag__container .tag__hold {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateY(-50%) translateX(-50%); }

.tile-common {
  display: block;
  position: relative;
  background-color: black;
  min-height: 300px;
  transition: box-shadow .3s; }
  .tile-common .logo, .tile-common .title {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px; }
  .tile-common .tag {
    z-index: 3;
    position: absolute;
    top: 10px;
    right: 10px; }
  .tile-common .title {
    line-height: 1.4;
    font-size: 2.4rem; }
  .tile-common .img::before {
    content: "";
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 17%, rgba(0, 0, 0, 0.6) 99%);
    position: absolute;
    z-index: 2;
    height: 50%;
    right: 0;
    bottom: 0;
    left: 0; }
  .tile-common .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translateX(-90%) translateY(-50%);
    transition: opacity .3s, transform .3s;
    z-index: 4; }
  .tile-common--timber .arrow {
    fill: #9BAE88; }
  .tile-common--facades .arrow {
    fill: #38A4B5; }
  @media (min-width: 992px) {
    .tile-common:hover {
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.71); }
      .tile-common:hover .icon {
        opacity: 1;
        transform: translateX(-50%) translateY(-50%); } }
  @media (min-width: 992px) {
    .tile-common {
      min-height: 353px; } }

.tile-common-extender {
  background: #eaeaea;
  padding: 15px;
  margin-bottom: 0; }

.tile-service {
  position: relative;
  top: 0;
  transition: all .3s;
  padding: 22px 22px 30px;
  height: 100%; }
  .tile-service .img img {
    height: 100px; }
  .tile-service a {
    text-decoration: none; }
  @media (min-width: 992px) {
    .tile-service {
      padding: 30px; }
      .tile-service:hover {
        top: -4px;
        box-shadow: 0 10px 20px 2px rgba(0, 0, 0, 0.2); } }
  .tile-service--outline {
    height: 100%;
    padding: 22px 22px 70px;
    border-top: 4px solid #38A4B5;
    background: #FFFFFF;
    box-shadow: 0 10px 20px 2px rgba(0, 0, 0, 0.03);
    position: relative; }
    @media (min-width: 992px) {
      .tile-service--outline {
        padding: 30px 30px 70px; } }
    .tile-service--outline p a {
      position: absolute;
      left: 0;
      bottom: 30px;
      width: 100%; }

.tile-partner {
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 30px; }
  .tile-partner .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 145px;
    padding: 15px 30px;
    background: #FFFFFF;
    box-shadow: 0 10px 20px 2px rgba(0, 0, 0, 0.1);
    border-top: 4px solid #6B775D;
    transition: all 0.3s; }
  .tile-partner img {
    max-height: 115px; }
    @media (max-width: 767px) {
      .tile-partner img {
        max-width: 300px;
        width: 100%; } }

.tile-project {
  display: block;
  position: relative; }
  .tile-project .tag {
    position: absolute;
    top: 30px;
    left: 30px; }
  .tile-project .img {
    position: relative;
    overflow: hidden; }
    .tile-project .img img {
      transform: scale(1);
      transform-origin: center;
      transition: 0.3s; }
  .tile-project .overlay {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;
    padding: 40px 30px 40px 20px;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .tile-project .overlay p {
      margin-bottom: 0;
      margin-right: 22px; }
    .tile-project .overlay .icon {
      transition: all 0.3s; }
  .tile-project.tile-project--riba .overlay {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 20px; }
    .tile-project.tile-project--riba .overlay p {
      margin-right: 22px; }
  @media (min-width: 992px) {
    .tile-project:hover .img img {
      transform: scale(1.05); }
    .tile-project:hover .overlay .icon {
      transform: translateX(6px); } }

.tile-project--slider {
  display: block;
  max-width: 470px;
  position: relative; }
  .tile-project--slider .tag {
    position: absolute;
    top: 30px;
    left: 30px; }
  .tile-project--slider .overlay {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;
    padding: 24px 32px 24px 20px;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1; }
    .tile-project--slider .overlay p {
      margin-bottom: 0; }
    @media (min-width: 768px) {
      .tile-project--slider .overlay {
        padding: 40px 50px 40px 20px; } }
    @media (min-width: 992px) {
      .tile-project--slider .overlay {
        padding: 40px 100px 40px 20px; } }

.tile-news {
  position: relative;
  height: 100%; }
  .tile-news .img__overlay {
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
    border-bottom: 4px solid #000000; }
    .tile-news .img__overlay img {
      display: block;
      transform: scale(1);
      transform-origin: center;
      transition: transform 0.3s; }
  .tile-news .preview__text {
    padding-bottom: 50px; }
    .tile-news .preview__text .read-more {
      position: absolute;
      bottom: 0;
      left: 0;
      transition: opacity 0.3s; }
      .tile-news .preview__text .read-more a {
        text-decoration: none; }
        .tile-news .preview__text .read-more a:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%; }
  .tile-news .overlay {
    background-color: rgba(255, 255, 255, 0.95);
    padding: 30px;
    position: absolute;
    top: 15%;
    left: 0;
    width: 90%; }
    @media (min-width: 768px) {
      .tile-news .overlay {
        padding: 34px 30px; } }
    @media (min-width: 1260px) {
      .tile-news .overlay {
        top: 20%;
        padding: 50px 30px; } }
    .tile-news .overlay .date {
      margin-bottom: 4px; }
    .tile-news .overlay .title a {
      text-decoration: none; }
      .tile-news .overlay .title a:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
    .tile-news .overlay p {
      margin-bottom: 0; }
    .tile-news .overlay .tag {
      position: absolute;
      top: 0;
      transform: translateY(-50%);
      left: 30px; }
    .tile-news .overlay .read-more {
      transition: opacity 0.3s; }
  .tile-news--timber .img__overlay {
    border-bottom: 4px solid #9BAE88; }
  .tile-news--timber .overlay {
    background-color: rgba(155, 174, 136, 0.95); }
  .tile-news--facades .img__overlay {
    border-bottom: 4px solid #38A4B5; }
  .tile-news--facades .overlay {
    background-color: rgba(56, 164, 181, 0.95); }
  .tile-news--featured .overlay .read-more {
    display: none; }
    .tile-news--featured .overlay .read-more a {
      text-decoration: none; }
  @media (min-width: 992px) {
    .tile-news--featured .img__overlay {
      overflow: visible; } }
  @media (min-width: 992px) and (min-width: 768px) {
    .tile-news--featured .img__overlay {
      max-width: calc(99.9% * 1 / 3 - (30px - 30px * 1 / 3)); }
      .tile-news--featured .img__overlay .overlay {
        left: auto;
        right: 0;
        transform: translateX(90%);
        width: 200%; }
        .tile-news--featured .img__overlay .overlay .title {
          font-size: 3.6rem;
          line-height: 1.3; } }
  @media (min-width: 992px) and (min-width: 1260px) {
    .tile-news--featured .img__overlay .overlay .title {
      font-size: 4.8rem; } }
  @media (min-width: 992px) {
    .tile-news--featured .overlay .read-more {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateY(150%); }
    .tile-news--featured .preview__text {
      display: none; }
    .tile-news--featured:hover .img__overlay img {
      transform: scale(1.025) !important; } }
  @media (min-width: 992px) {
    .tile-news:hover .img__overlay img {
      transform: scale(1.1); }
    .tile-news:hover .preview__text .read-more, .tile-news:hover .overlay .read-more {
      opacity: 0.75; } }

.tile-swatch .img__hold {
  position: relative;
  height: 220px;
  margin-bottom: 5px;
  transition: filter 0.2s;
  overflow: hidden; }
  .tile-swatch .img__hold .icon {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.2s;
    pointer-events: none; }

.tile-swatch .heading {
  position: relative;
  padding-right: 50px; }

.tile-swatch.tile-swatch--fs .img__hold .icon .circle {
  fill: #9BAE88; }

.tile-swatch.tile-swatch--in-stock .img__hold:before {
  content: 'Sample in stock';
  position: absolute;
  top: 8px;
  left: 8px;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.7);
  padding: 8px 14px;
  z-index: 1;
  font-size: 16px;
  font-weight: 500; }

@media (min-width: 992px) {
  .tile-swatch .img__hold:hover {
    filter: brightness(0.9); }
    .tile-swatch .img__hold:hover .icon {
      top: 50%;
      opacity: 1; } }

.tile-approach {
  position: relative;
  background: #ffffff;
  border-top: 4px solid #216B77;
  padding: 32px 24px; }
  @media (min-width: 768px) {
    .tile-approach {
      padding: 32px 50px; } }
  .tile-approach .tile-approach__index {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 120px;
    font-weight: 800;
    color: #ffffff;
    transform: translateY(-100%);
    line-height: 0.73; }
    @media (min-width: 768px) {
      .tile-approach .tile-approach__index {
        font-size: 200px; } }
  @media (min-width: 768px) {
    .tile-approach {
      padding: 42px 70px; } }

.tile-resource {
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 30px; }
  .tile-resource.tile-resource--no-spacing {
    margin-right: 0;
    margin-left: 0; }
  .tile-resource .inner {
    position: relative;
    background: #FFFFFF;
    box-shadow: 0 10px 20px 2px rgba(0, 0, 0, 0.1);
    border-top: 4px solid #000000;
    margin-bottom: 16px; }
    .tile-resource .inner .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
      transform-origin: center;
      transition: transform 0.3s;
      width: 44px;
      height: 44px;
      background: #000000;
      display: flex;
      align-items: center;
      justify-content: center; }
  .tile-resource img {
    display: block;
    width: 100%; }
  .tile-resource--timber .inner {
    border-top: 4px solid #6B775D; }
  .tile-resource--facades .inner {
    border-top: 4px solid #216B77; }
  @media (min-width: 992px) {
    .tile-resource:hover .icon {
      transform: translate(-50%, -55%) scale(1.05); } }

.tile-resource-block {
  margin-bottom: 40px; }
  .tile-resource-block ul {
    border-top: solid 1px #A1A1A1;
    margin: 0;
    padding: 0;
    list-style: none; }
    .tile-resource-block ul li {
      border-bottom: solid 1px #A1A1A1;
      margin: 0;
      padding: 0; }
    .tile-resource-block ul a {
      position: relative;
      text-decoration: none;
      padding: 15px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .tile-resource-block ul a .download, .tile-resource-block ul a .watch {
        font-weight: normal;
        text-decoration: underline; }
      @media (max-width: 767px) {
        .tile-resource-block ul a {
          flex-direction: column; }
          .tile-resource-block ul a span:not(:last-child) {
            margin-bottom: 20px; }
          .tile-resource-block ul a span:last-child {
            margin-bottom: 0 !important; } }
      @media (min-width: 768px) {
        .tile-resource-block ul a .text {
          flex: 0 0 auto;
          width: 60%; }
        .tile-resource-block ul a .type {
          flex: 0 0 auto;
          width: 20%;
          margin-right: 12px; }
        .tile-resource-block ul a .download, .tile-resource-block ul a .watch {
          margin-bottom: 0; } }
  .tile-resource-block.hidden {
    display: none; }
  @media (min-width: 768px) {
    .tile-resource-block--facades a:hover {
      background: #38A4B5; } }
  @media (min-width: 768px) {
    .tile-resource-block--timber a:hover {
      background: #7B866E; } }

.tile-brochure {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center center;
  margin-top: 20px;
  margin-bottom: 100px;
  border-bottom: 4px solid #000000; }
  .tile-brochure .tag {
    position: relative;
    left: 20px;
    top: 0;
    transform: translateY(-50%); }
    @media (min-width: 768px) {
      .tile-brochure .tag {
        left: 32px; } }
  .tile-brochure .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    padding: 20px; }
  .tile-brochure .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(150%);
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .tile-brochure .bottom a {
      text-decoration: none; }
    .tile-brochure .bottom .block-social {
      display: flex; }
      .tile-brochure .bottom .block-social a:not(:last-child) {
        margin-right: 12px; }
  .tile-brochure--facades {
    border-bottom: 4px solid #216B77; }
    .tile-brochure--facades .tag {
      background: #216B77; }
    .tile-brochure--facades .link {
      color: #216B77; }
  .tile-brochure--timber {
    border-bottom: 4px solid #7B866E; }
    .tile-brochure--timber .tag {
      background: #7B866E; }
    .tile-brochure--timber .link {
      color: #7B866E; }

[data-tooltip] {
  position: relative;
  cursor: pointer; }

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
  z-index: 1; }

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 8px;
  padding: 9px 9px;
  min-width: 120px;
  background-color: black;
  color: #ffffff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif; }

.tooltip--big {
  display: block; }

.tooltip--big[data-tooltip]:before {
  min-width: 140px; }

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-top: 3px;
  margin-left: -5px;
  width: 0;
  border-bottom: 5px solid black;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: ' ';
  font-size: 0;
  line-height: 0; }

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.copy-clipboard {
  background-color: transparent;
  border: none; }

/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: white;
  cursor: pointer; }

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

.flickity-button:active {
  opacity: 0.6; }

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

.flickity-button-icon {
  fill: currentColor; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }
