//###########################################################################
// Footer
//###########################################################################

#footer {
  padding-top: 45px;
  background-color: black;

  a {
    text-decoration: none;
  }

  .social {
    margin-bottom: 30px;

    li {
      margin-bottom: 15px;
    }
  }

  a:hover {
    color: #38A4B5;
    transition: color .3s;


    .icon svg path {
      fill: #38A4B5;
    }
  }

  .address p {
    line-height: 2;
  }

  .footer-logos {

    img {
      max-height: 190px;
    }
  }

  .legals {
    margin-bottom: 20px;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-right: 20px;

      &::before {
        content: "|";
        position: absolute;
        right: 0;
        color: white;
      }

      &:last-of-type {
        padding-right: 0;

        &::before {
          content: none;
        }
      }
    }

    a {
      text-decoration: none;
      font-size: 1.3rem;
    }
  }

  .copy {
    background-color: #353535;
    border-top: 4px solid white;

    p {
      color: white;
      padding-top: 20px;
    }
  }
}

@include breakpoint(medium) {

}

@include breakpoint(large) {

}