//###########################################################################
// Stockists
//###########################################################################

// Stockist table
//------------------

.stockist-table {
  border-top: 5px solid palette(green, light);
  padding: 20px;
  background-color: #EAEAEA;

  .head {
    padding-bottom: 20px;
    border-bottom: 1px solid #979797;
  }

  .list {
    height: 370px;
    overflow: scroll;
    scroll-behavior: smooth;
  }

  .item {
    padding: 26px;
    background: #FFFFFF;
    box-shadow: 0 10px 20px -7px rgba(0,0,0,0.30);
    margin-bottom: 20px;

    &:last-child {
      border: none;
      margin: 0;
    }
  }
}

// Stockist map
//------------------

.stockist-map {
  #map {
    min-height: 450px;
    height: 100%;

    .gm-style .gm-style-iw-c {
      padding: 34px 26px 26px 26px;
      border-radius: 0;
      border-top: 4px solid palette(green, light);

      .gm-style-iw-d {
        padding-bottom: 14px;
        padding-right: 14px;
      }

      button {
        top: 5px !important;
        right: 5px !important;
      }
    }
  }
}