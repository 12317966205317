//###########################################################################
// Arrow block
//###########################################################################

.arrow-block {
  position: relative;
}

.arrow-block__inner {
  position: relative;
  max-width: 785px;
  margin: 0 auto;
  text-align: center;
  z-index: 1;
  padding: 0 1.33334rem;
}

.arrow-block__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &--tinted {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      z-index: 1;
    }
  }
}