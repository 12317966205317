//###########################################################################
// Cookies
//###########################################################################

#cookie-message {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  background-color: black;
  max-width: 600px;
  border: 2px solid white;
  padding:30px 30px 10px;

  p {
    color: white;
    margin-bottom: 20px;
  }

  .btn {
    margin-right: 15px;
    margin-bottom: 20px;
  }

  .wrapper {
    padding: gutter(2) gutter(1);
  }
}

@include breakpoint(medium) {
  #cookie-message {

    .wrapper {
      padding: 40px;
    }
  }
}