//###########################################################################
// Shortlist
//###########################################################################

.shortlist__menu {
  display: inline-block;
  position: relative;

  &.open {
    .shortlist__list {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .shortlist__list {
    padding-top: 12px;
    position: absolute;
    top: 100%;
    right: 0;
    width: calc(100vw - 40px);
    max-width: 400px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s;

    .shortlist__list__items {
      max-height: 400px;
      overflow: auto;
      padding-right: 12px;
      margin-right: -12px;
    }

    @include breakpoint(small) {
      width: 320px;
    }

    .btn {
      width: 100%;
    }

    .inner {
      padding: 25px;
      background-color: white;
      box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.20);

      &::before {
        content: "";
        position: absolute;
        right: 10px;
        top: 4px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid white;
      }
    }
  }

  .shortlist__list__items {
    margin-bottom: 32px;

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: palette(white);
    }

    &::-webkit-scrollbar-thumb {
      background-color: palette(grey, light);
      border-radius: 10px;
      border: 3px solid palette(white);
    }

    .shortlist__list__item {
      position: relative;
      display: flex;
      align-items: flex-start;
      padding-bottom: 15px;
      margin-bottom: 15px;

      &:not(:last-child) {
        border-bottom: 1px solid palette(grey, light);
      }

      img {
        width: 40%;
      }

      .shortlist__list__item__content {
        width: 60%;
        padding-left: 15px;
        text-align: left !important;

        a {
          text-decoration: none;
          font-weight: normal;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
          }
        }

        .shortlist__remove {
          @include button-unstyled;
          position: relative;
          font-size: 14px;
          padding-left: 22px;
          z-index: 2;
          min-height: 30px;
          opacity: 1;
          transition: opacity 0.2s;

          span {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 12px;
            height: 12px;
            background: palette(red);
            border-radius: 50%;

            &:after, &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              width: 50%;
              height: 2px;
              background: palette(white);
            }

            &:after {
              transform: translate(-50%, -50%) rotate(45deg);
            }

            &:before {
              transform: translate(-50%, -50%) rotate(-45deg);
            }
          }

          &:hover {
            opacity: 0.65;
          }
        }
      }
    }
  }

  &:hover {

  }
}

// Shortlist head
//------------------

.shortlist__head {
  @include button-unstyled;

  display: flex;
  align-items: center;

  .shortlist__head__label {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0;
    margin-right: 20px;

    @include breakpoint-max(564px) {
      display: none;
    }
  }

  .shortlist__head__counter {
    line-height: 1;
  }

  .icon {
    position: relative;
    top: -1px;
  }
}

// Shortlist clear
//------------------

.shortlist__clear {
  background: transparent;
  border: none;
}

// Shortlist add
//------------------

.shortlist__add {
  background: transparent;
  border: none;
  padding:0;
  position: absolute !important;
  top: 0;
  right: 0;

  .star {
    transition: fill 0.1s;
  }


  &:hover, &.is-active {
    .star {
      fill: palette(blue, light);
    }
  }

  &.is-active {
    &:before {
      content: 'Remove from shortlist';
    }

    .icon {
      position: relative;

      &:after {
        content: '';
        width: 50%;
        height: 3px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: palette(white);
        opacity: 0;
        transition: opacity 0.1s;
      }
    }

    &:hover {
      .star {
        fill: transparent !important;
      }
      .icon {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  &.shortlist__add--green {
    &:hover, &.is-active {
      .star {
        fill: palette(green, lighter);
      }
    }
  }
}

// Shortlist remove shortlist (tile swatch)
//------------------

.shortlist__remove-shortlist {
  background: transparent;
  border: none;
  padding:0;
  position: absolute !important;
  top: 0;
  right: 0;

  .star {
    transition: fill 0.1s;
    fill: palette(blue, light);
  }

  &.shortlist__remove-shortlist--fs {
    .star {
      fill: palette(green, lighter);
    }
  }

  .icon {
    position: relative;

    &:after {
      content: '';
      width: 50%;
      height: 3px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: palette(white);
      opacity: 0;
      transition: opacity 0.1s;
    }
  }

  &:hover {
    .star {
      fill: transparent;
    }
    .icon {
      &:after {
        opacity: 1;
      }
    }
  }
}

// Shortlist full
//------------------

.shortlist__full {
  position: relative;
  margin-bottom: 32px;

  .shortlist__add {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    &:before {
      content: '';
      display: none;
    }

    .shortlist__add__label {
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1;
      color: palette(black);
      background: palette(white);
      padding: 9px 32px 10px 18px;
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
      position: relative;
      left: 20px;
      z-index: 1;
      transition: background-color 0.2s;
    }

    .icon {
      position: relative;
      z-index: 1;
    }

    &.is-active {
      .star {
        fill: palette(blue, light);
      }
    }

    &.shortlist__add--green {
      &:hover, &.is-active {
        .star {
          fill: palette(green, lighter);
        }
      }
    }

    @include breakpoint(small) {
      top: 20px;
      right: 20px;

      .shortlist__add__label {
        top: 1px;
      }
    }

    @include breakpoint(large) {
      .shortlist__add__label {
        padding: 8px 32px 8px 18px;
      }

      &:hover {
        .shortlist__add__label {
          background: palette(grey, light);
        }
      }
    }
  }

  &.shortlist__full--in-stock {
    &:before {
      content: 'Sample in stock';
      position: absolute;
      bottom: 8px;
      left: 8px;
      color: palette(white);
      background: rgba(0,0,0,0.7);
      padding: 8px 14px;
      z-index: 1;
      font-size: 14px;
      font-weight: 500;

      @include breakpoint(413px) {
        bottom: auto;
        top: 8px;
      }

      @include breakpoint(medium) {
        font-size: 16px;
        top: 20px;
        left: 20px;
      }
    }
  }
}