//###########################################################################
// Links
//###########################################################################

.link, .link--fake {
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  font-size: $p-size;
  transition: color .3s;

  &:hover {
    color: palette(blue);
  }
}

.link--icon {
  span {
    margin-left: 10px;
  }
}

// Link arrow
//------------------

.link-arrow {
  display: inline-flex;
  align-items: center;
  text-decoration: none !important;
  font-weight: bold;
  font-size: $p-size;
  line-height: 1.6;
}

.link-arrow--right {
  .text {
    margin-right: 10px;
  }

  .icon {
    transition: transform .3s;
  }

  &:hover {
    .icon {
      transform: translateX(8px);
    }
  }
}

.link-arrow--left {
  .icon {
    transition: transform .3s;
    margin-right: 10px;
  }

  &:hover {
    .icon {
      transform: translateX(-8px);
    }
  }
}

.link-arrow--category {
  width: 100%;
  justify-content: space-between;
  padding: 24px 15px 24px 0;
  line-height: 1.65;

  &__container {
    border-bottom: 2px solid palette(white);

    &:nth-child(1) {
      border-top: 2px solid palette(white);
    }
  }

  &__container-grid {
    border-bottom: 2px solid palette(white);

    &:nth-child(1) {
      border-top: 2px solid palette(white);
    }

    @include breakpoint(small) {
      &:nth-child(2) {
        border-top: 2px solid palette(white);
      }
    }
  }
}

// Link block
//------------------

.link-block {
  display: block;
  position: relative;
  width: calc(100% - 30px);
  padding: 30px 50px 30px 40px;
  margin-bottom: 55px;
  background: palette(white);
  box-shadow: 0 0 22px 5px rgba(0,0,0,0.10);
  border-bottom: 4px solid palette(black);
  text-decoration: none;

  &:last-of-type {
    margin-bottom: 0;
  }

  .link-block__tag {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-50%);
  }

  .link-block__title {
    margin-bottom: 0;
    font-size: 2.2rem;
    font-weight: 800;
  }

  .link-block__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 0;
    width: 60px;
    height: 60px;
    background: palette(black);
    transform: translateY(-50%) translateX(50%);
    transition: transform 0.3s;
  }

  &--timber {
    border-bottom: 4px solid palette(green);

    .link-block__tag {
      background: palette(green);
    }
  }

  &--facades {
    border-bottom: 4px solid palette(blue);

    .link-block__tag {
      background: palette(blue);
    }
  }

  @include breakpoint(medium) {
    &:hover {
      .link-block__arrow {
        transform: translateY(-50%) translateX(60%);
      }
    }
  }
}