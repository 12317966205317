//###########################################################################
// Accordion
//###########################################################################

.accordion__container {
  position: relative;
  margin-bottom: 30px;

  @include breakpoint(medium) {
    margin-bottom: 40px;
  }
}

.accordion__item {
  position: relative;
  background: transparent;
  border-top: 2px solid palette(white);
  transition: background 0.2s;

  &:last-child {
    border-bottom: 2px solid palette(white);
  }

  .accordion__header {
    padding: 8px 0px 8px 5px;

    @include breakpoint(medium) {
      padding: 26px 6px 26px 24px;
    }
  }

  .accordion__heading {
    font-weight: bold;
    margin-bottom: 0;
  }

  .accordion__content {
    overflow: hidden;
    display: none;
    padding: 0 5px 20px 5px;

    @include breakpoint(small) {
      padding: 0 40px 30px 40px;
    }

    @include breakpoint(medium) {
      padding: 0 60px 40px 60px;
    }

    @include breakpoint(large) {
      padding: 0 80px 50px 80px;
    }

    .inner {
      border-top: dotted 2px transparent;
      transition: border .3s;

      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .accordion__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    h3 {
      margin-bottom: 0;
    }

    .accordion__trigger {
      position: relative;
      @include button-unstyled;
      width: 44px;
      height: 44px;

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        span {
          width: 15px;
          height: 3px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: palette(white);

          &:nth-child(2) {
            transform: translate(-50%, -50%) rotate(90deg);
            transition: transform 0.2s;
          }
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &--dark {
    border-top: 2px solid black;

    .accordion__header {
      .accordion__trigger {
        .icon {
          span {
            background: black;
          }
        }
      }
    }

    &:last-child {
      border-bottom: 2px solid black;
    }
  }

  &.open {
    background: rgba(palette(white), 0.1);

    .accordion__trigger {
      .icon {
        span {
          &:nth-child(2) {
            transform: translate(-50%, -50%) rotate(0deg);
          }
        }
      }
    }

    .accordion__content {
      display: block;
    }
  }

}


@include breakpoint(small) {
  .accordion__item .accordion__data .inner {
    padding-left: 10px;
  }
}

/* purgecss end ignore */
