//###########################################################################
// Spacing utilities
//###########################################################################

// Bumps
//------------------

.bump--xs {
    margin-bottom: 10px !important;
}

.bump {
    margin-bottom: 20px !important;
}

.bump--sm {
    margin-bottom: 40px !important;
}

.bump--md {
    margin-bottom: 60px !important;
}

.bump--lg {
    margin-bottom: 80px !important;
}

.bump--none {
    margin-bottom: 0 !important;
}

// Padding Bumps
//------------------

.p-bump--xs {
    padding-bottom: 10px !important;
}

.p-bump {
    padding-bottom: 20px !important;
}

.p-bump-top--xs {
    padding-top: 10px !important;
}

.p-bump-top {
    padding-top: 20px !important;
}

// Bumps responsive
//---------

@include breakpoint-max(small) {
    .bump--small-down { margin-bottom: 20px !important; }
    .bump--sm-small-down { margin-bottom: 40px !important; }
}

@include breakpoint-max(medium) {
    .bump--medium-down { margin-bottom: 20px !important; }
    .bump--sm-medium-down { margin-bottom: 40px !important; }
}

@include breakpoint(medium) {
    .bump--none-medium-up { margin-bottom: 0 !important; }
}

// Bumps top
//------------------

.bump-top {
    margin-top: 20px !important;
}

.bump-top--sm {
    margin-top: 40px !important;
}

.bump-top--md {
    margin-top: 60px !important;
}

.bump-top--lg {
    margin-top: 80px !important;
}

@include breakpoint-max(small) {
    .bump-top--sm-small-down { margin-top: 40px !important; }
    .bump-top--xl-small-down { margin-top: 120px !important; }
}

// Horizontal align
//------------------

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

// Nudges
//------------------

.nudge {
    margin-right: 15px !important;
}

// Padding nudge
//------------------

.p-nudge {
    padding-right: 15px !important;
}

// Padding nudge responsive
//------------------

@include breakpoint(large) {
    .p-nudge-large-up {
        padding-right: 15px !important;
    }

    .p-nudge-lg-large-up {
        padding-right: 60px !important;
    }
}

// Padded
//------------------

.padded {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

// Spacers
//------------------

.spacer {
    margin-bottom: 8vh;

    &--large {
        margin-bottom: 8vh;

        @include breakpoint(small) {
            margin-bottom: 15vh;
        }
    }

    &-top {
        margin-top: 8vh;

        &--large {
            margin-top: 8vh;

            @include breakpoint(small) {
                margin-top: 15vh;
            }
        }
    }
}

// Spacers using padding-bottom
//------------------

.p-spacer {
    padding-bottom: 8vh;

    &--large {
        padding-bottom: 8vh;

        @include breakpoint(small) {
            padding-bottom: 15vh;
        }
    }

    &--xlarge {
        padding-bottom: 8vh;

        @include breakpoint(small) {
            padding-bottom: 17.5vh;
        }
    }

    &-top {
        padding-top: 8vh;

        &--large {
            padding-top: 8vh;
            @include breakpoint(small) {
                padding-top: 15vh;
            }
        }
    }
}

