//###########################################################################
// Slider
//###########################################################################

.slider-item {
  will-change: transform;
}


.slider {
  position: relative;
  padding-bottom: 80px;

  opacity: 0;
  transition: opacity 0.2s;

  &.loaded {
    opacity: 1;
  }

  @include breakpoint(small) {
    padding-bottom: 110px;
  }

  .slider-item {
    @include breakpoint(small) {
      width: 80%;
      margin-right: 30px;
    }
  }

  .slider-item__media {
    position: relative;

    .heading {
      position: absolute;
      background: rgba(0,0,0,0.4);
      z-index: 2;
      bottom: 30px;
      padding: 15px 30px;
      color: white;
      font-size: 2rem;
      font-weight: bold;
    }
  }

  .slider__controls {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .slider__buttons {
      .slider__button--prev {
        margin-right: 4px;
      }
    }
  }

  // For use on dark backgrounds
  //
  &.slider--dark {
    .slider__count {
      color: palette(white);
    }

    .slider__controls {
      .slider__progress {
        background: palette(white);

        .bar {
          background: lighten(palette(black), 20%);
        }
      }
    }
  }

  // For use on light backgrounds
  //
  &.slider--light {
    .slider__controls {
      .slider__progress {
        background: darken(palette(white), 10%);

        .bar {
          background: palette(black);
        }
      }
    }
  }

  &.slider--timber {
    .slider__controls {
      .slider__progress {
        .bar {
          background: palette(green, light);
        }
      }
    }
  }

  &.slider--facades {
    .slider__controls {
      .slider__progress {
        .bar {
          background: palette(blue, light);
        }
      }
    }
  }
}

.slider__progress {
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  margin-bottom: 26px;

  .bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 0px;
    height: 4px;
    transition: width 0.5s;
    border-radius: 2px;
  }
}

.slider__count {
  font-size: 1.8rem;
  font-weight: bold;
  color: palette(black);

  .count {
    font-size: 3.0rem;
  }

  .amount {
    font-size: 1.8rem;
  }
}

.slider__button {
  @include button-unstyled;
}

// Slider hero
//------------------

.slider-hero {
  position: relative;
  padding-bottom: 0 !important;
  opacity: 0;
  transition: opacity 0.2s;

  &.loaded {
    opacity: 1;
  }

  .slider-item {
    position: relative;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &.is-selected {
      pointer-events: auto;
    }

    .slide__media {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .slide__content {
      position: absolute;
      bottom: 30px;
      left: 0;
      width: 100%;
      min-height: 120px;
      z-index: 2;
      background-color: rgba(palette(black), 0.7);
      color: palette(white);
      padding: 27px 30px 32px 80px;

      @include breakpoint(medium) {
        padding: 30px 30px;
      }

      @include breakpoint(large) {
        h2 {
          padding-right: 85px;
        }
      }

      h2, p {
        margin-bottom: 0;
      }

      p {
        font-weight: 500;
      }

      .slider__count {
        position: absolute;
        bottom: 32px;
        right: 40px;
        color: palette(white);

        @include breakpoint-max(large) {
          display: none;
        }
      }
    }
  }

  .slider__buttons {
    position: absolute;
    bottom: 30px;
    left: 0;
    z-index: 1;

    @include breakpoint(medium) {
      transform: translateX(-100%);
    }

    .slider__button {
      display: block;
    }
  }

  .slider__controls {
    bottom: -38px;
  }
}

// Slider tiles
//------------------

.slider-tiles {
  position: relative;
  padding-left: 40px;
  padding-right: 40px;

  opacity: 0;
  transition: opacity 0.2s;

  &.loaded {
    opacity: 1;
  }

  .slider__button {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);

    &--prev {
      left: 0
    }

    &--next {
      right: 0;
    }
  }

  @include breakpoint(small) {
    padding-left: 80px;
    padding-right: 80px;

    .slider-item {
      width: 50%;
    }
  }

  @include breakpoint(medium) {
    .slider-item {
      width: 33.333%;
    }
  }

  @include breakpoint(large) {
    .slider-item {
      width: 25%;
    }
  }

  &.slider--disabled-large {
    @include breakpoint(large) {
      padding-left: 0;
      padding-right: 0;
      margin-left: -16px;
    }
  }
}

// Slider partners content
//------------------

.slider-partners-content {
  padding-bottom: 80px;

  opacity: 0;
  transition: opacity 0.2s;

  &.loaded {
    opacity: 1;
  }

  .slider-item {
    width: 100%;
    pointer-events: none;

    &.is-selected {
      pointer-events: auto;
    }

    .logo {
      padding: 10px;
      margin-bottom: 30px;
      background: palette(white);
      max-width: 236px;
    }

    ul {
      li {
        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

// Slider products
//------------------

.slider-products {
  position: relative;

  opacity: 0;
  transition: opacity 0.2s;

  &.loaded {
    opacity: 1;
  }

  .flickity-viewport {
    overflow: visible;
  }

  .slider-item {
    margin-right: 30px;
    width: 270px;

    &.is-selected {

      .img {
        &::after {
          content: "";
          position: absolute;
          top: -2px;
          right: -2px;
          bottom: -2px;
          left: -2px;
          border: 5px solid palette(black);
        }
      }
    }
  }

  .slider__buttons {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-108px);

    @include breakpoint-max(small) {
      display: none;
    }
  }
}

.slider-products-heading {
  @include breakpoint-max(small) {
    padding-right: 120px;
  }
}

// Slider product categories
//------------------

.slider-product-categories {
  position: relative;

  opacity: 0;
  transition: opacity 0.2s;

  &.loaded {
    opacity: 1;
  }

  .flickity-viewport {
    overflow: visible;
  }

  .slider-item {
    margin-right: 30px;
    width: 240px;

    @include breakpoint(400px) {
      width: 270px;
    }

    &.is-selected {
      .img {
        &::after {
          content: "";
          position: absolute;
          top: -2px;
          right: -2px;
          bottom: -2px;
          left: -2px;
          border: 5px solid palette(black);
        }
      }
    }
  }

  .slider__buttons {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-108px);
  }
}

// Slider product categories content
//------------------

.slider-product-categories-content {
  opacity: 0;
  transition: opacity 0.2s;

  &.loaded {
    opacity: 1;
  }

  .flickity-viewport {
    transition: height 0.3s;
  }

  .slider-item {
    pointer-events: none;

    &.is-selected {
      pointer-events: auto;
    }
  }
}

// Slider projects
//------------------

.slider-projects {
  position: relative;
  padding-bottom: 40px;

  opacity: 0;
  transition: opacity 0.2s;

  &.loaded {
    opacity: 1;
  }

  .flickity-viewport {
    overflow: visible;
  }

  .slider-item {
    width: 240px;
    margin-right: 30px;

    @include breakpoint(385px) {
      width: 270px;
    }

    @include breakpoint(small) {
      width: 470px;
    }
  }

  .slider__buttons {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-88px);

    .slider__button--prev {
      margin-right: 4px;
    }

    @include breakpoint(small) {
      transform: translateY(-95px);
    }

    @include breakpoint-max(384px) {
      display: none;
    }
  }

  &--riba {
    .slider-item {
      width: 100%;

      @include breakpoint(small) {
        width: 33.3%;
      }
    }
  }
}

.slider-projects-heading {
  @include breakpoint(385px) {
    @include breakpoint-max(small) {
      padding-right: 120px;
    }
  }
}

.slider-projects-riba-heading {
  @include breakpoint(385px) {
    padding-right: 120px;
  }

  @include breakpoint(small) {
    padding-right: 140px;
  }
}

// Slider approach
//------------------

.slider-approach {
  margin-bottom: 80px;

  opacity: 0;
  transition: opacity 0.2s;

  &.loaded {
    opacity: 1;
  }

  @include breakpoint-max(medium) {
    margin-top: 200px;
  }

  .flickity-viewport {
    @include breakpoint-max(medium) {
      overflow: visible;
    }

    @include breakpoint(medium) {
      @supports (clip-path: inset( -100vw -100vw -100vw 0 )) {
        overflow: visible;
        clip-path: inset(-100vw -100vw -100vw 0);
      }
    }
  }

  .slider-item {
    width: 80%;
    margin-right: 42px;

    @include breakpoint(small) {
      width: 100%;
    }
  }

  .slider__progress {
    position: absolute;
    bottom: -55px;
    left: 0;
    background: darken(palette(blue), 10%);

    .bar {
      background: palette(blue, light);
    }
  }

  .slider__count {
    position: absolute;
    bottom: -80px;
    left: 0;
    color: palette(white);
  }

  .slider__button {
    position: absolute;
    top: 50%;
    z-index: 1;
    filter: contrast(0.8);

    @include breakpoint-max(small) {
      display: none;
    }

    &--prev {
      left: 0;
      transform: translateY(-50%) translateX(-50%);
    }

    &--next {
      right: 0;
      transform: translateY(-50%) translateX(50%);
    }
  }
}

// Slider mobile only
//------------------

.slider-mobile-only {
  opacity: 1;

  .slider__buttons {
    display: none;
  }

  @include breakpoint-max(small) {
    position: relative;
    opacity: 0;
    transition: opacity 0.2s;

    &.loaded {
      opacity: 1;
    }

    .flickity-viewport {
      overflow: visible;
    }

    .slider-item {
      margin-right: 30px;
      width: 100%;
    }

    .slider__buttons {
      display: inline-flex;
      justify-content: flex-end;
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(-50%);
      z-index: 1;

      .slider__button--prev {
        margin-right: 4px;
      }
    }

    &--showrooms {
      display: block;
      margin-right: 0;
      margin-left: 0;

      .flickity-viewport {
        margin-bottom: 40px;
      }

      .flex-col {
        padding: 0;
      }

      .slider-item {
        width: 240px;

        @include breakpoint(414px) {
          width: 270px;
        }
      }
    }
  }
}

// Slider flooring selector
//------------------

.slider-fs {
  position: relative;

  opacity: 0;
  transition: opacity 0.2s;

  &.loaded {
    opacity: 1;
  }

  .slider-item {
    margin-right: 30px;
    width: 100%;
  }

  .shortlist__add {
    position: absolute;
    top: 12px;
    right: 10px;
    z-index: 1;
    cursor: pointer;

    &:before {
      content: '';
      display: none;
    }

    .shortlist__add__label {
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1;
      color: palette(black);
      background: palette(white);
      padding: 8px 32px 8px 18px;
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
      position: relative;
      top: 2px;
      left: 20px;
      z-index: 1;
      transition: background-color 0.2s;
      pointer-events: none;
    }

    .icon {
      position: relative;
      z-index: 3;
    }
  }
}

// Slider flooring selector nav
//------------------

.slider-fs-nav__wrapper {
  display: flex;
  margin-top: 16px;
}

.slider-fs-nav {
  opacity: 0;
  transition: opacity 0.2s;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;

  &.loaded {
    opacity: 1;
  }

  .slider-item {
    position: relative;
    width: 33.3%;
    margin-right: 10px;

    @include breakpoint(small) {
      width: 25%;
    }

    @include breakpoint(medium) {
      width: 16.66%;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      border: 0px solid palette(green, lighter);
      opacity: 0;
      transition: all 0.2s;

      @include breakpoint(small) {
        width: calc(100% - 10px);
        height: calc(100% - 10px);
      }
    }

    &.is-nav-selected {
      &:after {
        opacity: 1;
        border: 2px solid palette(green, lighter);

        @include breakpoint(small) {
          border: 5px solid palette(green, lighter);
        }
      }
    }
  }
}