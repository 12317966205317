//###########################################################################
// HTML & Body
//###########################################################################

html {
  height: 100%;
  overflow-x: hidden;
}

html, body {
  max-width: 100%;
  font-size: 55% !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  min-height: 100% !important;
  font-family: $body-font;
  font-style: normal;
  font-weight: 400;
  color: palette(black);
  background: white;

  @include breakpoint(large) {
    font-size: 62.5% !important;
    /* Normalise font size so that 1rem = 10px, 2rem = 20px etc. */
  }
}

body {
  position: relative;
  overflow-x: hidden;
  scroll-behavior: smooth;

  &.fix { overflow: hidden; }
}

//::-moz-selection {
//  background-color: palette(brand, compliment);
//  color: white;
//}
//
//::selection {
//  background-color: palette(brand, compliment);
//  color: white;
//}

main {
  position: relative;
  z-index: 1;
  @extend %clearfix;
  padding-top: 56px;

  @include breakpoint(medium) {
    padding-top: 117px;
  }

  @include breakpoint(large) {
    padding-top: 120px;
  }
}