//###########################################################################
// Colour utilities
//###########################################################################

.color--white {
  color: palette(white);
}

.color--black {
  color: palette(black);
}

.color--grey {
  color: palette(grey);
}

a.color--grey {
  transition: color .3s;
  &:hover {
    color: white;
  }
}

.color--green, .color--timber {
  color: palette(green);
}

.color--green-light {
  color: palette(green, light);
}

.color--green-lighter {
  color: palette(green, lighter);
}

.color--blue, .color--facades {
  color: palette(blue);
}

.color--blue-light {
  color: palette(blue, light);
}

.color--red-error {
  color: palette(red, error);
}