//###########################################################################
// Details panel
//###########################################################################

.details-panel {
  width: 100%;
  padding: 32px;
  background: palette(black);
  color: palette(white);

  @include breakpoint(small) {
    width: 90%;
  }

  @include breakpoint(medium) {
    padding: 36px 60px;
    width: 85%;
  }

  @include breakpoint(large) {
    padding: 40px 100px;
  }

  &--right {
    margin-left: auto;
  }
}

.details-panel__inner {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: -16px;
  margin-right: -16px;

  @include breakpoint(small) {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }

  p {
    margin-bottom: 0;

    @include breakpoint(small) {
      @include breakpoint-max(960px) {
        font-size: 1.4rem;
      }
    }
  }
}

.details-panel__item {
  margin: 0 16px;
  flex-grow: 1;
  flex-basis: 0;

  @include breakpoint-max(small) {
    margin-bottom: 20px;
  }
}