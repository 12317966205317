//###########################################################################
// Button
//###########################################################################

.btn {
  text-decoration: none;
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.375;
  font-family: $heading-font;
  font-weight: bold;
  background-color: transparent;
  border-color: palette(black);
  border-width: 2px;
  border-style: solid;
  color: palette(black);
  outline: none;
  padding: 12px 20px;
  display: inline-block;
  cursor: pointer;
  transition: all .3s;
  user-select: none;

  &:hover, &:focus {
    background-color: lighten(palette(black), 10%);
    color: palette(white);
  }
}

//###########################################################################
// Button modifiers
//###########################################################################

// Sizes
//------------------

.btn--small {
  font-size: 1.7rem;
  line-height: 2.3rem;
  padding: 7px 20px;
}

.btn--large {
  font-size: 2.8rem;
  line-height: 3.3rem;
  padding: 14px 40px;
}

// Button with icon
//------------------

.btn--icon-left {
  .icon {
    position: relative;
    top: -1px;
    margin-right: 20px;
  }
}

.btn--icon-right {
  .icon {
    position: relative;
    top: -1px;
    margin-left: 20px;
  }
}

// Button outlines
//------------------

.btn--white {
  color: palette(white);
  border-color: palette(white);
  background-color: transparent;

  &:hover, &:focus {
    color: palette(white);
    border-color: darken(palette(white), 10%);
    background-color: darken(palette(white), 10%);
  }
}

.btn--black {
  color: palette(black);
  border-color: palette(black);
  background-color: transparent;

  &:hover, &:focus {
    background: palette(black, slate);
    color: palette(white);

    .icon {
      path {
        fill: palette(white) !important;
      }
    }
  }
}

.btn--timber {
  color: palette(black);
  border-color: palette(green, lighter);
  background-color: transparent;

  &.color--white {
    color: white;
  }

  &:hover, &:focus {
    border-color: palette(green);
    background-color: palette(green, lighter);
  }
}

.btn--facades {
  color: palette(black);
  border-color: palette(blue, light);
  background-color: transparent;

  &.color--white {
    color: white;
  }

  &:hover, &:focus {
    color: palette(black);
    border-color: palette(blue);
    background-color: palette(blue, lighter);
  }
}

// Button filled
//------------------

.btn--white-fill {
  color: palette(black);
  border-color: palette(white);
  background-color: palette(white);

  &:hover, &:focus {
    color: palette(black);
    border-color: darken(palette(white), 10%);
    background-color: darken(palette(white), 10%);
  }
}

.btn--black-fill {
  color: palette(white);
  border-color: palette(black);
  background-color: palette(black);

  &:hover, &:focus {
    color: palette(white);
    border-color: palette(black, slate);
    background: palette(black, slate);
  }
}

.btn--timber-fill {
  color: palette(black);
  border-color: palette(green, lighter);
  background-color: palette(green, lighter);

  &:hover, &:focus {
    color: palette(black);
    border-color: #B1C59D;
    background-color: #B1C59D;
  }
}

.btn--facades-fill {
  color: palette(black);
  border-color: palette(blue, light);
  background-color: palette(blue, light);

  &:hover, &:focus {
    color: palette(black);
    border-color: palette(blue, lighter);
    background-color: palette(blue, lighter);
  }
}

// Button modifiers
//------------------

// How can we help
//
.btn--hcwh {
  padding: 1.65rem 20px;

  @include breakpoint(large) {
    padding: 1.55rem 20px;
  }
}

// Align button at bottom of div
// - to be used on the buttons main container
//
.btn--bottom-small-down {
  @include breakpoint-max(small) {
    position: relative;
    padding-bottom: 50px;
    
    .wrapper {
      position: unset;
    }

    .btn {
      position: absolute;
      bottom: 0;
      left: 1.33334rem;
    }
  }
}

.btn-resources--bottom-small-down {
  @include breakpoint-max(small) {
    margin-bottom: 20vh;

    .btn {
      position: absolute;
      bottom: -80px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 240px;
    }
  }
}