//###########################################################################
// CTA
//###########################################################################

.cta {
  padding: 32px;
  background: palette(black);
  color: palette(white);

  @include breakpoint(small) {
    padding: 44px 50px;
  }

  @include breakpoint(medium) {
    padding: 48px 60px;
  }
}

// CTA contact
//------------------

.cta-contact {
  border-bottom: 4px solid white;

  .wrapper {
    position: relative;
    z-index: 2;
  }

  .inner {
    padding: 30px;
    border: 3px solid black;
    position: relative;
    margin-bottom: -100px;
    background-color: white;

    .btn {
      position: absolute;
      bottom: 0;
      left: 30px;
      transform: translateY(50%);
    }
  }

  p a {
    font-weight: 500;
  }

  .cta-image {
    position: relative;
    min-height: 180px;
    background-color: white;
    opacity: .6;
  }

  @include breakpoint(small) {

    .inner {
      padding: 40px 70px;

      .btn {
        left: 70px;
      }
    }

    .cta-image {
      min-height: 240px;
    }

  }
}

.cta-contact--timber {
  p a {
    color: palette(green, light);
  }
}

.cta-contact--facades {
  p a {
    color: palette(blue, lighter);
  }
}

.cta-contact--404 {
  margin-top: -91px;

  @include breakpoint(small) {
    margin-top: -213px;
  }
  @include breakpoint(medium) {
    margin-top: -137px;
  }
  @include breakpoint(large) {
    margin-top: -152px;
  }
}


// CTA custom
//------------------

.cta-custom {
  border: 3px solid black;

  .img {
    position: relative;
    min-height: 300px;

    // Fix for safari pixel gap issue
    margin-bottom: -1px;

    &--tint {
      &::before {
        content: "";
        position: absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        background-color: black;
        opacity: .4;
        z-index: 2;
      }
    }

    .img__text {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      padding: 30px;
      z-index: 3;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .text .inner {
    padding: 30px;
    position: relative;
  }

  &--selector {
    h2 {
      text-align: center;
      padding-left: 20px;
      padding-right: 20px;
      position: absolute;
      top:0;
      left:50%;
      white-space: nowrap;
      background-color: white;
      transform: translateX(-50%) translateY(-50%);
    }

    .text .inner {
      padding: 60px 30px 30px;
    }
  }

  @include breakpoint(medium) {
    display: flex;

    &--selector {
      flex-direction: row-reverse;
    }

    > * {
      flex: 1;
    }

    .text .inner {
      padding: 50px 70px;
    }

  }
}