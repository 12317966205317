//###########################################################################
// Block
//###########################################################################

.block {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  &--flip {
    flex-direction: row-reverse;
  }

  .block__image {
    position: relative;
    flex: 0 0 auto;
    width: 100%;
    height: 200px;

    @include breakpoint(small) {
      width: 50%;
      height: auto;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .block__content {
    padding: 42px 32px 48px 32px;
    flex: 0 0 auto;
    width: 100%;

    @include breakpoint(small) {
      width: 50%;
      padding: 52px 40px 60px 40px;
    }

    @include breakpoint(large) {
      padding: 52px 70px 60px 70px;
    }
  }

  &--timber {
    .block__content {
      background: palette(green, lighter);
    }
  }

  &--facades {
    .block__content {
      background: palette(blue, light);
    }
  }

  &--group {
    .block__content {
      background: palette(blue, light);
    }
  }
}

// Block full width
//------------------

.block-full {
  .inner {
    padding: 50px 20px;
    max-width: 540px;
    margin: 0 auto;
  }

  .block__logo {
    max-width: 180px;
    margin: 0 0 30px;
  }

  .block__half {
    position: relative;

    &--img {
      min-height: 400px;
    }

    &--tint {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: black;
        opacity: .4;
        z-index: 2;
      }

      .inner {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translateY(-50%) translateX(-50%);
        z-index: 3;
      }
    }
  }

  @include breakpoint(medium) {
    display: flex;

    &--flip {
      flex-direction: row-reverse;
    }

    > * {
      flex: 1;
    }

    .inner {
      padding: 100px 40px;
      max-width: 580px;
      margin: 0 auto;
    }
  }
}

// Block help
//------------------

.block-help {
  border: 3px solid black;
  padding: 40px 30px 30px;
  position: relative;

  h2 {
    text-align: center;
    position: absolute;
    top: 0;
    transform: translateY(-50%) translateX(-50%);
    background-color: white;
    left: 50%;
    padding-left: 20px;
    padding-right: 20px;
  }

  select {
    margin-bottom: 20px;
  }


  @include breakpoint(medium) {
    select {
      margin-bottom: 0;
    }

    .btn {
      width: 100%;
    }
  }
}

// Block resources
//------------------

.block-resources {
  position: relative;
  overflow: hidden;
}

// Map
//------------------

.map__container {
  position: relative;
  z-index: 2;
}

.map__hold {
  position: relative;

  #map {
    width: 100%;
    height: 380px;
  }

  .btn {
    position: absolute;
    bottom: 0;
    left: 30px;
    transform: translateY(50%);
  }

  @include breakpoint(small) {
    margin-top: -60px;

    #map {
      height: 600px;
    }
  }
}