//###########################################################################
// Decor
//###########################################################################

.decor__hold {
  position: relative;
}

.decor__container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  pointer-events: none;

  @include breakpoint-max(small) {
    display: none;
  }
}

.decor {
  color: palette(white);
  opacity: 0.1;
  font-size: 14rem;
  font-weight: bold;
  line-height: 0.7;
  bottom: 0;
  left: 0;
}

.decor--b-l {
  text-align: left;
}

.decor--b-r {
  text-align: right;
}