//###########################################################################
// Hero
//###########################################################################

.hero {
  position: relative;

  h1 {
    @include breakpoint-max(small) {
      font-size: 4.0rem;
    }
  }
}

// Hero simple
//------------------

.hero-simple {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: black;

  .hero__logo {
    max-width: 180px;
    margin: 0 0 30px;
  }

  @include breakpoint(small) {
    padding-top: 120px;
    padding-bottom: 100px;
  }

  .search {
    position: relative;
    max-width: 400px;
    border: 2px solid #000000;

    input {
      border: none;
      width: calc(100% - 60px);
    }

    .submit {
      border: none;
      width: 60px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
      background: palette(grey, light);
    }

    &--large {
      max-width: 100%;

      input {
        padding: 20px 20px;
      }

      .submit {
        background: #2D2D2D;

        svg path {
          fill: #ffffff
        }
      }
    }
  }

  &--light {
    background-color: palette(white);
    color: palette(black);
  }

  &--dark {
    background-color: palette(black);
    color: palette(white);
  }
}

// Hero products pull up
//------------------

.hero--products-pull-up {
  margin-top: -30px;

  @include breakpoint(small) {
    margin-top: -80px;
  }
}

// Hero media
//------------------

.hero-media {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: black;

  &--image {
    &::before {
      content: "";
      background-color: black;
      z-index: 1;
      opacity: .6;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .hero__logo {
    max-width: 360px;
    margin: 0 auto 30px;
  }

  .btn__group {
    position: relative;
    padding: 50px 0 35px;
    border: 2px solid white;
    max-width: 100%;
    width: 490px;
    margin: 0 auto;

    p {
      padding-left: 10px;
      padding-right: 10px;
      margin: 0;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      background-color: black;
    }
  }

  .wrapper {
    position: relative;
    z-index: 2;
  }

  h1, p {
    color: white;
  }

  @include breakpoint(small) {
    padding-top: 80px;
    padding-bottom: 80px;


    .btn__group {

      padding: 40px 0 35px;

      p {
        white-space: nowrap;
        padding-left: 25px;
        padding-right: 25px;
      }
    }
  }

  @include breakpoint(medium) {
    min-height: 465px;
    display: flex;
    align-items: center;
  }

  @include breakpoint(large) {
    min-height: 510px;
  }
}

.hero-media-large {
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: black;

  .wrapper {
    position: relative;
    z-index: 2;
  }


  .hero__logo {
    max-width: 180px;
    margin: 0 0 30px;
  }

  h1, p {
    color: white;
  }

  .inner {
    position: relative;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 50px 30px 50px 0;

    .tag {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-50%);
    }

    h1, .h1 {
      margin-bottom: 0
    }

    &::before {
      content: "";
      position: absolute;
      right: 100%;
      top: 0;
      bottom: 0;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      width: 100vw;
    }
  }

  @include breakpoint(small) {
    padding-top: 100px;
    padding-bottom: 100px;

    .inner {
      padding: 80px 30px 80px 0;
    }
  }

  @include breakpoint(medium) {
    min-height: 700px;
    display: flex;
    align-items: center;
  }

  @include breakpoint(large) {
    .inner {
      padding: 100px 90px 100px 0;
    }
  }
}

// Hero slider
//------------------

.hero-slider {
  position: relative;

  .wrapper {
    z-index: 2;
  }

  .hero__logo {
    max-width: 180px;
    margin: 0 0 30px;
  }

  .inner {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .slider-hero {
    width: 100%;
    height: 390px;
  }

  @include breakpoint(small) {
    .slider-hero {
      height: 520px;
    }

    .inner {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }

  @include breakpoint(medium) {
    min-height: 650px;

    .slider-hero {
      position: absolute;
      top: -54px;
      right: 0;
      width: 45%;
      height: 550px;
    }
  }

  @include breakpoint(large) {
    min-height: 675px;

    .inner {
      padding-top: 125px;
      padding-bottom: 125px;
    }

    .slider-hero {
      height: 650px;
    }
  }
}

// Hero career
//------------------

.hero-career {
  padding-top: 50px;
  padding-bottom: 50px;

  .hero__text {
    text-align: center;
    max-width: 600px;
    margin: 0 auto 30px;
  }

  .wrapper {
    position: relative;
    z-index: 2;
  }

  .inner {
    padding-top: 50px;
    padding-bottom: 50px;
  }


  .hero__logo {
    max-width: 180px;
    margin: 0 0 30px;
  }

  @include breakpoint(small) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @include breakpoint(medium) {
    min-height: 465px;
    display: flex;
    align-items: center;
  }

  @include breakpoint(large) {
    min-height: 510px;
  }
}

.hero-split {


  .half {
    padding: 30px;
  }

  .inner {
    max-width: 450px;
    margin: 0 auto;
  }

  .location {
    position: relative;

    .icon {
      position: absolute;
      top:10px;
      right: 10px;
    }
  }

  input {
    background: transparent;
    border-color: palette(white);
    color: palette(white);
  }

  .half--form {
    background: #353535;
  }

  @include breakpoint(small) {
    display: flex;

    .half {
      padding: 100px 30px;
    }

    > * {
      flex: 1;
    }
  }
}

.hero__selectors {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  padding-top: 20px;
  padding-bottom: 20px;
}