//###########################################################################
// Icons
//###########################################################################

.icon {
  display: inline-block;
  vertical-align: middle;

  > svg {
    display: block;
    transition: fill .3s;

    path {
      transition: fill .3s;
    }
  }
}

// Icon colours
//------------------

.icon--white { > svg { fill: palette(white) !important; } }
.icon--black, .icon--group { > svg { fill: palette(black) !important; } }

// Colours
//
@each $color, $shades in $palettes {
  $colorName: if($color == grey, '-', '--#{$color}');
  @each $shade, $value in $shades {
    $shadeModifier: if($shade == base, '', '-#{$shade}');

    @if $shade == base {
      $shadeModifier: if($color == grey, '-#{$color}', '');
    } @else {
      $shadeModifier: '-#{$shade}';
    }

    .icon#{$colorName}#{$shadeModifier} {
      > svg {
        fill: palette($color, $shade) !important;
      }
    }
  }
}

.icon--timber { > svg { fill: palette(green, lighter) !important; } }
.icon--facades { > svg { fill: palette(blue, light) !important; } }

// Icon hover
//------------------

.icon--h-blue {
  &:hover {
    svg {
      path {
        fill: palette(blue, light) !important;
      }
    }
  }
}

// Icon sizes
//------------------

.icon--12 {
  &,
  & > svg {
    width: 12px;
    height: 12px;
  }
}

.icon--15 {
  &,
  & > svg {
    width: 15px;
    height: 15px;
  }
}

.icon--20 {
  &,
  & > svg {
    width: 20px;
    height: 20px;
  }
}

.icon--22 {
  &,
  & > svg {
    width: 22px;
    height: 22px;
  }
}

.icon--25 {
  &,
  & > svg {
    width: 25px;
    height: 25px;
  }
}

.icon--30 {
  &,
  & > svg {
    width: 30px;
    height: 30px;
  }
}

.icon--32 {
  &,
  & > svg {
    width: 32px;
    height: 32px;
  }
}

.icon--40 {
  &,
  & > svg {
    width: 40px;
    height: 40px;
  }
}

.icon--60 {
  &,
  & > svg {
    width: 60px;
    height: 60px;
  }
}

// Icon sizes responsive
//---------

@include breakpoint-max(small) {
  .icon--40-small-down {
    &,
    & > svg {
      width: 40px;
      height: 40px;
    }
  }
}

// Icon inline
//------------------

.icon--inline {
  margin: 0 4px;
}

// Icon logo
//------------------

.icon--logo {
  &,
  & > svg {
    width: 240px;
    height: auto;
    path { fill: #1D1D1B; }
  }
}

// Icon card (used in iconCards builder module)
//------------------

.icon--card {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  height: 0;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: 100%;
    max-height: 80%;
  }

  @include breakpoint(medium) {
    padding-bottom: 66.6%;

    img {
      max-height: 75%;
    }
  }
}