//###########################################################################
// Tag
//###########################################################################

.tag {
  min-width: 90px;
  padding: 10px 20px;
  text-align: center;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: bold;
  color: palette(white);
  background-color: palette(black);
  text-transform: capitalize;

  &--timber {
    background-color: palette(green);
  }

  &--facades {
    background-color: palette(blue);
  }
}

.tag__container {
  position: relative;

  .tag__hold {
    position: absolute;
    top:0;
    left:50%;
    transform: translateY(-50%) translateX(-50%);
  }
}