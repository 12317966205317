//###########################################################################
// Pagination
//###########################################################################

.pagination {
  text-align: center;
  font-size: 1.6rem;

  a, span {
    position: relative;
    padding: 6px 14px;
    margin: 0 8px;
    color: palette(black);
    font-weight: 500;
    text-decoration: none;

    @include breakpoint(medium) {
      margin: 0 4px;
    }
  }

  .icon {
    padding: 0;
    opacity: .5;
    transition: opacity .5s;

    &:hover {
      opacity: 1;
    }
  }

  .previous {
    margin-right: 4px;
  }
  .next {
    margin-left: 4px;
  }

  @include breakpoint(medium) {
    .previous {
      margin-right: 4px;
    }
    .next {
      margin-left: 4px;
    }
  }

  span {
    &.current {
      background: palette(blue, light);

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        width: 34px;
        height: 34px;
      }
    }
  }

  &.pagination--green {
    span {
      &.current {
        background: palette(green, lighter);
      }
    }
  }
}