//###########################################################################
// Width utilities
//###########################################################################

// Max widths
//------------------

.mw-165 { max-width: 165px !important; }
.mw-200 { max-width: 200px !important; }
.mw-250 { max-width: 250px !important; }
