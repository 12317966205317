//###########################################################################
// Overflow utilities
//###########################################################################

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-visible {
  overflow-y: visible;
}