//###########################################################################
// Tile
//###########################################################################

// Tile common
//------------------

.tile-common {
  display: block;
  position: relative;
  background-color: black;
  min-height: 300px;
  transition: box-shadow .3s;

  .logo, .title {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .tag {
    z-index: 3;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .title {
    line-height: 1.4;
    font-size: 2.4rem;
  }

  .img {
    &::before {
      content: "";
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 17%, rgba(0, 0, 0, 0.60) 99%);
      position: absolute;
      z-index: 2;
      height: 50%;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translateX(-90%) translateY(-50%);
    transition: opacity .3s, transform .3s;
    z-index: 4;
  }

  &--timber {
    .arrow {
      fill: #9BAE88;
    }
  }

  &--facades {
    .arrow {
      fill: palette(blue, light);
    }
  }

  &:hover {
    @include breakpoint(medium) {
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.71);

      .icon {
        opacity: 1;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }

  @include breakpoint(medium) {
    min-height: 353px;
  }
}

.tile-common-extender {
  background: palette(grey, light);
  padding: 15px;
  margin-bottom: 0;
}

// Tile service
//------------------

.tile-service {
  position: relative;
  top: 0;
  transition: all .3s;
  padding: 22px 22px 30px;
  height: 100%;

  .img {
    img {
      height: 100px;
    }
  }

  a {
    text-decoration: none;
  }

  @include breakpoint(medium) {
    padding: 30px;

    &:hover {
      top: -4px;
      box-shadow: 0 10px 20px 2px rgba(0, 0, 0, 0.2);
    }
  }

  &--outline {
    height: 100%;
    padding: 22px 22px 70px;
    border-top: 4px solid palette(blue, light);
    background: #FFFFFF;
    box-shadow: 0 10px 20px 2px rgba(0, 0, 0, 0.03);
    position: relative;

    @include breakpoint(medium) {
      padding: 30px 30px 70px;
    }

    p a {
      position: absolute;
      left: 0;
      bottom: 30px;
      width: 100%;
    }
  }
}

// Tile partner
//------------------

.tile-partner {
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 30px;

  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 145px;
    padding: 15px 30px;
    background: #FFFFFF;
    box-shadow: 0 10px 20px 2px rgba(0, 0, 0, 0.10);
    border-top: 4px solid palette(green);
    transition: all 0.3s;
  }

  img {
    max-height: 115px;

    @include breakpoint-max(small) {
      max-width: 300px;
      width: 100%;
    }
  }
}

// Tile project
//------------------

.tile-project {
  display: block;
  position: relative;

  .tag {
    position: absolute;
    top: 30px;
    left: 30px;
  }

  .img {
    position: relative;
    overflow: hidden;

    img {
      transform: scale(1);
      transform-origin: center;
      transition: 0.3s;
    }
  }

  .overlay {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;
    padding: 40px 30px 40px 20px;
    background-color: rgba(0, 0, 0, .7);
    z-index: 1;

    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin-bottom: 0;
      margin-right: 22px;
    }

    .icon {
      transition: all 0.3s;
    }
  }

  // RIBA variation of the project tile
  //
  &.tile-project--riba {
    .overlay {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 40px 20px;

      p {
        margin-right: 22px;
      }
    }
  }

  @include breakpoint(medium) {
    &:hover {
      .img {
        img {
          transform: scale(1.05);
        }
      }

      .overlay {
        .icon {
          transform: translateX(6px);
        }
      }
    }
  }
}

.tile-project--slider {
  display: block;
  max-width: 470px;
  position: relative;

  .tag {
    position: absolute;
    top: 30px;
    left: 30px;
  }

  .overlay {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;
    padding: 24px 32px 24px 20px;
    background-color: rgba(0, 0, 0, .7);
    z-index: 1;

    p {
      margin-bottom: 0;
    }

    @include breakpoint(small) {
      padding: 40px 50px 40px 20px;
    }

    @include breakpoint(medium) {
      padding: 40px 100px 40px 20px;
    }
  }
}

// Tile news
//------------------

.tile-news {
  position: relative;
  height: 100%;

  .img__overlay {
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
    border-bottom: 4px solid palette(black);

    img {
      display: block;
      transform: scale(1);
      transform-origin: center;
      transition: transform 0.3s;
    }
  }

  .preview__text {
    padding-bottom: 50px;

    .read-more {
      position: absolute;
      bottom: 0;
      left: 0;
      transition: opacity 0.3s;

      a {
        text-decoration: none;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .overlay {
    background-color: rgba(255, 255, 255, 0.95);
    padding: 30px;
    position: absolute;
    top: 15%;
    left: 0;
    width: 90%;

    @include breakpoint(small) {
      padding: 34px 30px;
    }

    @include breakpoint(large) {
      top: 20%;
      padding: 50px 30px;
    }

    .date {
      margin-bottom: 4px;
    }

    .title {
      a {
        text-decoration: none;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    p {
      margin-bottom: 0;
    }

    .tag {
      position: absolute;
      top: 0;
      transform: translateY(-50%);
      left: 30px;
    }

    .read-more {
      transition: opacity 0.3s;
    }
  }

  &--timber {
    .img__overlay {
      border-bottom: 4px solid palette(green, lighter);
    }

    .overlay {
      background-color: rgba(palette(green, lighter), 0.95);
    }
  }

  &--facades {
    .img__overlay {
      border-bottom: 4px solid palette(blue, light);
    }

    .overlay {
      background-color: rgba(palette(blue, light), 0.95);
    }
  }

  &--featured {
    .overlay {
      .read-more {
        display: none;

        a {
          text-decoration: none;
        }
      }
    }

    @include breakpoint(medium) {
      .img__overlay {
        overflow: visible;

        @include breakpoint(small) {
          max-width: calc(99.9% * 1 / 3 - (30px - 30px * 1 / 3));

          .overlay {
            left: auto;
            right: 0;
            transform: translateX(90%);
            width: 200%;

            .title {
              font-size: 3.6rem;
              line-height: 1.3;
            }
          }
        }

        @include breakpoint(large) {
          .overlay {
            .title {
              font-size: 4.8rem;
            }
          }
        }
      }

      .overlay {
        .read-more {
          display: block;
          position: absolute;
          bottom: 0;
          right: 0;
          transform: translateY(150%);
        }
      }

      .preview__text {
        display: none;
      }

      &:hover {
        .img__overlay {
          img {
            transform: scale(1.025) !important;
          }
        }
      }
    }
  }

  &:hover {
    @include breakpoint(medium) {
      .img__overlay {
        img {
          transform: scale(1.1);
        }
      }

      .preview__text, .overlay {
        .read-more {
          opacity: 0.75;
        }
      }
    }
  }
}

// Tile swatch
//------------------

.tile-swatch {
  .img__hold {
    position: relative;
    height: 220px;
    margin-bottom: 5px;
    transition: filter 0.2s;
    overflow: hidden;

    .icon {
      position: absolute;
      top: 65%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: all 0.2s;
      pointer-events: none;
    }
  }

  .heading {
    position: relative;
    padding-right: 50px;
  }

  &.tile-swatch--fs {
    .img__hold {
      .icon {
        .circle {
          fill: palette(green, lighter);
        }
      }
    }
  }

  &.tile-swatch--in-stock {
    .img__hold {
      &:before {
        content: 'Sample in stock';
        position: absolute;
        top: 8px;
        left: 8px;
        color: palette(white);
        background: rgba(0,0,0,0.7);
        padding: 8px 14px;
        z-index: 1;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  @include breakpoint(medium) {
    .img__hold {
      &:hover {
        filter: brightness(0.9);

        .icon {
          top: 50%;
          opacity: 1;
        }
      }
    }
  }
}


// Tile approach
//------------------

.tile-approach {
  position: relative;
  background: palette(white);
  border-top: 4px solid palette(blue);
  padding: 32px 24px;

  @include breakpoint(small) {
    padding: 32px 50px;
  }

  .tile-approach__index {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 120px;
    font-weight: 800;
    color: palette(white);
    transform: translateY(-100%);
    line-height: 0.73;

    @include breakpoint(small) {
      font-size: 200px;
    }
  }

  @include breakpoint(small) {
    padding: 42px 70px;
  }
}

// Tile resource
//------------------

.tile-resource {
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 30px;

  &.tile-resource--no-spacing {
    margin-right: 0;
    margin-left: 0;
  }

  .inner {
    position: relative;
    background: #FFFFFF;
    box-shadow: 0 10px 20px 2px rgba(0, 0, 0, 0.10);
    border-top: 4px solid palette(black);
    margin-bottom: 16px;

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
      transform-origin: center;
      transition: transform 0.3s;
      width: 44px;
      height: 44px;
      background: palette(black);

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  img {
    display: block;
    width: 100%;
  }

  &--timber {
    .inner {
      border-top: 4px solid palette(green);
    }
  }

  &--facades {
    .inner {
      border-top: 4px solid palette(blue);
    }
  }

  &:hover {
    @include breakpoint(medium) {
      .icon {
        transform: translate(-50%, -55%) scale(1.05);
      }
    }
  }
}

// Tile resource block
//------------------

.tile-resource-block {
  margin-bottom: 40px;

  ul {
    border-top: solid 1px #A1A1A1;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      border-bottom: solid 1px #A1A1A1;
      margin: 0;
      padding: 0;
    }

    a {
      position: relative;
      text-decoration: none;
      padding: 15px 20px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .download, .watch {
        font-weight: normal;
        text-decoration: underline;
      }

      @include breakpoint-max(small) {
        flex-direction: column;

        span {
          &:not(:last-child) {
            margin-bottom: 20px;
          }

          &:last-child {
            margin-bottom: 0 !important;
          }
        }
      }

      @include breakpoint(small) {
        .text {
          flex: 0 0 auto;
          width: 60%;
        }

        .type {
          flex: 0 0 auto;
          width: 20%;
          margin-right: 12px;
        }

        .download, .watch {
          margin-bottom: 0;
        }
      }
    }
  }

  &.hidden {
    display: none;
  }

  &--facades {
    @include breakpoint(small) {
      a {
        &:hover {
          background: palette(blue, light);
        }
      }
    }
  }

  &--timber {
    @include breakpoint(small) {
      a {
        &:hover {
          background: palette(green, light);
        }
      }
    }
  }
}

// Tile brochure
//------------------

.tile-brochure {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center center;
  margin-top: 20px;
  margin-bottom: 100px;
  border-bottom: 4px solid palette(black);

  .tag {
    position: relative;
    left: 20px;
    top: 0;
    transform: translateY(-50%);

    @include breakpoint(small) {
      left: 32px;
    }
  }

  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.80) 100%);
    padding: 20px;
  }

  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(150%);

    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      text-decoration: none;
    }

    .block-social {
      display: flex;

      a {
        &:not(:last-child) {
          margin-right: 12px;
        }
      }
    }
  }

  &--facades {
    border-bottom: 4px solid palette(blue);

    .tag {
      background: palette(blue);
    }

    .link {
      color: palette(blue);
    }
  }

  &--timber {
    border-bottom: 4px solid palette(green, light);

    .tag {
      background: palette(green, light);
    }

    .link {
      color: palette(green, light);
    }
  }
}