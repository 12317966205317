//###########################################################################
// Border utilities
//###########################################################################

.bt--white {
  border-top: 2px solid palette(white);
}

.bb--white {
  border-bottom: 2px solid palette(white);
}