//###########################################################################
// Colours variables
//###########################################################################

// Palettes
//------------------

$palettes: (
        white: (
                base: #ffffff
        ),
        black: (
                base: #000000,
                slate: #2D2D2D
        ),
        grey: (
                light: #eaeaea,
                base: #7F7F7F,
                dark: #5f5f5f
        ),
        green: (
                lighter: #9BAE88,
                light: #7B866E,
                base: #6B775D
        ),
        blue: (
                lighter: #3CB6C9,
                light: #38A4B5,
                base: #216B77
        ),
        red: (
                error: #EE3D52,
                base: #D94547
        ),
        brand: (
                timber: #6B775E
        )
) !global;