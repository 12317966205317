//###########################################################################
// Header
//###########################################################################

#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  transition: transform .3s;

  .show-desktop, .promo {
    display: none;
  }

  a {
    text-decoration: none;
  }

  .site-header {
    position: relative;
    z-index: 3;
    background-color: black;
    border-bottom: 4px solid white;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    display: inline-block;
    border-right: 4px solid white;

    a {
      display: block;
      font-size: 0;
    }

    svg {
      width: 172px;
      height: 52px;
    }
  }

  .nav__icon-group {
    padding-right: 15px;
  }

  .nav {

    a {
      color: white;
    }

    > li {

      > a {
        font-weight: bold;
      }
    }
  }

  .top-menu {
    height: 38px;
    transition: all 0.3s;
    display: none;
    background-color: #2D2D2D;

    li {
      margin-right: 35px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    p {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    a {
      color: white;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .site-nav__search {
    display: block;
    padding: 13px 12px 14px;
    transition: background .3s;

    &.search--active {
      background: #2D2D2D;
    }
  }

  .search__hold {
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 11;
    top: 56px;
    height: calc(100vh - 56px);
    transition: opacity .3s, visibility .3s;
    opacity: 0;
    visibility: hidden;
    padding: 100px 0;
  }

  .search__close {
    @include button-unstyled;
    position: absolute;
    top: 0;
    right: 8px;
    transform: translateY(-200%);
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(small) {
      right: 15px;
    }

    @include breakpoint(medium) {
      transform: translateY(-150%);
    }
  }
  
  .search__close__wrapper {
    position: relative;
  }

  .search {
    position: relative;

    input {
      border: none;
      width: calc(100% - 60px);
    }

    .submit {
      border: none;
      width: 80px;
      background: #2D2D2D;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
    }
  }

  .cpd-bar, .fs-bar {
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    right: 0;

    .btn--icon-left {
      padding-left: 0 !important;
    }

    .counter {
      width: 20px;
      font-size: 1.6rem;
    }
  }

  .fs-bar {
    &.fs-bar--padded {
      padding: 12px 0;
    }
  }
}

#header.header--search {
  .search__hold {
    opacity: 1;
    visibility: visible;
  }
}

#header.header--scrolled {
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);

  .top-menu {
    height: 0;
    overflow: hidden;
  }
}

#header.header--hide {
  transform: translateY(-100%) !important;

  &.header--open {
    transform: translateY(0) !important;
  }
}

#header.header--no-shadow {
  box-shadow: none;
}

@include breakpoint-max(medium) {
  #header {
    .show-inline-desktop {
      display: none;
    }

    .site-nav {
      background-color: black;
      position: fixed;
      left: 0;
      width: 100%;
      z-index: 11;
      top: 56px;
      height: 100vh;
      transition: transform .3s;
      transform: translateX(-100%);
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .nav {
      margin: 0;
      padding: 30px 30px 100px 30px;

      > li {
        padding: 0;
        border-bottom: 2px solid #323232;

        &:first-of-type {
          border-top: 2px solid #323232;
        }

        &.menu-information {
          border-bottom: 0;
          padding-top: 20px;

          p:first-of-type {
            margin-bottom: 10px;
          }

          .social {
            li {
              margin-bottom: 15px;
            }
          }
        }

        > a {
          display: block;
          padding-top: 15px;
          padding-bottom: 15px;
          font-size: 1.8rem;
          background-image: url('/dist/img/icons/arrow-right-white.svg');
          background-size: 20px 10px;
          background-repeat: no-repeat;
          background-position: center right;
        }
      }
    }

    .sub-nav {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: black;
      transform: translateX(100%);
      transition: transform .3s;

      &--facades {
        border-top: 4px solid palette(blue, light);

        .sub-nav-dropdown-heading, li > a, .sub-nav-category-heading::before {
          background-image: url('/dist/img/icons/arrow-right-blue.svg') !important;
        }
      }

      &--timber {
        border-top: 4px solid palette(green, light);

        .sub-nav-dropdown-heading, li > a, .sub-nav-category-heading::before {
          background-image: url('/dist/img/icons/arrow-right-green.svg') !important;
        }
      }

      .sub-nav__inner {
        padding: 30px;
      }

      ul {
        padding: 0;
        margin: 0;
        list-style: none;
      }

      .sub-nav-dropdown-heading {
        color: white;
        margin: 0;
        font-size: 1.8rem;
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 2px solid #323232;
        position: relative;
        background-size: 20px 10px;
        background-repeat: no-repeat;
        background-position: center right;
      }

      .sub-nav-dropdown {
        position: absolute;
        padding: 30px 30px 100px;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: black;
        transform: translateX(100%);
        transition: transform .3s;

        > ul > li {
          &.open {
            .sub-nav-category-heading::before {
              transform: translateY(-50%) rotate(-90deg);
            }

            .sub-nav-category {
              display: block;
            }
          }
        }
      }

      .sub-nav-category-heading {
        background: none !important;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          width: 20px;
          height: 10px;
          transform: translateY(-50%) rotate(90deg);
          background-size: 20px 10px;
          background-repeat: no-repeat;
          background-position: center right;
          transition: transform .3s;
        }
      }

      .sub-nav-category {
        background-color: #151515;
        margin-left: -30px;
        margin-right: -30px;
        padding-left: 30px;
        padding-right: 30px;
        display: none;


        li {
          border: none !important;
        }

        a {
          background: none !important;
        }
      }

      .list.open {
        .sub-nav-dropdown {
          transform: translateX(0%);
        }

        .sub-nav-dropdown-heading::before {
          transform: translateY(-50%) rotate(-90deg);
        }
      }

      li {
        padding: 0;
        border-bottom: 2px solid #323232;

        > a {
          display: block;
          padding-top: 15px;
          padding-bottom: 15px;
          padding-right: 25px;
          font-size: 1.8rem;
          background-image: url('/dist/img/icons/arrow-right-white.svg');
          background-size: 20px 10px;
          background-repeat: no-repeat;
          background-position: center right;
        }
      }
    }

    .sub-nav__back {
      color: white;
      font-size: 1.8rem;
      padding-left: 30px;
      font-weight: bold;
      background-image: url('/dist/img/icons/arrow-left-white.svg');
      background-size: 20px 10px;
      background-repeat: no-repeat;
      background-position: center left;
      border-bottom: 2px solid #323232;
      padding-bottom: 15px;
      padding-top: 15px;
    }

    .open .sub-nav {
      transform: translateX(0);
    }
  }

  #header.header--open {
    .site-nav {
      transform: translateX(0);
    }
  }
}


@include breakpoint-max(medium) {
  #header {
    .wrapper {
      width: 100%;
    }
  }
}

@include breakpoint(medium) {

  #header.header--scrolled {
    .search__hold {
      top: 79px;
      height: calc(100vh - 79px);
    }

  }

  #header {

    .search__hold {
      top: 117px;
      height: calc(100vh - 117px);
    }

    .search {
      input {
        padding: 30px 20px;
        font-size: 2rem;
      }
    }

    .site-nav__search {
      padding: 25px 25px 25px;
    }

    .top-menu, .show-desktop {
      display: block;
    }

    .show-inline-desktop {
      display: inline-block;
      padding: 0 !important;
    }

    .nav__icon-group, .hide-desktop {
      display: none;
    }

    .logo {
      display: inline-block;
      border-right: 4px solid white;

      a {
        display: block;
        font-size: 0;
      }

      svg {
        width: 248px;
        height: 75px;
      }
    }

    .nav {
      > li {
        padding: 25px 17px;

        &.link--active {
          background-color: #2d2d2d;
        }

        &:hover {
          background-color: #2D2D2D;
        }
      }
    }

    .sub-nav__hold {
      position: relative;

      &.link--active {
        background-color: #2D2D2D;
      }

      &:hover {
        background-color: #2D2D2D;

        .sub-nav {
          opacity: 1;
          visibility: visible;
        }
      }

      &--facades {
        &:hover {
          background-color: palette(blue) !important;
        }

        &.link--active {
          background-color: palette(blue) !important;
        }
      }

      &--timber {
        &:hover {
          background-color: palette(green) !important;
        }

        &.link--active {
          background-color: palette(green) !important;
        }
      }
    }

    .sub-nav {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 100%;
      margin-top: 5px;
      width: 280px;
      border-left: 4px solid white;
      border-right: 4px solid white;
      border-bottom: 4px solid white;
      opacity: 0;
      visibility: hidden;
      transition: opacity .3s, visibility .3s;

      a {
        font-weight: 500;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .sub-nav__inner {
      background-color: black;
      position: relative;
      box-shadow: 0 0 13px 8px rgba(0, 0, 0, 0.15);;
    }


    .sub-nav--big {
      width: 940px;

      &.sub-nav--facades {
        margin-left: 58px;
        border-bottom: 4px solid palette(blue, light);

        li a {

          &:hover {
            color: palette(blue, light);
            text-decoration: underline;
          }
        }
      }

      &.sub-nav--timber {
        margin-left: -34px;
        border-bottom: 4px solid palette(green, light);

        li a {
          &:hover {
            color: palette(green, light);
            text-decoration: underline;
          }
        }
      }

      .sub-nav__inner {
        display: flex;
        min-height: 460px;
      }

      .list {
        flex: 1;
        padding: 45px 0;

        > p {
          font-weight: bold;
          color: white;
          font-size: 1.8rem;
          padding-left: 30px;
          padding-right: 15px;
        }
      }

      a {
        padding-left: 30px;
        padding-right: 15px;
        display: block;
      }

      .sub-nav-category {
        display: none;
      }

      .promo {
        width: 30%;
        background: white;
        position: relative;
        transition: opacity .3s;

        &:hover {
          opacity: .6;
        }

        .overlay {
          position: absolute;
          z-index: 2;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 30px 50px 30px 20px;
          background-color: rgba(0, 0, 0, 0.7);

          .icon {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
          }

          p {
            margin-bottom: 0;
          }
        }
      }
    }

    .sub-nav__back {
      display: none;
    }

    .sub-nav-dropdown {
      li {
        &:hover {
          .sub-nav-category {
            display: block;
          }
        }
      }
    }

    .sub-nav-category {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      background: #191919;
      left: 0;
      width: 47%;
      margin-left: 23%;
      padding-top: 47px;
      z-index: 3;

      li {
        width: 48%;
        display: inline-block !important;
        vertical-align: top !important;
      }
    }

    .list {
      text-align: left;
      padding: 45px 30px;

      .breaker {
        color: white;
        margin: 0;
        padding-left: 30px;
        padding-right: 15px;
        padding-top: 18px;
      }

      li {
        display: block;
        padding-bottom: 25px;

        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }

    nav {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      li {
        display: inline-block;
        vertical-align: middle;
        padding-bottom: 0;
      }
    }
  }
}

@include breakpoint(large) {
  #header {
    .top-menu {
      height: 41px;
    }

    .search__hold {
      text-align: left !important;
    }

    .site-header {
      position: relative;
      height: 79px;
      text-align: right;
      justify-content: flex-end;
    }

    .list {
      text-align: left;
    }

    .nav__group {
      width: 1260px;
      margin-right: auto;
      margin-left: auto;
    }

    .logo {
      position: absolute;
      top: 0;
      left: 0;
    }

    .sub-nav {
      margin-top: 4px;
    }

    .sub-nav--big {
      width: 1260px;

      .list {

        > p {
          padding-left: 50px;
        }
      }

      .breaker {
        padding-left: 50px;
      }

      a {
        padding-left: 50px;
      }

      &.sub-nav--facades {
        margin-left: -30px;
        //opacity: 1;
        //visibility: visible;
      }

      &.sub-nav--timber {
        margin-left: -130px;
      }

    }

  }
}