//###########################################################################
// Flex utilities
//###########################################################################

.flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.flex-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;

  .flex-col {
    width: 100%;
    padding: 0 16px;
  }

  @include breakpoint(small) {
    .flex-col__1-2-small-up {
      width: 50%;
    }
  }

  @include breakpoint(medium) {
    .flex-col__1-4-medium-up {
      width: 25%;
    }
  }
}